import React from 'react'

const permissions_list = [
    {
        'Dashboard':[
            { label:'Reports And Pending' },
            { label:'Own Analytics'},
            { label:'Branch Analytics'},
            { label:'All Modules'},
        ],
    },
    {
        'Reports':[
            { label:'Create Report' },
            { label:'Add Result' },
            { label:'Add Billing' },
            // { label:'Edit Report'},
            // { label:'Delete Report'},
            // { label:'Download Report'}
        ], 
    },
        {

        'Invoice':[
            { label:'Own Invoice' },
            { label:'Branch Invoice'},
            { label:'All Invoices'},
        ], 
    },
        {

        'Age Group':[
            { label:'View Age Group' },
            { label:'Add Age Group'},
            { label:'Edit Age Group'},
            { label:'Delete Age Group'}
        ], 
    },
        {

        'Cluster':[
            { label:'View Cluster' },
            { label:'Add Cluster'},
            { label:'Edit Cluster'},
            { label:'Delete Cluster'}
        ], 
    },
        {

        'Test Prices':[
            { label:'View Test Prices' },
            { label:'Add Test Prices'},
            { label:'Edit Test Prices'},
            { label:'Delete Test Prices'}
        ],
     },
        {

        'Roles':[
            { label:'View Roles' },
            { label:'Add Roles'},
            { label:'Edit Roles'},
            { label:'delete Roles'}
        ], 
    },
        {

        'Staff':[
            { label:'View All Staff' },
            { label:'View Branch Staff'},
            { label:'Add Staff to any branch'},
            { label:'Add Staff to own Branch'}, 
            { label:'Edit Staff'},
            { label:'Delete Staff'},
        ],
     },
        {

        'Branches':[
            { label:'View Branches' },
            { label:'Add Branches'},
            { label:'edit Branches'},
            { label:'delete Branches'}
        ], 
    },
        {

        'Activity Log':[
            { label:'View own Activity' },
            { label:'View Branch Activity'},
            { label:'View Everyones Activity'},
            { label:'edit Activity'},
            {label:'Delete Activity'}
        ],
     },
        {

        'Payment Modes':[
            { label:'View Payment Modes' },
            { label:'Add Payment Modes'},
            { label:'Edit Payment Modes'},
            { label:'Delete Payment Modes'}
        ],
     },
        {

        'Income Expese Type':[
           { label:'View Income Expese Type' },
           { label:'Add Income Expese Type'},
           { label:'Edit Income Expese Type'},
           { label:'Delete Income Expese Type'}
        ],
     },
        {

        'Banking Activity':[
            { label:'View Branch Only ' },
            { label:'view Self '},
            { label:'View All'},
            { label:'Add Banking Activity'},
            { label:'Edit Banking Activity'},
            { label:'Delte Banking Activity'},
        ],
     },
        {

        'Customers':[
            { label:'View Customers' },
            { label:'Add Customers'},
            { label:'Update Customers'},
            { label:'Delete Customers'},
            { label:'Export Customers'}
        ],
     },
        {

        'Products':[
             { label:'View Products' },
             { label:'Feature Products'},
             { label:'Edit Products'},
             { label:'Hide Products'},
             { label:'Delete Products'}
        ],
     },
        {

        'Partners':[
            { label:'View Partners' },
            { label:'Edit Partners'},
            { label:'Delete Partners'},
        ], 
     },
        {
    
        'Profile':[
            { label:'Update Profile' }, 
        ],
    }


        // 'Blank':[
        //     { label:'' },
        //     { label:''},
        //     { label:''},
        //     { label:''}
        //     ],
                    // }

];

export default permissions_list;
