import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {  ThemeProvider, createTheme } from '@mui/material/styles';
import Login from './mainsite/Login';
import Signup from './mainsite/Signup';
import Dashboard from './dashboard/Dashboard';
import Protected from './mainsite/Protected';
import CreateReport from './dashboard/CretateReport';
import AgeGroup from './dashboard/AgeGroup';
import TestPrice from './dashboard/TestPrice';
import Clusters from './dashboard/Clusters';
import Groups from './dashboard/Groups';
import Staff from './dashboard/Staff';
import Profile from './dashboard/Profile';
import Branches from './dashboard/Branches';
import Activity from './dashboard/Activity';
import Create_cluster from './dashboard/Create_cluster';
import Partners from './dashboard/Partners';
import Banking from './dashboard/Banking';
import Products from './dashboard/Products';
import Customers from './dashboard/Customers';
import Invoices from './dashboard/Invoices';
import Report_results from './dashboard/Report_results';
import Test from './dashboard/Test';
import Homepage from './mainsite/Homepage';
import Logout from './dashboard/parts/Logout';

function App() {
  return (
    <div className="App">

<ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          typography:{
              fontFamily:'Quicksand',
              
              
          },
        
        })}
      >
      
      <Router>
        <Switch>

        {/* <Route path="/" exact>
            <Homepage/>
          </Route> */}


        <Route path="/" exact>
            <Login/>
          </Route>

          <Route path="/login" exact>
            <Login/>
          </Route>

          <Route path="/signup" exact>
            <Signup/>
          </Route>

          <Route path="/logout" exact>
            <Logout/>
          </Route>



          <Route  exact path="/dashboard">
            <Protected cmp={Dashboard} />
          </Route>


          <Route  exact path="/create/:id">
            <Protected cmp={CreateReport} />
          </Route>


          <Route  exact path="/agegroup">
            <Protected cmp={AgeGroup} />
          </Route>


          <Route  exact path="/clusters">
            <Protected cmp={Clusters} />
          </Route>


          <Route  exact path="/groups">
            <Protected cmp={Groups} />
          </Route>  


          <Route  exact path="/staff" >
            <Protected cmp={Staff} />
          </Route>  


          <Route  exact path="/testprice">
            <Protected cmp={TestPrice} />
          </Route> 


          <Route  exact path="/branches">
            <Protected cmp={Branches} />
          </Route>  


          <Route  exact path="/activity">
            <Protected cmp={Activity} />
          </Route>  


          <Route  exact path="/profile">
            <Protected cmp={Profile} />
          </Route> 

          <Route  exact path="/create_cluster">
            <Protected cmp={Create_cluster} />
          </Route> 


          <Route  exact path="/partners">
            <Protected cmp={Partners} />
          </Route> 


          <Route  exact path="/banking">
            <Protected cmp={Banking} />
          </Route> 


          <Route  exact path="/products">
            <Protected cmp={Products} />
          </Route> 

          <Route  exact path="/customers">
            <Protected cmp={Customers} />
          </Route> 


          <Route  exact path="/invoices">
            <Protected cmp={Invoices} />
          </Route> 


          <Route  exact path="/report_results/:token">
            <Protected cmp={Report_results} />
          </Route> 


          <Route  exact path="/test">
            <Protected cmp={Test} />
          </Route> 

          

          
{/*           
          <Route path="/dashboard" exact>
            <Dashboard/>
          </Route> */}
          


        </Switch>
        
      </Router>

     
</ThemeProvider>

    </div>
  );
}

export default App;
