import React, { useState, useEffect } from "react"
import Sidebar from "./parts/Sidebar"
import {
  Button,
  Select,
  Alert,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Toolbar,
  Box,
  IconButton,
  Chip,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Avatar,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import { useHistory } from "react-router-dom"
import CreateIcon from "@mui/icons-material/Create"
import DeleteIcon from "@mui/icons-material/Delete"
import { Modal } from "react-bootstrap"

const drawerWidth = 280

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  elevation: "0",
  color: theme.palette.text.secondary,
}))

export default function Staff() {
  var initialState = ""

  let history = useHistory()

  const [show, setShow] = useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [password, setPassword] = useState("")
  const [address, setAddress] = useState("")
  const [role, setRole] = useState("")

  const [resultmsg, setResultmsg] = useState("")
  const [resulttype, setResulttype] = useState("")

  const [stafflist, setStafflist] = useState([])
  const [roleslist, setRoleslist] = useState([])

  //  edit action
  const [edit_staff_modal, setEdit_staff_modal] = useState(false)
  const [update_staff_id, setupdate_staff_id] = useState(initialState)

  const [new_name, setNew_name] = useState(initialState)
  const [new_email, setNew_email] = useState(initialState)
  const [new_mobile, setNew_mobile] = useState(initialState)
  const [new_password, setNew_password] = useState(initialState)
  const [new_address, setNew_address] = useState(initialState)
  const [new_role, setNew_role] = useState(initialState)

  const handleChangeStaff = staff => {
    setupdate_staff_id(staff.id)
    setNew_name(staff.name)
    setNew_email(staff.email)
    setNew_mobile(staff.mobile)
    setNew_password(staff.password)
    setNew_address(staff.address)
    setNew_role(staff.role)
    setEdit_staff_modal(true)
  }

  function handleShow() {
    setShow(true)
  }

  function handleClose() {
    setName("")
    setEmail("")
    setMobile("")
    setPassword("")
    setAddress("")
    setRole("")

    setResulttype("")
    setResultmsg("")
    setShow(false)
  }

  function handleStaffClose() {
    setupdate_staff_id("")
    setNew_name("")
    setNew_email("")
    setNew_mobile("")
    setNew_password("")
    setNew_address("")
    setNew_role("")

    setResultmsg("")
    setResulttype("")
    setEdit_staff_modal(false)
  }
  function create_staff() {
    var url = "https://jeetlabs.in/Api/create_staff"
    var items = { name, email, mobile, password, address, role }

    if (
      name.length < 1 ||
      email.length < 1 ||
      mobile.length < 1 ||
      password.length < 1 ||
      address.length < 1 ||
      role.length < 1
    ) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Staff Member Creation in Progress")
      setResulttype("info")

      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            setTimeout(function () {
              staff_list()
              handleClose()
              //   history.push('/Myorder')
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  const handleChange = event => {
    setRole(event.target.value)
  }
  const handleNewChange = event => {
    setNew_role(event.target.value)
  }
  function role_list() {
    var url = "https://jeetlabs.in/Api/role_list"

    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          // console.log(result)
          setRoleslist(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  function staff_list() {
    var url = "https://jeetlabs.in/Api/staff_list"

    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          // console.log(result)
          setStafflist(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  function update_staff() {
    var url = "https://jeetlabs.in/Api/update_staff"
    var items = {
      id: update_staff_id,
      name: new_name,
      mobile: new_mobile,
      email: new_email,
      password: new_password,
      role: new_role,
      address: new_address,
    }
    if (
      update_staff_id.length < 1 ||
      new_name.length < 1 ||
      new_mobile.length < 1 ||
      new_email.length < 1 ||
      new_password.length < 1 ||
      new_role.length < 1 ||
      new_address.length < 1
    ) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Staff Update in Progress")
      setResulttype("info")

      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            staff_list()
            setTimeout(function () {
              handleStaffClose()
              //   history.push('/Myorder')
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }
  var userid = JSON.parse(localStorage.getItem("userid"))
  var token = JSON.parse(localStorage.getItem("token"))

  function delete_data(branch_id) {
    var url = "https://jeetlabs.in/Api/delete_staff"
    var confirm = window.confirm("Confirm Delete")

    var items = { userid, token, branch_id }

    if (confirm) {
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            // console.log(result);
            // setResultmsg(result.msg)
            // setResulttype(result.type)

            staff_list()
            setTimeout(function () {
              handleClose()
              //   history.push('/Myorder')
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    } else {
      // console.log(branch_id+ ' is safe')
    }
  }

  useEffect(() => {
    staff_list()
    role_list()
  }, [])

  return (
    <div>
      <Sidebar />

      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar />

        <div>
          <div
            sx={{ p: 3, m: 1 }}
            style={{
              marginTop: 20,
              marginBottom: 20,
              padding: 10,
              borderRadius: "12px",
              display: "flex",
              justifyContent: "space-between",
              border: "0.5px  lightgrey",
            }}
          >
            <Typography variant='h6' sx={{ fontWeight: "medium" }}>
              Manage Users
            </Typography>
            <Button
              variant='contained'
              onClick={() => handleShow()}
              color='primary'
              className='modified_button'
            >
              + Create{" "}
            </Button>
          </div>

          <Grid container spacing={2}>
            {stafflist.map(staff => (
              <Grid item xs={12} md={4} elevation='0'>
                <Item elevation='0'>
                  <Card>
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        src=''
                        sx={{
                          height: 150,
                          width: 150,
                          border: "5px solid darkcyan",
                        }}
                      />

                      <Chip
                        label={staff.name + " : " + staff.role}
                        color='success'
                        style={{ textTransform: "capitalize" }}
                        sx={{ m: 1, p: 1 }}
                      />
                      <Typography
                        variant='subtitle1'
                        sx={{ fontWeight: "bold", color: "gray" }}
                      >
                        {staff.email}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{
                        justifyContent: "center",
                        borderTop: "0.5px solid lightgrey",
                      }}
                    >
                      <IconButton color='success'>
                        <CreateIcon
                          onClick={() => {
                            handleChangeStaff(staff)
                          }}
                        />
                      </IconButton>
                      <IconButton
                        color='error'
                        onClick={() => delete_data(staff.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Item>
              </Grid>
            ))}
          </Grid>
        </div>
      </Box>

      <Modal
        show={show}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handleClose()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>Add Staff Member</span>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              required
              id='outlined-required'
              label='Name'
              sx={{ mb: 2, width: "45%", mr: 2 }}
              onChange={e => setName(e.target.value)}
            />

            <TextField
              required
              id='outlined-required'
              label='E-mail'
              sx={{ mb: 2, width: "45%" }}
              onChange={e => setEmail(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              required
              type='number'
              id='outlined-required'
              label='Phone Number'
              sx={{ mb: 2, width: "45%", mr: 2 }}
              onChange={e => setMobile(e.target.value)}
            />

            <TextField
              fullWidth
              required
              type='password'
              id='outlined-required'
              label='User Password'
              sx={{ mb: 2, width: "45%" }}
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              required
              id='outlined-required'
              label='Address'
              sx={{ mb: 2, width: "92%" }}
              multiline
              rows={3}
              onChange={e => setAddress(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl
              fullWidth
              style={{
                display: "flex",
                justifyContent: "center!important",
                alignItems: "center",
              }}
            >
              <InputLabel sx={{ ml: 4 }} id='demo-simple-select-label'>
                User Role
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={role}
                label='User Role'
                onChange={handleChange}
                sx={{ width: "92%" }}
                style={{ zIndex: 10000 }}
              >
                {roleslist.map(role => (
                  <MenuItem value={role.name}>{role.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => create_staff()}
            color='success'
            sx={{ m: 1 }}
          >
            Create User
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={edit_staff_modal}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handleClose()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>Edit Staff Member</span>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              required
              id='outlined-required'
              label='Name'
              sx={{ mb: 2, width: "45%", mr: 2 }}
              value={new_name}
              onChange={e => setNew_name(e.target.value)}
            />

            <TextField
              required
              id='outlined-required'
              label='E-mail'
              sx={{ mb: 2, width: "45%" }}
              value={new_email}
              onChange={e => setNew_email(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              required
              type='number'
              id='outlined-required'
              label='Phone Number'
              sx={{ mb: 2, width: "45%", mr: 2 }}
              value={new_mobile}
              onChange={e => setNew_mobile(e.target.value)}
            />

            <TextField
              fullWidth
              required
              type='password'
              id='outlined-required'
              label='User Password'
              sx={{ mb: 2, width: "45%" }}
              value={new_password}
              onChange={e => setNew_password(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              required
              id='outlined-required'
              label='Address'
              sx={{ mb: 2, width: "92%" }}
              multiline
              rows={3}
              value={new_address}
              onChange={e => setNew_address(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl
              fullWidth
              style={{
                display: "flex",
                justifyContent: "center!important",
                alignItems: "center",
              }}
            >
              <InputLabel sx={{ ml: 4 }} id='demo-simple-select-label'>
                User Role
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={new_role}
                label='User Role'
                onChange={handleNewChange}
                sx={{ width: "92%" }}
                style={{ zIndex: 10000 }}
              >
                {roleslist.map(role => (
                  <MenuItem value={role.name}>{role.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleStaffClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => update_staff()}
            color='success'
            sx={{ m: 1 }}
          >
            Update User
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
