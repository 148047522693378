import React,{useState,useEffect} from 'react'
import Sidebar from './parts/Sidebar'
import {Button,Toolbar,Box,Card,Grid, Divider,Alert,Checkbox,TextField,Paper} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory,useParams } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Map';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Row ,Col,Form,InputGroup} from 'react-bootstrap';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

const drawerWidth = 280;


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  elevation:'2',
  color: theme.palette.text.secondary,
}));




function isNumeric(value) {
  return /^-?\d+$/.test(value);
}
var monthNames = [
  "January", "February", "March", "April", "May", "June", "July",
  "August", "September", "October", "November", "December"
];
var dayOfWeekNames = [
  "Sunday", "Monday", "Tuesday",
  "Wednesday", "Thursday", "Friday", "Saturday"
];

function formatDate(date, patternStr){
  if (!patternStr) {
      patternStr = 'M/d/yyyy';
  }
  var day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear(),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds(),
      miliseconds = date.getMilliseconds(),
      h = hour % 12,
      hh = twoDigitPad(h),
      HH = twoDigitPad(hour),
      mm = twoDigitPad(minute),
      ss = twoDigitPad(second),
      aaa = hour < 12 ? 'AM' : 'PM',
      EEEE = dayOfWeekNames[date.getDay()],
      EEE = EEEE.substr(0, 3),
      dd = twoDigitPad(day),
      M = month + 1,
      MM = twoDigitPad(M),
      MMMM = monthNames[month],
      MMM = MMMM.substr(0, 3),
      yyyy = year + "",
      yy = yyyy.substr(2, 2)
  ;
  // checks to see if month name will be used
  patternStr = patternStr
    .replace('hh', hh).replace('h', h)
    .replace('HH', HH).replace('H', hour)
    .replace('mm', mm).replace('m', minute)
    .replace('ss', ss).replace('s', second)
    .replace('S', miliseconds)
    .replace('dd', dd).replace('d', day)
    
    .replace('EEEE', EEEE).replace('EEE', EEE)
    .replace('yyyy', yyyy)
    .replace('yy', yy)
    .replace('aaa', aaa);
  if (patternStr.indexOf('MMM') > -1) {
      patternStr = patternStr
        .replace('MMMM', MMMM)
        .replace('MMM', MMM);
  }
  else {
      patternStr = patternStr
        .replace('MM', MM)
        .replace('M', M);
  }
  return patternStr;
}
function twoDigitPad(num) {
  return num < 10 ? "0" + num : num;
}

export default function CreateReport() {
  let { id } = useParams();
  let history = useHistory(); 
 

  // var today = Moment(dateToBeFormate).format('DD/MM/YYYY');

  // assets

  const [customers_list, setCustomers_list] = useState([])
  const [partnerlist, setPartnerlist] = useState([])
  const [agegroup_list, setAgegroup_list] = useState([])
  const [tests_list, setTest_list] = useState([])

  const [valid_user, setValid_user] = useState(true)
    
  // customer details 
  
  const [cs_name, setCs_name] = useState('')
  const [cs_mobile, setCs_mobile] = useState('')
  const [cs_email, setCs_email] = useState('')
  const [cs_address, setCs_address] = useState('')

  // submission 
  const [cust_id, setCust_id] = useState(id)
  const [selected_tests, setSelected_tests] = useState([])
  const [sample_collection_date, setSample_collection_date] = useState(new Date())
  const [report_genration_date, setReport_genration_date] = useState((new Date()).toJSON())
  const [age, setAge] = useState('')
  const [age_group, setAge_group] = useState('')
  const [gender, setGender] = useState('male')
  const [sample_collection_place, setSample_collection_place] = useState('Lab')
  const [referer, setReferer] = useState('')
  const [refrence, setRefrence] = useState('')

  const [c_report_msg, setC_report_msg] = useState('')
  const [cr_msg_type, setCr_msg_type] = useState('')
  const [report_id, setReport_id] = useState('')

  const sample_places = ['Lab','Client Address','Other']
  const genders = ['Male','Female','Other']
  var userid = JSON.parse(localStorage.getItem('userid'));


  function permissionhandler(e){

 
    let perm = selected_tests;
    if(e.target.checked){
      perm.push(e.target.value);
      // get_tests_data()
    }else{
      if(perm.length > 0){
        var index = perm.indexOf(e.target.value);
        if (index !== -1) {
          perm.splice(index, 1);
          setSelected_tests(perm);
          // get_tests_data()
        }
      }
    }

  }

  

 
  function change_patient(e){
    setCust_id(e.target.value)
    var selected = e.target.value;
    
    history.push('/create/'+e.target.value )
    
      setValid_user(true)
      var url = 'https://jeetlabs.in/Api/get_customer_data'
      var items = {selected}

      let result = fetch(url,{
        method:'POST',
        headers:{
          "Content-Type":"application/json",
          "Accept":"application/json"
        },
        body:JSON.stringify(items)
        
      }).then((response)=>{
        response.json().then((result)=>{
          // console.log(result);
          if(result.type == 'valid'){
            setCs_name(result.data.cs_name)
            setCs_mobile(result.data.cs_mobile_no)
            setCs_email(result.data.cs_email)
            setCs_address(result.data.cs_address)
          }else{
            setValid_user(false)
          }
          
        })
      }).catch(error =>{
        console.warn(error);
      })


   
  }
  
  function create_report_assets(){

    var url = 'https://jeetlabs.in/Api/create_report_assets'
  
  let result = fetch(url,{
    method:'GET',
    headers:{
        "Content-Type":"application/json",
        "Accept":"application/json"
    }
  
  }).then((response)=>{
    response.json().then((result)=>{
      // console.log(result)
      setPartnerlist(result.partners)
      setCustomers_list(result.customers)
      setTest_list(result.tests)
      setAgegroup_list(result.age_group)
      // setToken(result.token)
     
   
    })
  }).catch(error =>{
  console.warn(error);
  })
  
  }


  const default_user = () => {
    if(id == 0){
      setValid_user(false)
    }else{
      var selected = id
      setValid_user(true)
      var url = 'https://jeetlabs.in/Api/get_customer_data'
      var items = {selected}

      let result = fetch(url,{
        method:'POST',
        headers:{
          "Content-Type":"application/json",
          "Accept":"application/json"
        },
        body:JSON.stringify(items)
        
      }).then((response)=>{
        response.json().then((result)=>{
          // console.log(result);
          if(result.type == 'valid'){
            setCs_name(result.data.cs_name)
            setCs_mobile(result.data.cs_mobile_no)
            setCs_email(result.data.cs_email)
            setCs_address(result.data.cs_address)
          }else{
            setValid_user(false)
          }
          
        })
      }).catch(error =>{
        console.warn(error);
      })


    }



  }


  const submitform = (event) => {

if(!valid_user || cust_id == 0){
  setCr_msg_type('error');
  setC_report_msg('Select Valid User')
}else if(age == '' || age_group == ''){

  setCr_msg_type('error');
  setC_report_msg('Age & Age Group Selection is Mandatory')

}else if(selected_tests.length < 1){

  setCr_msg_type('error');
  setC_report_msg('Minimumm 1 Test Selection is Required')

}else{


  setCr_msg_type('info');
  setC_report_msg('Report Creation in Progress')

    var url = 'https://jeetlabs.in/Api/create_pt_report'
    var items = {cust_id,selected_tests,userid,sample_collection_date,sample_collection_place,report_genration_date,age,age_group,gender,refrence,referer}   

    let result = fetch(url,{
      method:'POST',
      headers:{
        // "Content-Type":"application/json",
        "Accept":"application/json"
      },
      body:JSON.stringify(items)
      
    }).then((response)=>{
      response.json().then((result)=>{
        // console.log(result);
        setReport_id(result.data.report_id)
        setCr_msg_type(result.type)
        setC_report_msg(result.msg)

        if(result.type == 'success'){
          if(event == 'save'){
            history.push('/invoices')
          }else if(event == 'result'){
            history.push('/report_results/'+result.data.report_id)
          }else{
            window.location.reload();
          }
        }
       
        
      })
    }).catch(error =>{
      console.warn(error);
    })

  }

  }

 

  useEffect(() => {
    create_report_assets();
    default_user();
  },[])
  
    return (
        <div> 
            <Sidebar/>
            <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` },ml:{sm:`${drawerWidth}px`} }}
      >
        <Toolbar />

        {/* <h3 style={{margin:15}} >Create Report</h3> */}

<Card sx={{p:1,borderRadius:'10px',minHeight:'400px'}} elevation={3} > 

<Row>
    <Col xs={12} md={6} style={{backgroundColor:'',minHeight:'300px'}} >

    <Form.Group  controlId="validationCustomUsername">
          <Form.Label style={{fontWeight:'bold'}} >Select Patient</Form.Label>
   
            <Form.Control as="select" name='patient' onChange={(e) => change_patient(e)} >
            {
              customers_list.map((cust)=>(
               
                <option value={cust.cs_id} selected={cust.cs_id === id?'selected':'' } > #{cust.cs_id} . {cust.cs_name} </option>
                ))
              }
          
            </Form.Control>
         
          </Form.Group>

          <Row>
            <Col xs={12} md={4}>
            <Form.Label style={{fontWeight:'bold'}} >Age </Form.Label>
            <InputGroup className="mb-3">
          
              <Form.Control placeholder="Age" onChange={(e)=>setAge(e.target.value)}  value={age}  />
          
          </InputGroup>
            </Col>

              <Col xs={6} md={4} >

            <Form.Label style={{fontWeight:'bold'}} >Age Group</Form.Label>
              <Form.Control as="select" onChange={(e)=>setAge_group(e.target.value)} >
              <option value=''>Select Age Group</option>
         
            {
                    agegroup_list.map((group)=>(
                <option selected={age_group == group.age_group_id?'selected':''} value={group.age_group_id}>{group.min_age_num}&nbsp;{group.min_age_unit} {group.max_age_num}&nbsp;{group.max_age_unit}</option>
            
                    ))}
              
                      
                  
            </Form.Control>
              </Col>

            <Col xs={6} md={4}>

            <Form.Label style={{fontWeight:'bold'}} >Gender</Form.Label>
            <Form.Control as="select" onChange={(e)=>setGender(e.target.value)} value={gender}>
            {genders.map((gender_op,index)=>(
                <option key={index} selected={gender_op == gender?'selected':''} value={gender_op}>{gender_op}</option>
            ))}
                
            
                
                  
            </Form.Control>
            </Col>
          </Row>
  <br/>
{
  valid_user?
  <>
  <Divider/>
  <br/>
  <div style={{display:'flex',justifyContent:'',alignItems:'',flexDirection:'column'}}>
      <div><span style={{fontWeight:'bold'}}>Code# : </span> {cust_id} <br/></div>
      <div><span style={{fontWeight:'bold'}}>Name : </span> {cs_name} <br/></div>
      <div><span style={{fontWeight:'bold'}}>Mobile : </span> {cs_mobile} <br/></div>
      <div><span style={{fontWeight:'bold'}}>E-mail : </span> {cs_email} <br/></div>
      <div><span style={{fontWeight:'bold'}}>Address : </span> {cs_address} <br/></div>
  </div>
<br/>
  <Divider/>
  </>
  :<>
  <Divider/>
  <br/>
  <Alert severity='error'>Not A Valid User</Alert>
  <br/>
  <Divider/>
  <br/>
  </>

}


    </Col>

    <Col xs={12} md={6}  style={{backgroundColor:''}} >

    <Row>
    <Col>
    <Form.Label style={{fontWeight:'bold'}} >Sample Collection Date </Form.Label>
    
<InputGroup>  
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    
        
        <DateTimePicker
        inputFormat="dd/MM/yyyy hh:mm a"
        label=""
          renderInput={(params) => <TextField  size='small' {...params} />}
          value={sample_collection_date}
          showTodayButton
          onChange={(newValue) => {
            setSample_collection_date(newValue);
          }}
        />
     
    </LocalizationProvider>
    {/* <InputGroup.Append>
      <InputGroup.Text><CalendarTodayIcon /></InputGroup.Text>
    </InputGroup.Append> */}
    </InputGroup>
    {/* {current_d}
      <Form.Control placeholder="Sample Date" name="sample_collection_date" onChange={(e)=>setSample_collection_date(e.target.value)} type="datetime-local"  value={sample_collection_date}   /> */}
    </Col>
    <Col>

    <Form.Label style={{fontWeight:'bold'}} >Report Genration Date</Form.Label>
    <InputGroup>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    
        
        <DateTimePicker
        inputFormat="dd/MM/yyyy hh:mm a"
        label=""
          renderInput={(params) => <TextField  size='small' {...params} />}
          value={report_genration_date}
          onChange={(newValue) => {
            setReport_genration_date(newValue);
          }}
        />
     
    </LocalizationProvider>

    </InputGroup>
      {/* <Form.Control  placeholder="Report Date" name="rpt_gen_date" onChange={(e)=>setReport_genration_date(e.target.value)} type="date"  value={report_genration_date} /> */}
    </Col>
  </Row>

  <br/>

  <Row>
    <Col>
    <Form.Label style={{fontWeight:'bold'}} >Sample Collection Place</Form.Label>
    <InputGroup>

    <Form.Control as="select" onChange={(e)=>setSample_collection_place(e.target.value)} >
            
            {sample_places.map((place,index)=>(

            <option selected={sample_collection_place == place?'selected':''} value={place}>{place}</option>
            ))}

            
        
            
              
        </Form.Control>
      <InputGroup.Append>
      <InputGroup.Text><MapIcon /></InputGroup.Text>
    </InputGroup.Append>

    </InputGroup>
    </Col>
    <Col>
          <Form.Label style={{fontWeight:'bold'}} >Refered by</Form.Label>
      <InputGroup>
          <Form.Control as="select" onChange={(e)=>setReferer(e.target.value)} >
            <option value=''>Select Referer</option>
                  {
                    partnerlist.map((partner,index)=>(
                      
                      <option key={index} selected={referer == partner.pt_id?'selected':''} value={partner.pt_id}  > {partner.pt_id} . {partner.pt_name} </option>
                      ))
                    }
                
          </Form.Control>
          <InputGroup.Append>
            <InputGroup.Text><ReceiptIcon /></InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
    </Col>
  </Row>
<br/>
  {/* <Row>
    <Col>
    <Form.Group controlId="exampleForm.ControlTextarea1">
    <Form.Label style={{fontWeight:'bold'}} >Sample Collection Address</Form.Label>
    <Form.Control as="textarea" rows={3}  />
  </Form.Group>
    </Col>
</Row> */}
<Row>

    <Col>
    <Form.Group controlId="exampleForm.ControlTextarea1">
    <Form.Label style={{fontWeight:'bold'}} >Refrence</Form.Label>
    <Form.Control as="textarea" rows={4} value={refrence} onChange={(e)=>setRefrence(e.target.value)}  />
  </Form.Group>
    </Col>
  </Row>

    </Col>
  </Row>

<br/>

<Divider/>
<br/>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',fontWeight:'600',fontSize:'25px',}} >
  Select Tests
</div>
<br/>
<Divider/>





  {
    age_group == '' || age.length < 3?
  
    <>
    <Alert severity='error'>Age Entry & Age Group Selection is required before Selecting Test</Alert>
    </>

:
<Grid container spacing={0}>
  {
    tests_list.map((tests)=>(
    <Grid item xs={6} md={3}>
      <Item elevation={0} >
        <Checkbox value={tests.test_id}  onChange={(e)=>permissionhandler(e)} aria-label={tests.test_name} /> <label>{tests.test_name}</label>
      </Item>
    </Grid>

    ))
  }
</Grid>
}

    
    



<Divider/>

{/* const [c_report_msg, setC_report_msg] = useState('')
  const [cr_msg_type, setCr_msg_type] = useState('') */}


{
    cr_msg_type == 'error'?
    <Alert sx={{m:2}} variant="filled" color="error">{c_report_msg}</Alert>
    :cr_msg_type == 'success'?<Alert sx={{m:2}} variant="filled" color="success">{c_report_msg}</Alert>
    :cr_msg_type == 'info'?<Alert  sx={{m:2}} variant="filled" color="info">{c_report_msg}</Alert>
    :''
     
  }
  {/* <h2>{report_id}</h2> */}
<br/>

{/* <div style={{display:'flex',justifyContent:"space-between",alignItems:"center",marginRight:'20px'}} > */}
<Grid container spacing={0}>

<Grid item xs={12} md={4}>
      <Item elevation={0} >
        <Button size='large' onClick={()=>submitform('new')} sx={{minWidth:'250px',backgroundImage:'linear-gradient(to right, #0f2027, #203a43, #2c5364);'}}   variant="contained">Save Report & Create New</Button>
      </Item>
    </Grid>

    <Grid item xs={12} md={4}>
      <Item elevation={0} >
        <Button size='large' onClick={()=>submitform('result')}  sx={{minWidth:'250px',backgroundImage:'linear-gradient(to right, #0f2027, #203a43, #2c5364);'}}   variant="contained">Save Report & Add Result</Button>
      </Item>
    </Grid>

    <Grid item xs={12} md={4}>
      <Item elevation={0} >
        <Button size='large' onClick={()=>submitform('save')} sx={{minWidth:'250px',backgroundImage:'linear-gradient(to right, #0f2027, #203a43, #2c5364);'}}  variant="contained">Save Report</Button>
      </Item>
    </Grid>


  </Grid>
{/* </div> */}
     

      
<br/>


       
           
            </Card>


      </Box>








        </div>
    )
}
