import React, { useState, useEffect } from "react"
import Sidebar from "./parts/Sidebar"
import {
  Button,
  TextField,
  Alert,
  Toolbar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import { useHistory } from "react-router-dom"
import CreateIcon from "@mui/icons-material/Create"
import DeleteIcon from "@mui/icons-material/Delete"
import { Modal, Row, Col } from "react-bootstrap"
import VisibilityIcon from "@mui/icons-material/Visibility"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import XLSX from "xlsx"
import { useInput } from "@mui/core"

const StyledInputElement = styled("input")`
  width: 200px;
  font-size: 1rem;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  background: #fff;
  border: 1.5px solid #606060;
  border-radius: 10px;
  padding: 6px 10px;
  color: #20262d;
  transition: width 300ms ease;

  &:hover {
    background: #fff;
    border-color: #606060;
  }

  &:focus {
    outline: none;
    width: 220px;
    transition: width 200ms ease-out;
  }
`
const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  const { getRootProps, getInputProps } = useInput(props, ref)

  return (
    <div {...getRootProps()}>
      <StyledInputElement {...props} {...getInputProps()} />
    </div>
  )
})

const drawerWidth = 280

// const Item = styled(Paper)(({ theme }) => ({
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     elevation:'0',
//     color: theme.palette.text.secondary,
//   }));

export default function Partners() {
  var initialState = ""

  const [show, setShow] = useState(false)

  const [resultmsg, setResultmsg] = useState("")
  const [resulttype, setResulttype] = useState("")

  const [name, setName] = useState("")
  const [mobile, setMobile] = useState("")
  const [address, setAddress] = useState("")
  const [additional, setAdditional] = useState("")
  const [percentage, setPercentage] = useState("")

  const [partnerlist, setPartnerlist] = useState([])

  //  edit action
  const [edit_partner_modal, setEdit_partner_modal] = useState(false)
  const [update_partner_id, setupdate_partner_id] = useState(initialState)
  const [new_name, setNew_name] = useState(initialState)
  const [new_mobile, setNew_mobile] = useState(initialState)
  const [new_address, setNew_address] = useState(initialState)
  const [new_additional, setNew_additional] = useState(initialState)
  const [new_percentage, setNew_percentage] = useState(initialState)

  function handleShow() {
    setShow(true)
  }

  function handleClose() {
    setName("")
    setMobile("")
    setAddress("")
    setAdditional("")
    setPercentage("")
    setResultmsg("")
    setResulttype("")
    setShow(false)
  }

  function handlePartnerClose() {
    setupdate_partner_id("")
    setNew_name("")
    setNew_mobile("")
    setNew_address("")
    setNew_additional("")
    setNew_percentage("")
    setResultmsg("")
    setResulttype("")
    setEdit_partner_modal(false)
  }

  function create_partner() {
    var url = "https://jeetlabs.in/Api/create_partner"
    var items = { name, mobile, address, additional, percentage }

    if (name.length < 1) {
      setResultmsg(" At least Name is Required")
      setResulttype("error")
    } else {
      setResultmsg("Partner Creation in Progress")
      setResulttype("info")

      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            partners_list()
            setTimeout(function () {
              handleClose()
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  function partners_list() {
    var url = "https://jeetlabs.in/Api/partners_list"

    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          // console.log(result)
          setPartnerlist(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  function search_partners_list(value) {
    var url = "https://jeetlabs.in/Api/search_partners"
    var items = { data: value }

    let result = fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(items),
    })
      .then(response => {
        response.json().then(result => {
          console.log(result)
          if (result.type === "error" && result.msg === "No Data Found") {
            setPartnerlist([])
          } else {
            setPartnerlist(result)
          }
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  function update_partner() {
    var url = "https://jeetlabs.in/Api/update_partner"
    var items = {
      id: update_partner_id,
      name: new_name,
      phone: new_mobile,
      address: new_address,
      additional: new_additional,
      percentage: new_percentage,
    }

    if (
      update_partner_id.length < 1 ||
      new_name.length < 1 ||
      new_mobile.length < 1 ||
      new_address.length < 1 ||
      new_additional.length < 1 ||
      new_percentage.length < 1
    ) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Partner Update in Progress")
      setResulttype("info")

      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            partners_list()
            setTimeout(function () {
              handlePartnerClose()
              //   history.push('/Myorder')
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  var userid = JSON.parse(localStorage.getItem("userid"))
  var token = JSON.parse(localStorage.getItem("token"))

  function delete_data(branch_id) {
    var url = "https://jeetlabs.in/Api/delete_partner"
    var confirm = window.confirm("Confirm Delete")

    var items = { userid, token, branch_id }

    if (confirm) {
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            // console.log(result);
            // setResultmsg(result.msg)
            // setResulttype(result.type)

            partners_list()
            setTimeout(function () {
              handleClose()
              //   history.push('/Myorder')
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    } else {
      // console.log(branch_id+ ' is safe')
    }
  }

  useEffect(() => {
    partners_list()
  }, [])

  // let history = useHistory();

  const getTableData = () => {
    var rows = []
    partnerlist.map((eachRow, eachRowIndex) => {
      var obj = {
        "S.No": "S.No",
        Name: "Name",
        Mobile: "Mobile",
        Address: "Address",
        Additional: "Additional",
        Percentage: "Percentage",
      }
      obj["S.No"] = eachRowIndex + 1
      obj["Name"] = eachRow.pt_name
      obj["Mobile"] = eachRow.pt_mobile_no
      obj["Address"] = eachRow.pt_address
      obj["Additional"] = eachRow.pt_additional
      obj["Percentage"] = eachRow.pt_percentage

      rows.push(obj)
    })
    console.log("rows", rows)
    return rows
  }
  const exportToExcel = () => {
    var rows = getTableData()

    const sheet_names = ["sheet 1"]
    const sheets = {
      [sheet_names[0]]: XLSX.utils.json_to_sheet(rows),
    }
    const wb = {
      SheetNames: sheet_names,
      Sheets: sheets,
    }

    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    })
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const fileNameWithExt = "PartenersList.xlsx"

    const data = new Blob([excelBuffer], { type: fileType })
    const url = URL.createObjectURL(data)
    var el = document.createElement("a")
    el.href = url
    el.download = fileNameWithExt
    el.click()

    URL.revokeObjectURL(url)
  }
  return (
    <div>
      <Sidebar />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar />

        <div>
          <div
            sx={{ p: 3, m: 1 }}
            style={{
              marginTop: 20,
              marginBottom: 20,
              padding: 10,
              borderRadius: "12px",
              display: "flex",
              justifyContent: "space-between",
              border: "0.5px  lightgrey",
            }}
          >
            {/* <Typography variant='h6' sx={{ fontWeight: "medium" }}>
            
              Partners List
            </Typography> */}
            <CustomInput
              aria-label='Search'
              onChange={e => {
                search_partners_list(e.target.value)
              }}
              placeholder='Search something...'
            />
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleShow()}
              className='modified_button'
            >
              + Create{" "}
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Button
              sx={{ p: 2 }}
              onClick={exportToExcel}
              endIcon={<ArrowDownwardIcon />}
            >
              Export
            </Button>
            <Table sx={{ minWidth: "550px" }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Address
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Additional
                  </TableCell>
                  <TableCell
                    width='15%'
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Percentage
                  </TableCell>
                  <TableCell
                    width='15%'
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partnerlist.map(partner => (
                  <TableRow>
                    <TableCell
                      align='center'
                      sx={{ fontWeight: 500, textTransform: "capitalize" }}
                    >
                      {partner.pt_name}
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ fontWeight: 500, textTransform: "capitalize" }}
                    >
                      {" "}
                      {partner.pt_mobile_no}{" "}
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ fontWeight: 500, textTransform: "capitalize" }}
                    >
                      {partner.pt_address}
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ fontWeight: 500, textTransform: "capitalize" }}
                    >
                      {" "}
                      {partner.pt_additional}{" "}
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ fontWeight: 500, textTransform: "capitalize" }}
                    >
                      {" "}
                      {partner.pt_percentage}
                      {"%"}
                    </TableCell>
                    <TableCell width='15%' align='center'>
                      <Tooltip title='View Details'>
                        <IconButton aria-label='View Details' color='info'>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title='Edit'>
                        <IconButton aria-label='Edit' color='success'>
                          <CreateIcon
                            onClick={e => {
                              setupdate_partner_id(partner.pt_id)
                              setNew_name(partner.pt_name)
                              setNew_mobile(partner.pt_mobile_no)
                              setNew_address(partner.pt_address)
                              setNew_additional(partner.pt_additional)
                              setNew_percentage(partner.pt_percentage)
                              setEdit_partner_modal(true)
                            }}
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title='Delete'>
                        <IconButton
                          aria-label='Delete'
                          color='error'
                          onClick={() => delete_data(partner.pt_id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>

      <Modal
        show={show}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handleClose()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>Add Partner</span>
        </Modal.Header>

        <Modal.Body>
          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Name'
                sx={{ mb: 2 }}
                onChange={e => setName(e.target.value)}
              />
            </Col>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Mobile No'
                sx={{ mb: 2 }}
                type='number'
                onChange={e => setMobile(e.target.value)}
              />
            </Col>
          </Row>

          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Address'
                multiline
                rows={4}
                sx={{ mb: 2 }}
                onChange={e => setAddress(e.target.value)}
              />
            </Col>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Additional'
                sx={{ mb: 2 }}
                multiline
                rows={4}
                onChange={e => setAdditional(e.target.value)}
              />
            </Col>
          </Row>
          <Row className='g-2'>
            <Col md>
              <TextField
                required
                id='outlined-required'
                label='Percentage'
                sx={{ mb: 2, width: "50%" }}
                onChange={e => setPercentage(e.target.value)}
              />
            </Col>
          </Row>
          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => create_partner()}
            color='success'
            sx={{ m: 1 }}
          >
            Add Partner
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={edit_partner_modal}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handlePartnerClose()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>Edit Partner</span>
        </Modal.Header>

        <Modal.Body>
          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Name'
                sx={{ mb: 2 }}
                value={new_name}
                onChange={e => setNew_name(e.target.value)}
              />
            </Col>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Mobile No'
                sx={{ mb: 2 }}
                type='number'
                value={new_mobile}
                onChange={e => setNew_mobile(e.target.value)}
              />
            </Col>
          </Row>

          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Address'
                multiline
                rows={4}
                sx={{ mb: 2 }}
                value={new_address}
                onChange={e => setNew_address(e.target.value)}
              />
            </Col>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Additional'
                sx={{ mb: 2 }}
                multiline
                rows={4}
                value={new_additional}
                onChange={e => setNew_additional(e.target.value)}
              />
            </Col>
          </Row>
          <Row className='g-2'>
            <Col md>
              <TextField
                required
                id='outlined-required'
                label='Percentage'
                sx={{ mb: 2, width: "50%" }}
                value={new_percentage}
                onChange={e => setNew_percentage(e.target.value)}
              />
            </Col>
          </Row>
          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handlePartnerClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => update_partner()}
            color='success'
            sx={{ m: 1 }}
          >
            Update Partner
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
