import React,{} from 'react'
import Sidebar from './parts/Sidebar'
import {Toolbar,Box,Table, TableBody, TableCell, TableContainer, TableHead, TableRow,IconButton} from '@mui/material';
// import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { useInput } from '@mui/core';
import { styled } from '@mui/system';

const StyledInputElement = styled('input')`
  width: 200px;
  font-size: 1rem;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  background: #fff;
  border: 1px solid #e5e8ec;
  border-radius: 10px;
  padding: 6px 10px;
  color: #20262d;
  transition: width 300ms ease;

  &:hover {
    background: #fff;
    border-color: #e5e8ec;
  }

  &:focus {
    outline: none;
    width: 220px;
    transition: width 200ms ease-out;
  }
`;

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  const { getRootProps, getInputProps } = useInput(props, ref);

  return (
    <div {...getRootProps()}>
      <StyledInputElement {...props} {...getInputProps()} />
    </div>
  );
});



const drawerWidth = 280;


// const Item = styled(Paper)(({ theme }) => ({
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     elevation:'0',
//     color: theme.palette.text.secondary,
//   }));

  

export default function Activity() {
  
  // let history = useHistory(); 


  const activity = [
    {
      user:'anil',
      message:'Login to Panel',
      time:'12 Nov 2021 12:34 PM'
    },{
      user:'Sunil',
      message:'Login to Panel',
      time:'12 Nov 2021 12:34 PM'
    },{
      user:'anil',
      message:'Created Test Report 12:46 pm Report No 309',
      time:'12 Nov 2021 12:34 PM'
    },{
      user:'Sunil',
      message:'Login to Panel',
      time:'12 Nov 2021 12:34 PM'
    },{
      user:'Arun',
      message:'Moved Report to Trash report no 567',
      time:'12 Nov 2021 12:34 PM'
    },{
      user:'Sam',
      message:'Login to Panel',
      time:'12 Nov 2021 12:34 PM'
    },{
      user:'Pardeep',
      message:'Login to Panel',
      time:'12 Nov 2021 12:34 PM'
    },{
      user:'Manjeet',
      message:'Edited Report 123. ',
      time:'12 Nov 2021 12:34 PM'
    },
  ]

  
    return (
        <div> 
            <Sidebar/>
            <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` },ml:{sm:`${drawerWidth}px`} }}
      >
        <Toolbar />

        <div>
          <div sx={{p:3,m:1}} style={{marginTop:20,marginBottom:20,padding:10,borderRadius:'12px' ,display:'flex',justifyContent:'end',border:'0.5px  lightgrey'}} >
            
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
            <CustomInput aria-label="Search" placeholder="Search something..." />
            <IconButton aria-label="Delete" color="info">
                                              <SearchIcon />
                                            </IconButton>
            </div>
          </div>
          <TableContainer component={Paper}>
              <Table sx={{minWidth:'550px'}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="15%" align="left" sx={{fontWeight:600,fontSize:'110%'}} >user</TableCell>
                    <TableCell width="50%"  align="center" sx={{fontWeight:600,fontSize:'110%'}} >Activity</TableCell>
                    <TableCell width="25%"  align="center" sx={{fontWeight:600,fontSize:'110%'}} >Time</TableCell>
                    <TableCell width="10%"  align="center" sx={{fontWeight:600,fontSize:'110%'}} >Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activity.map((activity)=>(
                  <TableRow>
                    
                                      <TableCell width="15%" align="left" sx={{fontWeight:500,textTransform:'capitalize'}} >{activity.user}</TableCell>
                                       <TableCell width="50%"  align="center">{activity.message}</TableCell>
                                       <TableCell width="25%"  align="center">{activity.time}</TableCell>
                                      <TableCell width="10%" align="center" >
                                          <IconButton aria-label="Edit" color="success">
                                              <CreateIcon />
                                            </IconButton>
                                            <IconButton aria-label="Delete" color="secondary">
                                              <DeleteIcon />
                                            </IconButton>
                                      </TableCell>

                  </TableRow>
                  ))}

                </TableBody>
              </Table>
          </TableContainer>


        </div>





      </Box>



        </div>
    )
}
