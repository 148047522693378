import React, { useState, useEffect } from "react"
import Sidebar from "./parts/Sidebar"
import {
  Button,
  TextField,
  Alert,
  Toolbar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import { useHistory } from "react-router-dom"
import CreateIcon from "@mui/icons-material/Create"
import DeleteIcon from "@mui/icons-material/Delete"
import { Modal, Row, Col } from "react-bootstrap"

const drawerWidth = 280

// const Item = styled(Paper)(({ theme }) => ({
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     elevation:'0',
//     color: theme.palette.text.secondary,
//   }));

export default function AgeGroup() {
  var initialState = ""

  const [show, setShow] = useState(false)
  const [minage, setMinage] = useState("")
  const [maxage, setMaxage] = useState("")

  const [agegroup_list, setAgegroup_list] = useState([])

  const [resultmsg, setResultmsg] = useState("")
  const [resulttype, setResulttype] = useState("")

  // const [new_min_age, setEditAge] = useState(false);
  // const[minage,setMinAge] = useState('');
  // const[maxage,setMaxAge] = useState('');

  const [edit_age_group_modal, setEdit_age_group_modal] = useState(false)
  const [update_group_id, setupdate_group_id] = useState(initialState)
  const [new_min_age, setNew_min_age] = useState(initialState)
  const [new_min_age_unit, setNew_min_age_unit] = useState(initialState)
  const [new_max_age, setNew_max_age] = useState(initialState)
  const [new_max_age_unit, setNew_max_age_unit] = useState(initialState)

  function handleShow() {
    setShow(true)
  }

  const show_edit_age_group_modal = e => {
    var data = e.target.dataset
    if (data.id == null) {
      alert("Click Again")
    } else {
      setupdate_group_id(data.id)
      setNew_min_age(data.min)
      setNew_min_age_unit(data.minunit)
      setNew_max_age(data.max)
      setNew_max_age_unit(data.maxunit)
      setEdit_age_group_modal(true)
    }
  }

  function handleClose() {
    setMinage("")
    setMaxage("")
    setResultmsg("")
    setResulttype("")
    setShow(false)
  }

  function handleAgeClose() {
    setNew_min_age("")
    setNew_min_age_unit("")
    setNew_max_age("")
    setNew_max_age_unit("")
    setResultmsg("")
    setResulttype("")
    setEdit_age_group_modal(false)
  }

  function create_age_group() {
    var url = "https://jeetlabs.in/Api/create_age_group"
    var items = { minage, maxage }

    if (minage.length < 1 || maxage.length < 1) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Age Group Creation in Progress")
      setResulttype("info")

      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            age_group_list()
            setTimeout(function () {
              handleClose()
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  function age_group_list() {
    var url = "https://jeetlabs.in/Api/age_group_list"

    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          // console.log(result)
          setAgegroup_list(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  function update_age() {
    var url = "https://jeetlabs.in/Api/update_age_group"
    var items = {
      age_group_id: update_group_id,
      age_group_min: new_min_age,
      age_group_max: new_max_age,
      age_group_min_unit: new_min_age_unit,
      age_group_max_unit: new_max_age_unit,
    }

    if (
      new_min_age.length < 1 ||
      new_max_age.length < 1 ||
      new_min_age_unit.length < 1 ||
      new_max_age_unit.length < 1
    ) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Age Group Update in Progress")
      setResulttype("info")

      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            age_group_list()
            setTimeout(function () {
              handleAgeClose()
              //   history.push('/Myorder')
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  var userid = JSON.parse(localStorage.getItem("userid"))
  var token = JSON.parse(localStorage.getItem("token"))

  function delete_data(branch_id) {
    var url = "https://jeetlabs.in/Api/delete_age_group"
    var confirm = window.confirm("Confirm Delete")

    var items = { userid, token, branch_id }

    if (confirm) {
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            // console.log(result);
            // setResultmsg(result.msg)
            // setResulttype(result.type)

            age_group_list()
            setTimeout(function () {
              handleClose()
              //   history.push('/Myorder')
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    } else {
      // console.log(branch_id+ ' is safe')
    }
  }

  useEffect(() => {
    age_group_list()
  }, [])

  // let history = useHistory();

  return (
    <div>
      <Sidebar />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar />

        <div>
          <div
            sx={{ p: 3, m: 1 }}
            style={{
              marginTop: 20,
              marginBottom: 20,
              padding: 10,
              borderRadius: "12px",
              display: "flex",
              justifyContent: "space-between",
              border: "0.5px  lightgrey",
            }}
          >
            <Typography variant='h6' sx={{ fontWeight: "medium" }}>
              Age Groups
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleShow()}
              className='modified_button'
            >
              + Create{" "}
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "550px" }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell
                    width='35%'
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Min
                  </TableCell>
                  <TableCell
                    width='35%'
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Max
                  </TableCell>
                  <TableCell
                    width='30%'
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agegroup_list.map(group => (
                  <TableRow>
                    <TableCell
                      width='35%'
                      align='center'
                      sx={{ fontWeight: 500, textTransform: "capitalize" }}
                    >
                      {group.min_age_num}&nbsp;{group.min_age_unit}
                    </TableCell>
                    <TableCell
                      width='35%'
                      align='center'
                      sx={{ fontWeight: 500, textTransform: "capitalize" }}
                    >
                      {" "}
                      {group.max_age_num}&nbsp;{group.max_age_unit}{" "}
                    </TableCell>
                    <TableCell width='30%' align='center'>
                      <IconButton
                        aria-label='Edit'
                        color='success'
                        // data-id={group.age_group_id} data-min={group.min_age_num} data-minunit={group.min_age_unit} data-max={group.max_age_num} data-maxunit={group.max_age_unit}
                        // onClick={(e)=>show_edit_age_group_modal(e)}
                      >
                        <CreateIcon
                          data-id={group.age_group_id}
                          data-min={group.min_age_num}
                          data-minunit={group.min_age_unit}
                          data-max={group.max_age_num}
                          data-maxunit={group.max_age_unit}
                          onClick={e => show_edit_age_group_modal(e)}
                        />
                      </IconButton>
                      <IconButton
                        aria-label='Delete'
                        color='secondary'
                        onClick={() => delete_data(group.age_group_id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>

      <Modal
        show={show}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handleClose()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>Create Age Group</span>
        </Modal.Header>

        <Modal.Body>
          <TextField
            fullWidth
            required
            id='outlined-required'
            label='Min Age'
            sx={{ mb: 2 }}
            onChange={e => setMinage(e.target.value)}
          />

          <TextField
            fullWidth
            required
            id='outlined-required'
            label='Max Age'
            sx={{ mb: 2 }}
            onChange={e => setMaxage(e.target.value)}
          />

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => create_age_group()}
            color='success'
            sx={{ m: 1 }}
          >
            Save Group
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={edit_age_group_modal}
        backdrop='static'
        centered
        style={{ background: "#808080db", zIndex: "10000" }}
        size='lg'
        onHide={() => handleAgeClose()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>Edit Age Group</span>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Min Age'
                value={new_min_age}
                sx={{ mb: 2 }}
                onChange={e => setNew_min_age(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Min Age Unit'
                value={new_min_age_unit}
                sx={{ mb: 2 }}
                onChange={e => setNew_min_age_unit(e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Max Age'
                value={new_max_age}
                sx={{ mb: 2 }}
                onChange={e => setNew_max_age(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Max Age Unit'
                value={new_max_age_unit}
                sx={{ mb: 2 }}
                onChange={e => setNew_max_age_unit(e.target.value)}
              />
            </Col>
          </Row>

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleAgeClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => update_age()}
            color='success'
            sx={{ m: 1 }}
          >
            Update Age
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
