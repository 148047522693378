import { useEffect, useState } from "react"
import { Modal, TabContainer } from "react-bootstrap"
import {
  Button,
  Alert,
  TextField,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
  Card,
  CardContent,
  Table,
  TableRow,
  TableCell,
  Stack,
  Box,
  Toolbar
} from "@mui/material"
import Sidebar from './parts/Sidebar'

import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DateTimePicker from "@mui/lab/DateTimePicker"

import XLSX from "xlsx"
import {
  Add,
  ImportExport,
  Download,
  Update,
  Edit,
  Delete,
} from "@mui/icons-material"

const drawerWidth = 280;


export function PaymentModeModal(props) {
  console.log("Payment Mode Screen", props)

  const [show, setShow] = useState(false)

  const [paymentModeType, setPaymentModeType] = useState("")
  const [paymentDetails, setPaymentDetails] = useState("")

  const [resultmsg, setResultmsg] = useState("")
  const [resulttype, setResulttype] = useState("")

  const resetForm = () => {
    setPaymentModeType("")
    setPaymentDetails("")
    setResultmsg("")
    setResulttype("")
  }

  function handleShow() {
    setShow(true)
  }
  function handleClose() {
    setShow(false)
    resetForm()
  }

  function add_payment_modes() {
    var items = {
      pmt_type: paymentModeType,
      payment_details: paymentDetails,
    }

    console.log("payment modes", items)

    if (paymentModeType.length < 1 || paymentDetails.length < 1) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Add Payment Modes in Progress")
      setResulttype("info")

      var url = "https://jeetlabs.in/Api/add_payment_modes"
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            setTimeout(function () {
              handleClose()
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  return (
    <>
      <Button
        variant='contained'
        onClick={() => handleShow()}
        color='primary'
        sx={{ mx: 2 }}
        startIcon={<Add />}
      >
        Add Payment Modes
      </Button>

      <Modal
        show={show}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handleClose()}
      >
        <Modal.Header>
          <span style={{ fontWeight: 600 }}>Modal 1</span>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              required
              id='outlined-required'
              label='Payment Mode Type'
              value={paymentModeType}
              sx={{ mb: 2, width: "92%", mr: 2 }}
              onChange={e => setPaymentModeType(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              required
              id='outlined-required'
              label='Payment Details'
              sx={{ mb: 2, width: "92%", mr: 2 }}
              multiline
              rows={3}
              value={paymentDetails}
              onChange={e => setPaymentDetails(e.target.value)}
            />
          </div>

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => add_payment_modes()}
            color='success'
            sx={{ m: 1 }}
          >
            + Payment Modes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export function IncomeExpenseModal(props) {
  console.log("Payment Details Screen 2", props)

  const [show, setShow] = useState(false)

  const [incomeExpenseType, setIncomeExpenseType] = useState("")
  const [incomeExpenseDetails, setIncomeExpenseDetails] = useState("")

  const [resultmsg, setResultmsg] = useState("")
  const [resulttype, setResulttype] = useState("")

  const resetForm = () => {
    setIncomeExpenseType("")
    setIncomeExpenseDetails("")

    setResultmsg("")
    setResulttype("")
  }

  function handleShow() {
    setShow(true)
  }
  function handleClose() {
    setShow(false)
    resetForm()
  }

  function add_income_expense_types() {
    var items = {
      iet_type: incomeExpenseType,
      iet_details: incomeExpenseDetails,
    }

    console.log("Income/Expense Types", items)
    if (incomeExpenseType.length < 1 || setIncomeExpenseDetails.length < 1) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Add Income/Expense in Progress")
      setResulttype("info")

      var url = "https://jeetlabs.in/Api/add_income_expense_type"
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            setTimeout(function () {
              handleClose()
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  return (
    <>
      <Button
        variant='contained'
        onClick={() => handleShow()}
        color='primary'
        sx={{ mx: 2 }}
        startIcon={<Add />}
      >
        CREATE INCOME/EXPENSE TYPES
      </Button>

      <Modal
        show={show}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handleClose()}
      >
        <Modal.Header>
          <span style={{ fontWeight: 600 }}>Modal 2</span>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl
              fullWidth
              style={{
                display: "flex",
                justifyContent: "center!important",
                alignItems: "center",
              }}
            >
              <InputLabel sx={{ ml: 4 }} id='demo-simple-select-label'>
                Select Income/Expense Type
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={incomeExpenseType}
                label='Select Income/Expense Type'
                onChange={e => setIncomeExpenseType(e.target.value)}
                sx={{ width: "92%", mb: 2 }}
                style={{ zIndex: 10000 }}
              >
                <MenuItem value='income'>Income</MenuItem>
                <MenuItem value='expense'>Expense</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              required
              id='outlined-required'
              label='Enter Income/Expense Details'
              value={incomeExpenseDetails}
              sx={{ mb: 2, width: "92%" }}
              onChange={e => setIncomeExpenseDetails(e.target.value)}
            />
          </div>

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              // if (isUpdating) {
              //   update_payment_details()
              // } else {
              add_income_expense_types()
              // }
            }}
            color='success'
            sx={{ m: 1 }}
          >
            ADD INCOME/EXPENSE TYPES
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export function BankingPage(props) {
  const [show, setShow] = useState(false)

  const [bankingId, setBankingId] = useState(0)
  const [bankingType, setBankingType] = useState("")

  const [amount, setAmount] = useState("")
  const [date, setDate] = useState(new Date().toJSON())

  const [currentPaymentMode, setCurrentPaymentMode] = useState("")
  const [paymentModes, setPaymentModes] = useState([])
  const [currentIncomeExpenseType, setCurrentIncomeExpenseType] = useState("")
  const [incomeExpenseTypes, setIncomeExpenseTypes] = useState([])

  const [creditNote, setCreditNote] = useState("")
  const [resultmsg, setResultmsg] = useState("")
  const [resulttype, setResulttype] = useState("")
  const actions = {
    create: "create",
    update: "update",
  }
  const [action, setAction] = useState("")

  const resetForm = () => {
    setBankingType("")

    setAmount("")
    setDate(new Date().toJSON())

    setCurrentPaymentMode("")
    setPaymentModes([])
    setCurrentIncomeExpenseType("")
    setIncomeExpenseTypes([])

    setCreditNote("")
    setAction("")
    setResultmsg("")
    setResulttype("")
  }

  function handleShow() {
    get_income_expense_types()
    get_payment_modes()
    setShow(true)
  }
  function handleClose() {
    setShow(false)
    resetForm()
  }

  function add_banking_details() {
    var items = {
      banking_type: bankingType,
      banking_amount: amount,
      banking_datetime: date,
      payment_mode: currentPaymentMode,
      payment_type: currentIncomeExpenseType,
      banking_note: creditNote,
    }

    console.log(items)
    if (
      bankingType.length < 1 ||
      amount.length < 1 ||
      date.length < 1 ||
      currentPaymentMode.length < 1 ||
      currentIncomeExpenseType.length < 1 ||
      creditNote.length < 1
    ) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Add Payment Details in Progress")
      setResulttype("info")

      var url = "https://jeetlabs.in/Api/add_banking"
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            console.log("create banking details", result)
            setResultmsg(result.msg)
            setResulttype(result.type)

            setTimeout(function () {
              handleClose()
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }
  function update_banking_details() {
    var items = {
      banking_id: bankingId,
      banking_type: bankingType,
      banking_amount: amount,
      banking_datetime: date,
      payment_mode: currentPaymentMode,
      payment_type: currentIncomeExpenseType,
      banking_note: creditNote,
    }

    if (
      bankingId.length < 1 ||
      bankingType.length < 1 ||
      amount.length < 1 ||
      date.length < 1 ||
      currentPaymentMode.length < 1 ||
      currentIncomeExpenseType.length < 1 ||
      creditNote.length < 1
    ) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Update Payment Details is in Progress")
      setResulttype("info")

      var url = "https://jeetlabs.in/Api/update_banking"
      let result = fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            console.log("update banking details ", result)

            setResultmsg(result.msg)
            setResulttype(result.type)

            setTimeout(function () {
              handleClose()
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }
  var userid = JSON.parse(localStorage.getItem("userid"))
  var token = JSON.parse(localStorage.getItem("token"))

  function delete_data(branch_id) {
    var url = "https://jeetlabs.in/Api/delete_banking"
    var confirm = window.confirm("Confirm Delete")

    var items = { userid, token, branch_id }

    if (confirm) {
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            console.log(result)
            // setResultmsg(result.msg)
            // setResulttype(result.type)

            get_banking_details()
            setTimeout(function () {
              handleClose()
              //   history.push('/Myorder')
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    } else {
      // console.log(branch_id+ ' is safe')
    }
  }

  const getTableData = () => {
    var rows = []
    tableData.map((eachRow, eachRowIndex) => {
      var obj = {
        "S.No": "",
        "Banking Type": "",
        Amount: "",
        Date: "",
        "Payment Mode": "",
        "Payment Type": "",
        "Credit Note": "",
      }
      obj["S.No"] = eachRowIndex + 1
      obj["Banking Type"] = eachRow.banking_type
      obj["Amount"] = eachRow.banking_amount
      obj["Date"] = eachRow.banking_datetime
      obj["Payment Mode"] = eachRow.payment_mode
      obj["Payment Type"] = eachRow.payment_type
      obj["Credit Note"] = eachRow.banking_note

      rows.push(obj)
    })
    console.log("rows", rows)
    return rows
  }
  const exportToExcel = () => {
    var rows = getTableData()

    const sheet_names = ["sheet 1"]
    const sheets = {
      [sheet_names[0]]: XLSX.utils.json_to_sheet(rows),
    }
    const wb = {
      SheetNames: sheet_names,
      Sheets: sheets,
    }

    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    })
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const fileNameWithExt = "download.xlsx"

    const data = new Blob([excelBuffer], { type: fileType })
    const url = URL.createObjectURL(data)
    var el = document.createElement("a")
    el.href = url
    el.download = fileNameWithExt
    el.click()

    URL.revokeObjectURL(url)
  }

  const [tableData, setTableData] = useState([])
  function get_banking_details() {
    var url = "https://jeetlabs.in/Api/get_banking"
    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          console.log("get banking details ", result)
          setTableData(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }
  useEffect(() => {
    get_banking_details()
  }, [])
  const addTableData = data => {
    const id = tableData.length
    const obj = { id, ...data }
    setTableData(values => [...values, obj])
  }
  const updateTableData = data => {
    const { id } = data
    var result = []
    tableData.map(el => {
      if (el.id === id) {
        result.push({ ...el, ...data })
      } else {
        result.push(el)
      }
    })
    setTableData(result)
  }
  const deleteTableData = id => {
    if (window.confirm("Confirm Delete")) {
      const result = tableData.filter(el => el.id !== id)

      setTableData(result)
    }
  }

  console.log("tableData", tableData)

  function get_income_expense_types() {
    var url = "https://jeetlabs.in/Api/get_income_expense_type"
    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          console.log("get_income_expense_types", result)
          setIncomeExpenseTypes(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }
  function get_payment_modes() {
    var url = "https://jeetlabs.in/Api/get_payment_modes"
    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          console.log("get_payment_modes", result)
          setPaymentModes(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  return (
    <>
      <Button
        variant='contained'
        onClick={() => {
          setAction(actions.create)
          handleShow()
        }}
        color='primary'
        sx={{ mx: 2 }}
        startIcon={<Add />}
      >
        CREATE PAYMENT DETAILS
      </Button>

      <TabContainer>
        <Button
          onClick={exportToExcel}
          variant='contained'
          startIcon={<ImportExport />}
          sx={{ mx: 2 }}
        >
          Export{" "}
        </Button>
        <hr />
        <Table>
          <TableRow sx={{ m: 0, p: 0 }}>
            <TableCell
              align='center'
              as='head'
              style={{
                fontWeight: "bold",
                color: "darkcyan",
                margin: 0,
                fontSize: "small",
              }}
            >
              S.No
            </TableCell>
            <TableCell
              align='center'
              as='head'
              style={{
                fontWeight: "bold",
                color: "darkcyan",
                margin: 0,
                fontSize: "small",
              }}
            >
              Banking Type
            </TableCell>
            <TableCell
              align='center'
              as='head'
              style={{
                fontWeight: "bold",
                color: "darkcyan",
                margin: 0,
                fontSize: "small",
              }}
            >
              Amount
            </TableCell>
            <TableCell
              align='center'
              as='head'
              style={{
                fontWeight: "bold",
                color: "darkcyan",
                margin: 0,
                fontSize: "small",
              }}
            >
              Date
            </TableCell>
            <TableCell
              align='center'
              as='head'
              style={{
                fontWeight: "bold",
                color: "darkcyan",
                margin: 0,
                fontSize: "small",
              }}
            >
              Payment Mode
            </TableCell>
            <TableCell
              align='center'
              as='head'
              style={{
                fontWeight: "bold",
                color: "darkcyan",
                margin: 0,
                fontSize: "small",
              }}
            >
              Payment Type
            </TableCell>

            <TableCell
              align='center'
              as='head'
              style={{
                fontWeight: "bold",
                color: "darkcyan",
                margin: 0,
                fontSize: "small",
              }}
            >
              Credit Note
            </TableCell>
            <TableCell
              align='center'
              as='head'
              style={{
                fontWeight: "bold",
                color: "darkcyan",
                margin: 0,
                fontSize: "small",
              }}
            >
              Actions
            </TableCell>
          </TableRow>
          {tableData.map((eachRow, eachRowIndex) => {
            return (
              <TableRow key={eachRowIndex}>
                <TableCell align='center'>
                  <span
                    style={{
                      fontWeight: "bolder",
                      textTransform: "capitalize",
                      fontSize: "small",
                    }}
                  >
                    {eachRowIndex + 1}
                  </span>
                </TableCell>

                <TableCell align='center'>
                  <span
                    style={{
                      fontWeight: "bolder",
                      textTransform: "capitalize",
                      fontSize: "small",
                      color:
                        eachRow.option === "income"
                          ? "green"
                          : eachRow.option === "expense"
                          ? "red"
                          : "currentcolor",
                    }}
                  >
                    {eachRow.banking_type}
                  </span>
                </TableCell>

                <TableCell align='center'>
                  <span
                    style={{
                      fontWeight: "bolder",
                      textTransform: "capitalize",
                      fontSize: "small",
                    }}
                  >
                    {eachRow.banking_amount}
                  </span>
                </TableCell>
                <TableCell align='center'>
                  <span
                    style={{
                      fontWeight: "bolder",
                      textTransform: "capitalize",
                      fontSize: "small",
                    }}
                  >
                    {eachRow.banking_datetime}
                  </span>
                </TableCell>
                <TableCell align='center'>
                  <span
                    style={{
                      fontWeight: "bolder",
                      textTransform: "capitalize",
                      fontSize: "small",
                    }}
                  >
                    {eachRow.pmt_type}
                  </span>
                </TableCell>
                <TableCell align='center'>
                  <span
                    style={{
                      fontWeight: "bolder",
                      textTransform: "capitalize",
                      fontSize: "small",
                    }}
                  >
                    {eachRow.iet_type}
                  </span>
                </TableCell>

                <TableCell align='center'>
                  <span
                    style={{
                      fontWeight: "bolder",
                      textTransform: "capitalize",
                      fontSize: "small",
                    }}
                  >
                    {eachRow.banking_note}
                  </span>
                </TableCell>
                <TableCell align='center'>
                  <IconButton
                    color='success'
                    onClick={() => {
                      setAction(actions.update)
                      //  destructure the fields
                      var {
                        banking_id,
                        banking_type,
                        banking_amount,
                        banking_datetime,
                        payment_mode,
                        payment_type,
                        banking_note,
                      } = eachRow

                      //  update the state
                      setBankingId(banking_id)
                      setBankingType(banking_type)
                      setAmount(banking_amount)
                      setCurrentPaymentMode(payment_mode)
                      setCurrentIncomeExpenseType(payment_type)
                      setDate(banking_datetime)
                      setCreditNote(banking_note)

                      //  show the modal
                      handleShow()
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color='error'
                    onClick={() => delete_data(eachRow.banking_id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
      </TabContainer>

      <Modal
        show={show}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handleClose()}
      >
        <Modal.Header>
          <span style={{ fontWeight: 600 }}>Modal 3</span>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl
              fullWidth
              style={{
                display: "flex",
                justifyContent: "center!important",
                alignItems: "center",
              }}
            >
              <InputLabel sx={{ ml: 4 }} id='demo-simple-select-label'>
                Select Banking Type
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={bankingType}
                label='Select Banking Type'
                onChange={e => setBankingType(e.target.value)}
                sx={{ width: "92%", mb: 2 }}
                style={{ zIndex: 10000 }}
              >
                <MenuItem value='income'>Income</MenuItem>
                <MenuItem value='expense'>Expense</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TextField
              required
              id='outlined-required'
              label='Amount'
              value={amount}
              sx={{ mb: 2, width: "45%", mr: 2 }}
              onChange={e => setAmount(e.target.value)}
            />

            {/* <TextField
                fullWidth
                required
                type='datetime-local'
                id='outlined-required'
                label='Date'
                placeholder=''
                value={date}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2, width: "45%" }}
                onChange={e => setDate(e.target.value)}
              />
               */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                required
                inputFormat='dd/MM/yyyy hh:mm a'
                label='Date Time'
                renderInput={params => (
                  <TextField {...params} sx={{ mb: 2, width: "45%" }} />
                )}
                value={date}
                showTodayButton
                onChange={value => setDate(value)}
              />
            </LocalizationProvider>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl
              fullWidth
              style={{
                display: "flex",
                justifyContent: "center!important",
                alignItems: "center",
              }}
            >
              <InputLabel sx={{ ml: 4 }} id='demo-simple-select-label'>
                Select Payment Mode
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='select-income-type'
                value={currentPaymentMode}
                label='Select Payment Mode'
                onChange={e => setCurrentPaymentMode(e.target.value)}
                sx={{ width: "90%", mb: 2, ml: 3 }}
                style={{ zIndex: 10000 }}
              >
                {paymentModes.map((eachPaymentMode, index) => {
                  return (
                    <MenuItem value={eachPaymentMode.pmt_id}>
                      {eachPaymentMode.pmt_type}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              style={{
                display: "flex",
                justifyContent: "center!important",
                alignItems: "center",
              }}
            >
              <InputLabel sx={{ ml: 2 }} id='demo-simple-select-label'>
                Select Income/Expense Type
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='select-expense-type'
                value={currentIncomeExpenseType}
                label='Select Income/Expense Type'
                onChange={e => setCurrentIncomeExpenseType(e.target.value)}
                sx={{ width: "90%", mb: 2, mr: 2 }}
                style={{ zIndex: 10000 }}
              >
                {incomeExpenseTypes.map((eachIncomeExpenseType, index) => {
                  return (
                    <MenuItem value={eachIncomeExpenseType.iet_id}>
                      {eachIncomeExpenseType.iet_type}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              required
              id='outlined-required'
              label='Credit Note'
              sx={{ mb: 2, width: "92%" }}
              multiline
              rows={3}
              value={creditNote}
              onChange={e => setCreditNote(e.target.value)}
            />
          </div>

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              if (action === actions.create) {
                add_banking_details()
              } else if (action === actions.update) {
                update_banking_details()
              }
            }}
            color='success'
            sx={{ m: 1 }}
          >
            {action === actions.create ? "ADD PAYMENT DETAILS" : ""}
            {action === actions.update ? "UPDATE PAYMENT DETAILS" : ""}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default function Banking() {
  return (
    <div> 
    <Sidebar/>
    <Box
component="main"
sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` },ml:{sm:`${drawerWidth}px`} }}
>
<Toolbar />

<div>
    <Card sx={{ boxShadow: 4, mt: 2 }}>
      <CardContent>
        <PaymentModeModal />
        <IncomeExpenseModal />
        <BankingPage />
      </CardContent>
    </Card>
    </div>
    </Box>
    </div>
  )
}
