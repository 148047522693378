import React,{useEffect,useState} from 'react'
import { useHistory } from "react-router-dom";
const drawerWidth = 280;
export default function Protected(props) {
    
    var Comp = props.cmp;
    let history = useHistory(); 
    var userid = JSON.parse(localStorage.getItem('userid'));
    var token = JSON.parse(localStorage.getItem('token'));
    // var username = JSON.parse(localStorage.getItem('username'));
    const [session, setSession] = useState('')
    let url = 'https://jeetlabs.in/Api/validate_session?id='+userid+'&token='+token;

    // console.log(url);
    
    if(userid && token){
        var items = {userid,token}
       fetch(url).then((response)=>{
                response.json().then((result)=>{
                    setSession(result)
              })
            }).catch(error =>{
              console.warn(error);
               
            })
          
            

   }else{

    // console.log('r of unfound')
       history.push('/');

   }


    return (
        <div>
            {/* {url} */}
            {/* {userid} */}
            {/* {token} */}
            {/* {session} */}
            {/* <Comp /> */}
            {
                session == "valid"?
                <Comp/>
                
                :session == "invalid"?
                history.push('/')
                :
                <Comp/>
               
                
               
            }
            
        </div>
    )
}
