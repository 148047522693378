import React,{useState,useEffect} from 'react'
import Sidebar from './parts/Sidebar'
import {Toolbar,Box,Grid,TextField, Button,Snackbar,Alert} from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';

const drawerWidth = 280;


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    elevation:'0',
    color: theme.palette.text.secondary,
  }));

  

export default function Profile() {

  const [role,setRole] =useState('Role')
  const [name, setName] = useState('Name')
  const [email, setEmail] = useState('Email')
  const [mobile, setMobile] = useState('Mobile')
  const [address, setAddress] = useState('Address')
  const [password, setPassword] = useState('Password')


  const [resultmsg, setResultmsg] = useState('')
  const [resulttype, setResulttype] = useState('')


  var userid = JSON.parse(localStorage.getItem('userid'));
  var token = JSON.parse(localStorage.getItem('token'));

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const hidesnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function profile_data(){

    var url = 'https://jeetlabs.in/Api/profile_data'
    var items = {userid,token}
      
       let result = fetch(url,{
         method:'POST',
         headers:{
           "Content-Type":"application/json",
           "Accept":"application/json"
         },
         body:JSON.stringify(items)
         
       }).then((response)=>{
         response.json().then((result)=>{
          //  console.log(result);
           setRole(result.role)
           setName(result.name)
           setEmail(result.email)
           setMobile(result.mobile)
           setAddress(result.address)
           setPassword(result.password)
          
         })
       }).catch(error =>{
         console.warn(error);
       })
       

  
   }


  async function update_profile(){

    var url = 'https://jeetlabs.in/Api/update_profile'
    var items = {name,email,mobile,address,password,userid,token}

       if(name.length < 1 || email.length < 1 || mobile.length < 1 || address.length < 1 || password.length < 1  ){
         setResultmsg(' All fields Required')
         setResulttype('error')
        //  console.log(resulttype,resultmsg)
       }else{
         setResultmsg('Update Profile in Progress')
         setResulttype('info')

           
       let result = fetch(url,{
         method:'POST',
         headers:{
           "Content-Type":"application/json",
           "Accept":"application/json"
         },
         body:JSON.stringify(items)
         
       }).then((response)=>{
         response.json().then((result)=>{
          //  console.log(result);
      
           setResultmsg(result.msg)
           setResulttype(result.type)
           setOpen(true)
          //  profile_data();
            
         })
       }).catch(error =>{
         console.warn(error);
       })
       }

  
   }
  
  let history = useHistory(); 

  useEffect(() => {
    profile_data();
  },[])
  
    return (
        <div> 
            <Sidebar/>
            <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` },ml:{sm:`${drawerWidth}px`} }}
      >
        <Toolbar />

        <div>
        <Grid container spacing={2} sx={{mb:2,justifyContent:'center'}}>
        <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  label="Name"
                  autoFocus
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  disabled
                  label="Role"
                  value={role}
                />
              </Grid>

              </Grid>
              <Grid container spacing={2} sx={{mb:2,justifyContent:'center'}}>

              <Grid item xs={12} sm={4} >
                <TextField
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  required
                  fullWidth
                  label="Email"
                  
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  id="password"
                  label="Password"
                  value={password}
                  onChange={(e)=>setPassword(e.target.value)}
                />
              </Grid>
              </Grid>

              <Grid container spacing={2} sx={{mb:2,justifyContent:'center'}}>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  id="address"
                  label="Address"
                  value={address}
                  onChange={(e)=>setAddress(e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={4}>
                <TextField
                  value={mobile}
                  onChange={(e)=>setMobile(e.target.value)}
                  required
                  type="number"
                  fullWidth
                  id="mobile"
                  label="Mobile No."
                  autoFocus
                  sx={{mb:3}}
                />
                <Button variant="contained" onClick={()=>update_profile()} className="modified_button" color="info" fullWidth>Update</Button>
              </Grid>
             
              </Grid>

         
      <Snackbar open={open} autoHideDuration={6000} onClose={hidesnack} anchorOrigin={{horizontal:'right',vertical:'bottom'}}>
        <Alert onClose={hidesnack} severity={resulttype} sx={{ width: '100%' }}>
          {resultmsg}
        </Alert>
      </Snackbar>


        </div>





      </Box>



        </div>
    )
}
