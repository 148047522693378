import React, { useState, useEffect } from "react"
import Sidebar from "./parts/Sidebar"
import {
  Button,
  Select,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Chip,
  Snackbar,
  Alert,
  Toolbar,
  Tooltip,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Divider,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import { makeStyles } from "@mui/styles"
import { Link, useHistory } from "react-router-dom"
import CreateIcon from "@mui/icons-material/Create"
import DeleteIcon from "@mui/icons-material/Delete"
import { Modal, Row, Col } from "react-bootstrap"
import SettingsIcon from "@mui/icons-material/Settings"
import { useInput } from "@mui/core"
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DateRangePicker from "@mui/lab/DateRangePicker"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
{
  /* <Button sx={{p:2}} endIcon={<ArrowDownwardIcon/>}>Export</Button> */
}

const StyledInputElement = styled("input")`
  width: 200px;
  font-size: 1rem;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  background: #fff;
  border: 1.5px solid #606060;
  border-radius: 10px;
  padding: 6px 10px;
  color: #20262d;
  transition: width 300ms ease;

  &:hover {
    background: #fff;
    border-color: #606060;
  }

  &:focus {
    outline: none;
    width: 220px;
    transition: width 200ms ease-out;
  }
`

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  const { getRootProps, getInputProps } = useInput(props, ref)

  return (
    <div {...getRootProps()}>
      <StyledInputElement {...props} {...getInputProps()} />
    </div>
  )
})

const useStyles = makeStyles({})

const drawerWidth = 280

export default function Invoices() {
  const [show, setShow] = useState(false)

  const [resultmsg, setResultmsg] = useState("")
  const [resulttype, setResulttype] = useState("")

  const [name, setName] = useState("")
  const [mobile, setMobile] = useState("")
  const [address, setAddress] = useState("")
  const [additional, setAdditional] = useState("")
  const [email, setEmail] = useState("")

  const [invoice_list, setInvoice_list] = useState([])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [open, setOpen] = useState(false)
  const [value, setValue] = React.useState([null, null])

  const [payment_status, setPayment_status] = useState("All")
  const [report_status, setReport_status] = useState("All")

  const resetFilter = () => {
    setValue([null, null])
    setPayment_status("All")
    setReport_status("All")
  }

  const hidesnack = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function handleShow() {
    setShow(true)
  }

  function handleClose() {
    setName("")
    setMobile("")
    setAddress("")
    setAdditional("")
    setResultmsg("")
    setResulttype("")
    setShow(false)
  }

  function Reports_list() {
    var url = "https://jeetlabs.in/Api/get_reports"

    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          // console.log(result)
          setInvoice_list(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }
  function search_Reports_list(value) {
    var url = "https://jeetlabs.in/Api/search_invoices"
    var items = { data: value }

    let result = fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(items),
    })
      .then(response => {
        response.json().then(result => {
          console.log(result)
          if (result.type === "error" && result.msg === "No Data Found") {
            setInvoice_list([])

            setResultmsg(result.msg)
            setResulttype(result.type)
          } else {
            setInvoice_list(result)
          }
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  var userid = JSON.parse(localStorage.getItem("userid"))
  var token = JSON.parse(localStorage.getItem("token"))

  function delete_data(branch_id) {
    var url = "https://jeetlabs.in/Api/delete_invoice"
    var confirm = window.confirm("Confirm Delete")

    var items = { userid, token, branch_id }

    if (confirm) {
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            // console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            setOpen(true)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    } else {
      // console.log(branch_id+ ' is safe')
    }
  }

  function filter() {
    var url = "https://jeetlabs.in/Api/filter_invoices"
    var start_date = value[0]
    var end_date = value[1]
    var items = { payment_status, report_status, start_date, end_date }

    if (start_date == null || end_date == null) {
      setResulttype("error")
      setResultmsg("Start date & End Date is Mandatory")
      setOpen(true)
    } else {
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            console.log(result)
            if (result.type === "error" && result.msg === "No Data Found") {
              setInvoice_list([])

              setResultmsg(result.msg)
              setResulttype(result.type)
            } else {
              setInvoice_list(result)
            }

            setOpen(true)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  useEffect(() => {
    Reports_list()
  }, [])

  let history = useHistory()

  return (
    <div>
      <Sidebar />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar />

        <div>
          {/* <Divider /> */}
          <Card>
            <div sx={{ p: 3, m: 1 }} className='filterdiv'>
              <div style={{ margin: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateRangePicker
                    startText='Start Date'
                    endText='End Date'
                    value={value}
                    onChange={newValue => {
                      setValue(newValue)
                    }}
                    renderInput={(startProps, endProps) => (
                      <React.Fragment>
                        <TextField size='small' {...startProps} />
                        <Box sx={{ mx: 2 }}> to </Box>
                        <TextField size='small' {...endProps} />
                      </React.Fragment>
                    )}
                  />
                </LocalizationProvider>
              </div>

              <FormControl style={{ margin: "10px" }}>
                <InputLabel id='demo-simple-select-label'>
                  Payment Status
                </InputLabel>
                <Select
                  sx={{ minWidth: "150px" }}
                  size='small'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Payment Status'
                  value={payment_status}
                  onChange={e => setPayment_status(e.target.value)}
                >
                  <MenuItem value='All'>All</MenuItem>
                  <MenuItem value='Partialy Paid'>Partialy Paid</MenuItem>
                  <MenuItem value='Not Paid'>Not Paid</MenuItem>
                  <MenuItem value='Paid'>Paid</MenuItem>
                </Select>
              </FormControl>

              <FormControl style={{ margin: "10px" }}>
                <InputLabel id='demo-simple-select-label'>
                  Report Status
                </InputLabel>
                <Select
                  sx={{ minWidth: "150px" }}
                  size='small'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Report Status'
                  value={report_status}
                  onChange={e => setReport_status(e.target.value)}
                >
                  <MenuItem value='All'>All</MenuItem>
                  <MenuItem value='Sample Collected'>Sample Collected</MenuItem>
                  <MenuItem value='Result Added'>Result Added</MenuItem>
                  <MenuItem value='Report Genrated & Sent'>
                    Report Genrated & Sent
                  </MenuItem>
                </Select>
              </FormControl>

              <div style={{ margin: "10px" }}>
                <Button
                  variant='contained'
                  onClick={() => filter()}
                  color='primary'
                  className='modified_button'
                  endIcon={<FilterAltIcon />}
                >
                  Filter{" "}
                </Button>
              </div>
              <div style={{ margin: "10px" }}>
                <Button
                  variant='contained'
                  onClick={() => {
                    Reports_list()
                    resetFilter()
                  }}
                  color='primary'
                  className='modified_button'
                  endIcon={<RestartAltIcon />}
                >
                  Reset{" "}
                </Button>
              </div>
            </div>
          </Card>

          {/* <Divider /> */}

          <div
            sx={{ p: 3, m: 1 }}
            style={{
              marginTop: 20,
              marginBottom: 20,
              padding: 10,
              borderRadius: "12px",
              display: "flex",
              justifyContent: "space-between",
              border: "0.5px  lightgrey",
            }}
          >
            <CustomInput
              aria-label='Search'
              onChange={e => {
                search_Reports_list(e.target.value)
              }}
              placeholder='Search something...'
            />
            <Button
              variant='contained'
              color='primary'
              onClick={() => history.push("create/0")}
              className='modified_button'
            >
              + Create{" "}
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Button sx={{ p: 2 }} endIcon={<ArrowDownwardIcon />}>
              Export
            </Button>
            <Table sx={{ minWidth: "550px" }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "70%" }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "70%" }}
                  >
                    Name
                  </TableCell>
                  <Tooltip title={"Report Genration Date "}>
                    <TableCell
                      align='center'
                      sx={{ fontWeight: 600, fontSize: "70%" }}
                    >
                      R. Genration Date
                    </TableCell>
                  </Tooltip>
                  <Tooltip title={"Sample Collection Place "}>
                    <TableCell
                      align='center'
                      sx={{ fontWeight: 600, fontSize: "70%" }}
                    >
                      {" "}
                      S.C Place
                    </TableCell>
                  </Tooltip>
                  <Tooltip title={"Sample Collection Date"}>
                    <TableCell
                      align='center'
                      sx={{ fontWeight: 600, fontSize: "70%" }}
                    >
                      S. C. Date
                    </TableCell>
                  </Tooltip>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "70%" }}
                  >
                    Created by
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "70%" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "70%" }}
                  >
                    Payment
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "70%" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice_list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(invoice => (
                    <TableRow>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        <Tooltip title='view Report'>
                          <Button variant='outlined' color='secondary'>
                            {invoice.report_id}
                          </Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {invoice.cs_name}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {" "}
                        {invoice.rpt_genration_date}{" "}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {invoice.sample_collection_place}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {invoice.sample_collection_date}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {" "}
                        {invoice.name}{" "}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {invoice.rpt_status == "Sample Collected" ? (
                          <Chip
                            color='secondary'
                            label='Sample Collected'
                          ></Chip>
                        ) : invoice.rpt_status == "Report Genrated" ? (
                          <Chip color='warning' label='Report Genrated'></Chip>
                        ) : (
                          <Chip color='warning' label='Report Genrated'></Chip>
                        )}
                      </TableCell>

                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {invoice.rpt_status == "Sample Collected" ? (
                          <Chip color='error' label='Not Billed'></Chip>
                        ) : invoice.rpt_status == "Report Genrated" ? (
                          <Chip color='error' label='Not Billed'></Chip>
                        ) : (
                          <Chip color='error' label={invoice.rpt_status}></Chip>
                        )}
                      </TableCell>

                      <TableCell align='center'>
                        <Tooltip title='Create Report'>
                          <IconButton
                            aria-label='Edit'
                            onClick={() =>
                              history.push(
                                "/report_results/" + invoice.report_id
                              )
                            }
                            color='info'
                          >
                            <CreateIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title='Download'>
                          <IconButton aria-label='Edit' color='success'>
                            <DownloadForOfflineIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title='Delete'>
                          <IconButton
                            aria-label='Delete'
                            color='error'
                            onClick={() => delete_data(invoice.cs_id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                {invoice_list.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align='center'>
                      <img
                        src='https://jeetlabs.in/Api/no_data.png'
                        srcSet='https://jeetlabs.in/Api/no_data.png'
                        alt={"no data found"}
                        style={{ maxHeight: "250px", width: "auto" }}
                        loading='lazy'
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component='div'
              count={invoice_list.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={hidesnack}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert onClose={hidesnack} severity={resulttype} sx={{ width: "100%" }}>
          {resultmsg}
        </Alert>
      </Snackbar>
    </div>
  )
}
