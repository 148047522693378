import React, { useState, useEffect } from "react"
import Sidebar from "./parts/Sidebar"
import {
  Button,
  Toolbar,
  Box,
  Table,
  TableBody,
  Alert,
  TableCell,
  Collapse,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Tooltip,
  TextField,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import { useHistory } from "react-router-dom"
import CreateIcon from "@mui/icons-material/Create"
import DeleteIcon from "@mui/icons-material/Delete"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { Row, Col, Modal } from "react-bootstrap"

const drawerWidth = 280

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  elevation: "0",
  color: theme.palette.text.secondary,
}))

const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

function Data_rows(props) {
  var initialState = ""

  const { row } = props
  const [open, setOpen] = React.useState(false)

  // console.log(row.cluster_data)

  var cluster_data = row.cluster_data

  var userid = JSON.parse(localStorage.getItem("userid"))
  var token = JSON.parse(localStorage.getItem("token"))

  function delete_data(branch_id) {
    var url = "https://jeetlabs.in/Api/delete_cluster"
    var confirm = window.confirm("Confirm Delete")

    var items = { userid, token, branch_id }

    if (confirm) {
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            // console.log(result);
            // setResultmsg(result.msg)
            // setResulttype(result.type)
            // age_group_list();
          })
        })
        .catch(error => {
          console.warn(error)
        })
    } else {
      // console.log(branch_id+ ' is safe')
    }
  }

  // models states
  const [cluster_edit_model, setcluster_edit_model] = useState(false)
  const [cluster_data_edit_model, setcluster_data_edit_model] = useState(false)

  // edit cluster data states
  const [cluster_id, setCluster_id] = useState(initialState)
  const [cluster_new_name, setCluster_new_name] = useState(initialState)
  const [cluster_new_unit, setCluster_new_unit] = useState(initialState)

  // edit cluster data states
  const [cluster_data_id, setCluster_data_id] = useState(initialState)
  const [cluster_age_group, setcluster_age_group] = useState(initialState)
  const [cluster_gender, setCluster_gender] = useState(initialState)
  const [cluster_min, setCluster_min] = useState(initialState)
  const [cluster_max, setCluster_max] = useState(initialState)

  const [resultmsg, setResultmsg] = useState("")
  const [resulttype, setResulttype] = useState("")

  function show_cluster_edit_model(e) {
    var data = e.target.dataset
    if (data.id == null) {
      alert("Click Again")
    } else {
      setCluster_id(data.id)
      setCluster_new_name(data.name)
      setCluster_new_unit(data.unit)
      setcluster_edit_model(true)
    }
  }

  function hide_cluster_edit_model() {
    setCluster_id(null)
    setCluster_new_name(initialState)
    setCluster_new_unit(initialState)
    setcluster_edit_model(false)
  }

  function show_cluster_data_edit_model(event) {
    var data = event.target.dataset
    setCluster_data_id(data.id)
    setcluster_data_edit_model(true)
  }

  function hide_cluster_data_edit_model() {
    setCluster_data_id(initialState)
    setcluster_age_group(initialState)
    setCluster_gender(initialState)
    setCluster_min(initialState)
    setCluster_max(initialState)
    setcluster_data_edit_model(false)
  }

  function update_cluster_name_unit() {
    var url = "https://jeetlabs.in/Api/update_cluster_name_unit"
    var items = {
      cl_id: cluster_id,
      cl_name: cluster_new_name,
      cl_unit: cluster_new_unit,
    }

    if (
      cluster_id.length < 1 ||
      cluster_new_name.length < 1 ||
      cluster_new_unit.length < 1
    ) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Cluster Update in Progress")
      setResulttype("info")

      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            // clusters_data();
            setTimeout(function () {
              hide_cluster_edit_model()
              //   history.push('/Myorder')
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  function update_cluster_data() {
    var url = "https://jeetlabs.in/Api/update_cluster_data"
    var items = {
      cluster_data_id: cluster_data_id,
      Age_group_id: cluster_age_group,
      gender: cluster_gender,
      min: cluster_min,
      max: cluster_max,
    }

    if (
      cluster_data_id.length < 1 ||
      cluster_age_group.length < 1 ||
      cluster_gender.length < 1 ||
      cluster_min.length < 1 ||
      cluster_max.length < 1
    ) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Cluster Data Update in Progress")
      setResulttype("info")

      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            // clusters_data();
            setTimeout(function () {
              hide_cluster_edit_model()
              //   history.push('/Myorder')
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  function delete_cluster() {}

  function delete_cluster_data() {}

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align='center'>{row.main.cl_name}</TableCell>
        <TableCell align='center'>{row.main.cl_unit}</TableCell>
        <TableCell align='center'>
          <IconButton aria-label='Edit' color='success'>
            <CreateIcon
              data-id={row.main.cl_id}
              data-name={row.main.cl_name}
              data-unit={row.main.cl_unit}
              onClick={e => show_cluster_edit_model(e)}
            />
          </IconButton>
          <IconButton
            aria-label='Delete'
            color='secondary'
            onClick={() => delete_data(row.main.cl_id)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                  Data
                </Typography> */}
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Age Group</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell align=''>Min</TableCell>
                    <TableCell align=''>Max</TableCell>
                    <TableCell align=''>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cluster_data.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell component='th' scope='row'>
                        {data.min_age_num +
                          " " +
                          data.min_age_unit +
                          " - " +
                          data.max_age_num +
                          " " +
                          data.max_age_unit}
                      </TableCell>
                      <TableCell>{data.cl_data_gender}</TableCell>
                      <TableCell>{data.cl_data_min}</TableCell>
                      <TableCell>{data.cl_data_max}</TableCell>
                      <TableCell align=''>
                        <Tooltip title='edit'>
                          <IconButton aria-label='Edit' color='success'>
                            <CreateIcon
                              data-id={data.cl_data_id}
                              onClick={e => show_cluster_data_edit_model(e)}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Delete'>
                          <IconButton aria-label='Delete' color='secondary'>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Modal
        show={cluster_edit_model}
        backdrop='static'
        centered
        style={{ background: "#808080db", zIndex: "10000" }}
        size='lg'
        onHide={() => hide_cluster_edit_model()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>Edit Cluster Data</span>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Cluster New Name'
                value={cluster_new_name}
                sx={{ mb: 2 }}
                onChange={e => setCluster_new_name(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Cluster New Unit'
                value={cluster_new_unit}
                sx={{ mb: 2 }}
                onChange={e => setCluster_new_unit(e.target.value)}
              />
            </Col>
          </Row>

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => hide_cluster_edit_model()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => update_cluster_name_unit()}
            color='success'
            sx={{ m: 1 }}
          >
            Update Cluster Data
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cluster_data_edit_model}
        backdrop='static'
        centered
        style={{ background: "#808080db", zIndex: "10000" }}
        size='lg'
        onHide={() => hide_cluster_data_edit_model()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>hide_cluster_data_edit_model</span>
        </Modal.Header>

        <Modal.Body></Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default function Clusters() {
  var initialState = ""

  let history = useHistory()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const [clusters_list, setClusters_list] = useState([])

  function clusters_data() {
    var url = "https://jeetlabs.in/Api/clusters_data"

    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          // console.log(result)
          setClusters_list(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  useEffect(() => {
    clusters_data()
  }, [])

  return (
    <div>
      <Sidebar />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar />

        <div>
          <div
            sx={{ p: 3, m: 1 }}
            style={{
              marginTop: 20,
              marginBottom: 20,
              padding: 10,
              borderRadius: "12px",
              display: "flex",
              justifyContent: "space-between",
              border: "0.5px  lightgrey",
            }}
          >
            <Typography variant='h6' sx={{ fontWeight: "medium" }}>
              Clusters
            </Typography>

            <Button
              variant='contained'
              className='modified_button'
              color='primary'
              onClick={() => history.push("/create_cluster")}
            >
              + Create{" "}
            </Button>
          </div>

          <TableContainer component={Paper}>
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Unit
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clusters_list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return <Data_rows key={index} row={row} />
                  })}

                {clusters_list.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align='center'>
                      No Data Found .....
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {/* {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))} */}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={clusters_list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Box>
    </div>
  )
}
