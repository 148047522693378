import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Form, InputGroup, TabContainer } from "react-bootstrap";
import Sidebar from "./parts/Sidebar";
import { Button, Toolbar, Box, Card, Grid, Divider, Paper, Table, Alert, Checkbox, OutlinedInput, TextField, TableRow, TableCell, } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WcIcon from '@mui/icons-material/Wc';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import BoltIcon from '@mui/icons-material/Bolt';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';

const drawerWidth = 280;

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  elevation: "2",
  color: theme.palette.text.secondary,
}));




export default function Report_results() {
  let { token } = useParams();
  const [clusters_box, setClusters_box] = useState([]);
  const [report_data, setReport_data] = useState("");

  const { register, handleSubmit } = useForm();

  function total(price,sgst,cgst){
    var price = Number(price);
    var total_sgst = price/100*Number(sgst);
    var total_cgst = price/100*Number(cgst);
    
    var total =  price + total_cgst + total_sgst ;
    // return Math.round(price , 2)
    return total.toFixed(2)
  }
  
  const onSubmit = (data) => {
    var url = 'https://jeetlabs.in/Api/add_report_result'
    var items = {data}

       let result = fetch(url,{
         method:'POST',
         headers:{
           "Content-Type":"application/json",
           "Accept":"application/json"
         },
         body:JSON.stringify(items)
         
       }).then((response)=>{
         response.json().then((result)=>{
          //  console.log(result);
          //  setResultmsg(result.msg)
          //  setResulttype(result.type)
           
        //    partners_list();
        //      setTimeout(function() {
        //        handleClose()
        //  }, 1200);
         })
       }).catch(error =>{
         console.warn(error);
       })
       
  }

  // const onSubmit = (data) => console.log(data)

  function get_tests_data() {
    // var url = "https://jeetlabs.in/Api/get_tests_data";
    var url = 'https://jeetlabs.in/Api/get_report_gen_data'


    let result = fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        token,
      }),
    })
      .then((response) => {
        response.json().then((result) => {
          console.log(result)
          setClusters_box(result.inputs);
          setReport_data(result.report_data);
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  useEffect(() => {
    get_tests_data();
  }, []);

  return (
    <div>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)`, }, ml: { sm: `${drawerWidth}px`, }, }} >
        <Toolbar />

        

        <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control  {...register(`report_id`)} value={token} hidden />
        <Card sx={{ p: 1, borderRadius: "10px", minHeight: "200px", }} elevation={3} >
          <Row>
            <Col xs={12} md={6} style={{ backgroundColor: "", minHeight: "200px", }} >
              <Row>
                <Col xs={6} md={6}>
                  <Form.Group controlId="validationCustomUsername">
                    <Form.Label style={{ fontWeight: "bold", }} > Name </Form.Label>

                    <InputGroup className="mb-3">
                      <Form.Control placeholder="Name"  defaultValue={report_data.cs_name} readOnly/>
                      <InputGroup.Append>
                        <InputGroup.Text>
                          
                          <AccountCircleIcon />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={6} md={6}>
                  <Form.Label style={{ fontWeight: "bold", }} > Gender </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control placeholder="Gender" defaultValue={report_data.rpt_pt_gender} readOnly/>
                    <InputGroup.Append>
                      <InputGroup.Text>
                      {
                      report_data.rpt_pt_gender == 'Male'? 
                      <MaleIcon />
                      :report_data.rpt_pt_gender == 'Female'? 
                      <FemaleIcon />
                      :<WcIcon />
                      }
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6}>
                  <Form.Label style={{fontWeight: "bold",}}> Age </Form.Label>
                  <InputGroup className="mb-3">

                      <Form.Control placeholder="Age" defaultValue={report_data.rpt_pt_age} readOnly/>

                      <InputGroup.Append>
                        <InputGroup.Text> <BoltIcon /> </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                </Col>

                <Col xs={6} md={6}>
                  <Form.Label style={{fontWeight: "bold",}}> Age Group </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control placeholder="Age Group" value={report_data.min_age_num +' '+ report_data.min_age_unit +' - '+ report_data.max_age_num +' '+ report_data.max_age_unit } readOnly/>
                    <InputGroup.Append>
                      <InputGroup.Text> <BoltIcon/> </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>

              </Row>
              {/* <br /> */}
              {/* <Divider />
              <br /> */}

              {/* <div style={{ display: "flex", justifyContent: "", alignItems: "", flexDirection: "column", }} >
                <div> <span style={{ fontWeight: "bold", }} > Code#: </span> {report_data.cs_id} <br /> </div>
                <div> <span style={{ fontWeight: "bold", }} >  Name: </span> {report_data.cs_name}  <br /> </div>
                <div> <span style={{ fontWeight: "bold", }} > Mobile: </span> {report_data.cs_mobile_no}  <br /> </div>
                <div> <span style={{ fontWeight: "bold", }} > E - mail: </span> {report_data.cs_email}  <br /> </div>
                <div> <span style={{ fontWeight: "bold", }} > Address: </span> {report_data.cs_address}  <br /> </div>
              </div> */}
              {/* <br /> */}
              {/* <Divider /> */}
            </Col>
            <Col xs={12} md={6} style={{ backgroundColor: "", }} >
              <Row>
                <Col>
                  <Form.Label style={{ fontWeight: "bold", }} > Sample Collection Date </Form.Label>
                  <InputGroup>
                  <Form.Control placeholder="Sample Collection Date"  defaultValue={report_data.sample_collection_date} readOnly/>
                    <InputGroup.Append> 
                    <InputGroup.Text>
                       <DateRangeIcon />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>

                <Col>
                  <Form.Label style={{ fontWeight: "bold", }} > Report Genration Date </Form.Label>
                  <InputGroup>
                  <Form.Control placeholder="Report Genration Date" defaultValue={report_data.rpt_genration_date} readOnly/>
                    <InputGroup.Append>
                      <InputGroup.Text>
                        
                        <DateRangeIcon   />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                 </Col>
              </Row>
              <br />
              <Row>
              <Col>
                  <Form.Label style={{ fontWeight: "bold", }} > Sample Collection Place </Form.Label>
                  <InputGroup>
                  <Form.Control placeholder="Sample Collection Place" defaultValue={report_data.sample_collection_place} readOnly/>
                    <InputGroup.Append> <InputGroup.Text> <MapIcon /> </InputGroup.Text> </InputGroup.Append>
                  </InputGroup>
                </Col>

                
               
              </Row>
              
            </Col>
          </Row>

          <Divider />
          <br />
          <TabContainer>

              <Table>
              {clusters_box.map((clusters , index)=>(
                  <>
                  <TableRow>
                  <TableCell align="center" style={{border:0}}  >
                        <span style={{fontWeight:'bolder',textTransform:'capitalize',fontSize:"small",color:'red'}}>{clusters.test.test_name}</span>
                      </TableCell>

                      <TableCell align="center" style={{border:0}} >
                        <span style={{fontWeight:'bolder',textTransform:'capitalize',fontSize:"small",color:'red'}}>Sample Type : {clusters.test.sample_type}</span>
                      </TableCell>

                      <TableCell align="center" style={{border:0}} >
                        <span style={{fontWeight:'bolder',textTransform:'capitalize',fontSize:"small",color:'red'}}> Price : {clusters.test.test_price} INR</span>
                      </TableCell>

                      <TableCell align="center" style={{border:0}}>
                        <span style={{fontWeight:'bolder',textTransform:'capitalize',fontSize:"small",color:'red'}}>C GST : {clusters.test.c_gst} % - S GST {clusters.test.s_gst} % </span>
                      </TableCell>

                      <TableCell align="center" style={{border:0}}>
                        <span style={{fontWeight:'bolder',textTransform:'capitalize',fontSize:"small",color:'red'}}>Total : {total(clusters.test.test_price,clusters.test.s_gst,clusters.test.c_gst)} </span>
                      </TableCell>

                      </TableRow>

                    <TableRow sx={{m:0,p:0}} >
                        <TableCell align="center" as="head" style={{fontWeight:'bold',color:'darkcyan',margin:0, fontSize:'small'}}>Cluster Name </TableCell>
                        <TableCell align="center" as="head" style={{fontWeight:'bold',color:'darkcyan',margin:0, fontSize:'small'}}>Normal Range  </TableCell>
                        <TableCell align="center" as="head" style={{fontWeight:'bold',color:'darkcyan',margin:0, fontSize:'small'}}>Cluster Result </TableCell>
                        <TableCell align="center" as="head" style={{fontWeight:'bold',color:'darkcyan',margin:0, fontSize:'small'}}>Cluster Comment </TableCell>
                        <TableCell align="center" as="head" style={{fontWeight:'bold',color:'darkcyan',margin:0, fontSize:'small'}}>Preloaded Comment </TableCell>
                    </TableRow>
                      
                        {
                            clusters.clusters.map((inputs , key)=>(
                              <>
                                <TableRow>
                                    
                                    <TableCell align="center" style={{fontWeight:600,color:'',textTransform:'capitalize',fontSize:'small'}}>
                                        {inputs.cl_name}    
                                    </TableCell>





                                    <TableCell align="center" style={{fontWeight:600,color:'',textTransform:'capitalize',fontSize:'small'}}>
                                        {inputs.cl_data_min} {inputs.cl_unit} -  {inputs.cl_data_max} {inputs.cl_unit}
                                    </TableCell>


                                    {/* <TableCell align="center" style={{fontWeight:600,color:'',textTransform:'capitalize'}}>
                                        {inputs.cl_data_max} {inputs.cl_unit}
                                    </TableCell> */}


                                    <TableCell align="center">
                                        <InputGroup>
                                            <Form.Control placeholder="Result" {...register(`data.${index}.${key}.testid`)} value={clusters.test.test_id} hidden />
                                            <Form.Control placeholder="Result" {...register(`data.${index}.${key}.clusterid`)} value={inputs.cl_data_id} hidden/>
                                            <Form.Control placeholder="Result" {...register(`data.${index}.${key}.result`)}  style={{width:'50%'}} />
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                    <AssessmentIcon fontSize="small"/>
                                                </InputGroup.Text>
                                                </InputGroup.Append>
                                        </InputGroup>
                                    </TableCell>

                                    <TableCell align="center">
                                        <Form.Control as="textarea" rows={2} placeholder="Comment"  {...register(`data.${index}.${key}.cmnt`)}    />
                                    </TableCell>

                                    <TableCell align="center">

                                       
                                        <Form.Group controlId="formBasicCheckbox" >
                                        <Tooltip title={inputs.low_cmnt}>
                                            <Checkbox size="small" value={inputs.low_cmnt} {...register(`data.${index}.${key}.low_cmnt`)} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
                                        </Tooltip>
                                        <label  style={{color:'olive',fontWeight:'bold'}}>Low</label>
                                        {/* <Divider/> */}
                                     
                                     
                                        <Tooltip title={inputs.normal_cmnt}>
                                            <Checkbox size="small" color="warning" value={inputs.normal_cmnt} {...register(`data.${index}.${key}.normal_cmnt`)} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
                                        </Tooltip>
                                        <label  style={{color:'olive',fontWeight:'bold'}}>Normal</label>
                                        {/* <Divider /> */}
                                      

                                          <Tooltip title={inputs.high_cmnt}>
                                              <Checkbox size="small" color="secondary" value={inputs.high_cmnt} {...register(`data.${index}.${key}.high_cmnt`)} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
                                          </Tooltip>
                                          <label  style={{color:'olive',fontWeight:'bold'}}>High</label>
                                          {/* <Divider /> */}
                                        </Form.Group>
                                        

                                    </TableCell>

                                </TableRow>

                           

                                </>
                            ))
                        }
                     

                     </>
                      ))}
              </Table>
              </TabContainer>

          <br />
          <Divider />
          <br />

          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <Item elevation={0}>
                <Button size="large" type="submit" sx={{ minWidth: "250px", backgroundImage: "linear-gradient(to right, #0f2027, #203a43, #2c5364);", }} variant="contained" >
                   Save Results
                </Button>
              </Item>
            </Grid>
          </Grid>

          <br />
        </Card>
        </form>
                        <br/>

        <Card>
        {/* <form onSubmit={handleSubmit(onSubmit)}>
      {clusters_box.map((clusters, index) => (
        <>
          <span style={{ color: "white" }}>{clusters.test.test_name}</span>
          {clusters.clusters.map((inputs, key) => (
            <>
              <input
                {...register(`${index}.${key}.result`)}
                placeholder="result"
                // defaultValue={index + "," + key + "r"}
              />
              <input
                {...register(`${index}.${key}.cmnt`)}
                placeholder="cmnt"
                // defaultValue={index + "," + key + "c"}
              />
              <br />
            </>
          ))}
        </>
      ))}

      <input type="submit" />
    </form> */}
        </Card>

    
      </Box>
    </div>
  );
}











// import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
// import { useForm } from "react-hook-form";

// import "./styles.css";

// export default function App() {
//   const { register, handleSubmit } = useForm();
//   const onSubmit = (data) => console.log(data);

//   const [clusters_box, setClusters_box] = useState([]);
//   const [report_data, setReport_data] = useState("");

//   var token = 1;
//   function get_tests_data() {
//     var url = "https://jeetlabs.in/Api/get_tests_data";

//     let result = fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json"
//       },
//       body: JSON.stringify({
//         token
//       })
//     })
//       .then((response) => {
//         response.json().then((result) => {
//           // console.log(result)
//           setClusters_box(result.inputs);
//           setReport_data(result.report_data);
//         });
//       })
//       .catch((error) => {
//         console.warn(error);
//       });
//   }

//   useEffect(() => {
//     get_tests_data();
//   }, []);

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       {clusters_box.map((clusters, index) => (
//         <>
//           <span style={{ color: "white" }}>{clusters.test.test_name}</span>
//           {clusters.clusters.map((inputs, key) => (
//             <>
//               <input
//                 {...register(`${index}.${key}.result`)}
//                 placeholder="result"
//                 defaultValue={index + "," + key + "r"}
//               />
//               <input
//                 {...register(`${index}.${key}.cmnt`)}
//                 placeholder="cmnt"
//                 defaultValue={index + "," + key + "c"}
//               />
//               <br />
//             </>
//           ))}
//         </>
//       ))}

//       <input type="submit" />
//     </form>
//   );
// }

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
