import React,{useState,useEffect} from 'react'
import Sidebar from './parts/Sidebar'
import {Toolbar,Box,Button,Grid, Card, Avatar,Badge,ButtonGroup, Divider,TextField,TableContainer,Table,TableHead,TableBody ,TableRow,TableCell,Tooltip,Chip,TablePagination,IconButton} from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { makeStyles } from '@mui/styles';
import ColorizeIcon from '@mui/icons-material/Colorize';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

const drawerWidth = 280;


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    elevation:'0',
    color: theme.palette.text.secondary,
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 28,
    height: 28,
    border: `2px solid ${theme.palette.background.paper}`,
    padding:'5px'
  }));

  const useStyles = makeStyles({
    maincard:{
      display:'flex',
      justifyContent:'center',
      flexDirection:'column',
      alignItems:'center',
      padding:'20px',
      borderRadius:16,
    },
    maincard_title:{
      padding:20,
      fontSize:'large',
      fontWeight:'bold'
    },
    selected_button:{
      borderColor:'#fff!important',
      backgroundImage:'linear-gradient(to right, #085078, #085078)',
      color:"#fff!important",
      fontWeight:'bold'
    },
    stats_button:{
      borderColor:'#fff!important',
      backgroundImage:'linear-gradient( 95deg,rgb(241 73 97) 0%,rgb(241 73 97) 50%)',
      color:"#fff!important",
      fontWeight:'bold'
    }
  });

  

export default function Dashboard() {
  
  let history = useHistory(); 
  const classes = useStyles();
  const [selected, setSelected] = useState(0)
  const [value, setValue] = React.useState([null, null]);
  const [show, setShow] = useState(false);
  const [invoice_list, setInvoice_list] = useState([])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [calender_count, setCalender_count] = useState(1)

  const handleClose = () => {
    setSelected(0)
    setShow(false);
  }

  const handleShow = (e) => {
    setSelected(e.target.dataset.id)
    setShow(true);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function windows_width(){
    if(window.screen.width < 480){
      setCalender_count(1)
    }else{
      setCalender_count(2)
    }

  }


var userid = JSON.parse(localStorage.getItem('userid'));
var token = JSON.parse(localStorage.getItem('token'));

  function delete_data(branch_id){
    var url = 'https://jeetlabs.in/Api/delete_invoice'
   var confirm = window.confirm('Confirm Delete');
  
   var items = {userid,token,branch_id}
  
   if(confirm){
    let result = fetch(url,{
      method:'POST',
      headers:{
        "Content-Type":"application/json",
        "Accept":"application/json"
      },
      body:JSON.stringify(items)
      
    }).then((response)=>{
      response.json().then((result)=>{
        // console.log(result);
        // setResultmsg(result.msg)
        // setResulttype(result.type)
        
      
        // setOpen(true)
      })
    }).catch(error =>{
      console.warn(error);
    })
   }else{
  
    // console.log(branch_id+ ' is safe')
   }
  
  }
  


  function Reports_list(){

    var url = 'https://jeetlabs.in/Api/get_reports'
  
  let result = fetch(url,{
    method:'GET',
    headers:{
        "Content-Type":"application/json",
        "Accept":"application/json"
    }
  
  }).then((response)=>{
    response.json().then((result)=>{
      // console.log(result)
      setInvoice_list(result)
     
   
    })
  }).catch(error =>{
  console.warn(error);
  })
  
  }


  function get_stat(e){
    setSelected(e.target.dataset.id)
    // console.log(e.target.value)
  }


  
  useEffect(() => {
    Reports_list();
    windows_width();
  },[])

  
    return (
        <div> 
            <Sidebar/>
            <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` },ml:{sm:`${drawerWidth}px`} }}
      >
        <Toolbar />

       <Card></Card>

       <ButtonGroup variant="outlined" disableElevation sx={{padding:3 }} >
        <Button data-id="0" className={selected == 0?classes.selected_button:classes.stats_button} onClick={(e)=>get_stat(e)} value="1">Today</Button>
        <Button data-id="1" className={selected == 1?classes.selected_button:classes.stats_button} onClick={(e)=>get_stat(e)} value="7">Last 7 Days</Button>
        <Button data-id="2" className={selected == 2?classes.selected_button:classes.stats_button} onClick={(e)=>get_stat(e)} value="30">Last 30 days</Button>
        <Button data-id="3" className={selected == 3?classes.selected_button:classes.stats_button} onClick={(e)=>handleShow(e)} value="30">Custom</Button>
       
      </ButtonGroup>

      <Divider/>

      
     
        <Grid container spacing={2} sx={{pt:3,pb:3}}>


        <Grid item xs={12} md={3} elevation={0} >
            <Item elevation={0}>
              <Card className={classes.maincard} sx={{backgroundImage:'linear-gradient(95deg,rgb(242,113,33) 0%,rgb(241 73 97) 50%)',color:'#fff',}} > 
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <SmallAvatar sx={{ bgcolor: 'rgb(233,64,87)' }}>
                        <ColorizeIcon fontSize="small" />
                      </SmallAvatar>
                  }
                >
                  <Avatar sx={{ bgcolor: 'rgb(233,64,87)',width: 56 , height: 56,border: `2px solid white` }} >
                    <span className={classes.maincard_title} >67</span> 
                    </Avatar>
                </Badge>
                 <span className={classes.maincard_title}>Sample Collected</span>
              </Card>

            </Item>
          </Grid>  

          
        <Grid item xs={12} md={3} elevation={0} >
            <Item elevation={0}>
              <Card className={classes.maincard} sx={{backgroundImage:'linear-gradient(to right, #085078, #85d8ce)',color:'#fff',}} > 
              <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <SmallAvatar sx={{ bgcolor: '#085078' }}>
                        <AssignmentOutlinedIcon fontSize="small"/>
                      </SmallAvatar>
                  }
                >
                  <Avatar sx={{ bgcolor: '#085078',width: 56 , height: 56,border: `2px solid white` }} >
                  <span className={classes.maincard_title} >67</span> 
                    </Avatar>
                </Badge>   
                 <span className={classes.maincard_title}>Reports</span>
              </Card>

            </Item>
          </Grid>   

 
        <Grid item xs={12} md={3} elevation={0} >
            <Item elevation={0}>
              <Card className={classes.maincard } sx={{backgroundImage:'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(241 73 97) 50%)',color:'#fff',}} > 

              <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <SmallAvatar sx={{ bgcolor: 'rgb(233,64,87)' }}>
                        <ReceiptOutlinedIcon fontSize="small" />
                      </SmallAvatar>
                  }
                >
                  <Avatar sx={{ bgcolor: 'rgb(233,64,87)',width: 56 , height: 56,border: `2px solid white` }} >
                    <span className={classes.maincard_title} >67</span> 
                    </Avatar>
                </Badge>
                 <span className={classes.maincard_title}>Pending Billing</span>
              </Card>

            </Item>
          </Grid>

          <Grid item xs={12} md={3} elevation={0} >
            <Item elevation={0}>
              <Card className={classes.maincard} sx={{backgroundImage:'linear-gradient(to right, #085078, #85d8ce)',color:'#fff',}} > 
              <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <SmallAvatar sx={{ bgcolor: '#085078' }}>
                        <MonetizationOnIcon fontSize="small"/>
                      </SmallAvatar>
                  }
                >
                  <Avatar sx={{ bgcolor: '#085078',width: 56 , height: 56,border: `2px solid white` }} >
                  <span className={classes.maincard_title} >67</span> 
                    </Avatar>
                </Badge>   
                 <span className={classes.maincard_title}>Billing Amount</span>
              </Card>

            </Item>
          </Grid>   

       
        </Grid>


      <Divider/>



     
      <TableContainer component={Paper} sx={{pt:3}}>
              <Table sx={{minWidth:'550px'}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell  align="center" sx={{fontWeight:600,fontSize:'70%'}} >#</TableCell>
                    <TableCell  align="center" sx={{fontWeight:600,fontSize:'70%'}} >Name</TableCell>
                  <Tooltip title={"Report Genration Date "}>
                    <TableCell  align="center" sx={{fontWeight:600,fontSize:'70%'}} >
                          R. Genration Date
                      </TableCell>
                  </Tooltip>
                  <Tooltip title={"Sample Collection Place "}>
                    <TableCell  align="center" sx={{fontWeight:600,fontSize:'70%'}} > S.C Place</TableCell>
                  </Tooltip>
                  <Tooltip title={"Sample Collection Date"}>
                    <TableCell  align="center" sx={{fontWeight:600,fontSize:'70%'}} >S. C. Date</TableCell>
                  </Tooltip>
                    <TableCell  align="center" sx={{fontWeight:600,fontSize:'70%'}} >Created by</TableCell>
                    <TableCell  align="center" sx={{fontWeight:600,fontSize:'70%'}} >Status</TableCell>
                    <TableCell  align="center" sx={{fontWeight:600,fontSize:'70%'}} >Payment</TableCell>
                    <TableCell  align="center" sx={{fontWeight:600,fontSize:'70%'}} >Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                  invoice_list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((invoice)=>(
                  <TableRow>
                    
                                      <TableCell align="center" sx={{fontWeight:500,textTransform:'capitalize'}} >
                                      <Tooltip title="view Report">
                                        <Button variant="outlined" color="secondary">{invoice.report_id}</Button>
                                      </Tooltip>
                                        </TableCell>
                                      <TableCell align="center" sx={{fontWeight:500,textTransform:'capitalize'}} >{invoice.cs_name}</TableCell>
                                      <TableCell align="center" sx={{fontWeight:500,textTransform:'capitalize'}} > {invoice.rpt_genration_date} </TableCell>
                                      <TableCell align="center" sx={{fontWeight:500,textTransform:'capitalize'}} >{invoice.sample_collection_place}</TableCell>
                                      <TableCell align="center" sx={{fontWeight:500,textTransform:'capitalize'}} >{invoice.sample_collection_date}</TableCell>
                                      <TableCell align="center" sx={{fontWeight:500,textTransform:'capitalize'}} > {invoice.name} </TableCell>
                                      <TableCell align="center" sx={{fontWeight:500,textTransform:'capitalize'}} >
                                         {invoice.rpt_status == 'Sample Collected'?
                                        <Chip color="secondary" label="Sample Collected" ></Chip>
                                          :invoice.rpt_status == 'Report Genrated'?
                                        <Chip color="warning" label="Report Genrated" ></Chip>
                                        :<Chip color="warning" label="Report Genrated" ></Chip>
                                        }
                                      </TableCell>

                                      <TableCell align="center" sx={{fontWeight:500,textTransform:'capitalize'}} >
                                         {invoice.rpt_status == 'Sample Collected'?
                                        <Chip color="error" label="Not Billed" ></Chip>
                                          :invoice.rpt_status == 'Report Genrated'?
                                          <Chip color="error" label="Not Billed" ></Chip>
                                          :<Chip color="error" label={invoice.rpt_status} ></Chip>
                                        }
                                      </TableCell>

                                      <TableCell align="center" >
                                      <Tooltip title="Create Report">
                                        
                                           <IconButton aria-label="Edit" onClick={()=>history.push('/report_results/'+invoice.report_id)} color="info">
                                              <CreateIcon />
                                            </IconButton>
                                        </Tooltip>

                                        
                                            
                                          <Tooltip title="Delete">
                                            <IconButton aria-label="Delete" color="error" onClick={()=>delete_data(invoice.cs_id)} >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                      </TableCell>

                  </TableRow>
                  ))}
                  {invoice_list.length == 0  ? 
              <TableRow>
                <TableCell colSpan={5} align='center'>
                      
              <img
        src="https://jeetlabs.in/Api/no_data.png"
        srcSet="https://jeetlabs.in/Api/no_data.png"
        alt={'no data found'}
        style={{maxHeight:'250px',width:'auto'}}
        loading="lazy"
      />
                </TableCell>
              </TableRow>:''
              }

                </TableBody>
              </Table>
        
          
          <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={invoice_list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      </TableContainer>

      </Box>

<Modal show={show} onHide={handleClose} centered size="lg" >
 <Modal.Header closeButton>
 <Modal.Title id="example-modal-sizes-title-lg">
    Custom Date Selection
 </Modal.Title>
</Modal.Header> 
<Modal.Body style={{display:'flex',justifyContent:'center'}}>

<LocalizationProvider dateAdapter={AdapterDateFns}>
  <StaticDateRangePicker
  calendars={calender_count}
    displayStaticWrapperAs="desktop"
    value={value}
    onChange={(newValue) => {
      setValue(newValue);
    }}
    renderInput={(startProps, endProps) => (
      <React.Fragment>
        <TextField {...startProps} />
        <Box sx={{ mx: 2 }}> to </Box>
        <TextField {...endProps} />
      </React.Fragment>
    )}
    />
</LocalizationProvider>
    </Modal.Body>
    <Modal.Footer>
            <Button variant="contained" color="primary"  className={classes.stats_button} endIcon={<FilterAltIcon />} >Filter </Button>
      </Modal.Footer>
</Modal>







        </div>
    )
}
