import React,{ useState , useEffect } from 'react'
import {Button,Toolbar,TextField,Box,Alert,IconButton,Chip, Grid, Typography,Card,CardContent,CardActions, Avatar, MenuItem, InputLabel, FormControl, Select} from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Modal ,Container,Row,Col} from 'react-bootstrap';
import Sidebar from './parts/Sidebar';





const drawerWidth = 280;


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    elevation:'0',
    color: theme.palette.text.secondary,
  }));

  const Input = styled('input')({
    display: 'none',
  });

  export default function Branches() {

  
  let history = useHistory(); 
  
  const [headername, setHeadername] = useState()
  const [headerpop, setHeaderpop] = useState()
  const [header, setHeader] = useState('')
  
  const [footername, setFootername] = useState()
  const [footerpop, setFooterpop] = useState()
  const [footer, setFooter] = useState('')
  
  const [rawpop, setRawpop] = useState()
  const [pop, setPop] = useState('')
  
  const [stafflist, setStafflist] = useState([])
  const [branchhead, setBranchhead] = useState('')
  const [branchname, setBranchname] = useState('')
  const [branchaddress, setBranchaddress] = useState('')
  const [branchlist, setBranchlist] = useState([])

  const [resultmsg, setResultmsg] = useState('')
  const [resulttype, setResulttype] = useState('')

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState(false);
  function handleShow() {
    
    setShow(true);
  }

  function edit_branch(e){
    console.log(e.target)
    setEdit(true);
  }

  function click_image(){
    setImage(true);
  }

  function selectImage(e){
    console.log('pop ',e.target.files[0])
    setRawpop(e.target.files[0])
    var img = URL.createObjectURL(e.target.files[0])
    console.log(img)
    setPop(img)
  }

  function selectHeader(h){

    console.log('header ',h.target.files[0])
    setHeaderpop(h.target.files[0])
    var imgheader = URL.createObjectURL(h.target.files[0])
    console.log(imgheader)
    setHeader(imgheader)
  }

  const  selectFooter = (e) =>{
    // console.log('footer ',e.target.files[0])
    setFooterpop(e.target.files[0])
    var imgfooter = URL.createObjectURL(e.target.files[0])
    // console.log(imgfooter)
    setFooter(imgfooter)
  }

  function handleClose(){
    setBranchhead('')
    setBranchname('')
    setBranchaddress('')
    setResultmsg('')
    setResulttype('')
    setShow(false)
  }

  function handleEditClose(){
    setBranchhead('')
    setBranchname('')
    setBranchaddress('')
    setResultmsg('')
    setResulttype('')
    setEdit(false);
  }

  function handleImage(){
    setPop()
    setImage(false);
  }
  function update_branch(){

  }


  function create_branch(){

    var url = 'https://jeetlabs.in/Api/create_branch'
    var items = {branchhead,branchname,branchaddress}

       if(branchhead.length < 1 || branchname.length < 1 || branchaddress.length < 1 ){
         setResultmsg('All fields Required')
         setResulttype('error')
       }else{
         setResultmsg('Branch Creation in Progress')
         setResulttype('info')

           
       let result = fetch(url,{
         method:'POST',
         headers:{
           "Content-Type":"application/json",
           "Accept":"application/json"
         },
         body:JSON.stringify(items)
         
       }).then((response)=>{
         response.json().then((result)=>{
          //  console.log(result);
           setResultmsg(result.msg)
           setResulttype(result.type)
           
           branch_list();
             setTimeout(function() {
               handleClose()
         //   history.push('/Myorder')
         }, 1200);
         })
       }).catch(error =>{
         console.warn(error);
       })
       }

  
   }

   function staff_list(){

    var url = 'https://jeetlabs.in/Api/staff_list'
  
  let result = fetch(url,{
    method:'GET',
    headers:{
        "Content-Type":"application/json",
        "Accept":"application/json"
    }
  
  }).then((response)=>{
    response.json().then((result)=>{
      // console.log(result)
      setStafflist(result)
     
   
    })
  }).catch(error =>{
  console.warn(error);
  })
  
  }


  function branch_list(){

    var url = 'https://jeetlabs.in/Api/branch_list'
  
  let result = fetch(url,{
    method:'GET',
    headers:{
        "Content-Type":"application/json",
        "Accept":"application/json"
    }
  
  }).then((response)=>{
    response.json().then((result)=>{
      // console.log(result)
      setBranchlist(result)
     
   
    })
  }).catch(error =>{
  console.warn(error);
  })
  
  }


  var userid = JSON.parse(localStorage.getItem('userid'));
  var token = JSON.parse(localStorage.getItem('token'));


  function delete_data(branch_id){
    var url = 'https://jeetlabs.in/Api/delete_branch'
   var confirm = window.confirm('Confirm Delete');

   var items = {userid,token,branch_id}

   if(confirm){
    let result = fetch(url,{
      method:'POST',
      headers:{
        "Content-Type":"application/json",
        "Accept":"application/json"
      },
      body:JSON.stringify(items)
      
    }).then((response)=>{
      response.json().then((result)=>{
        // console.log(result);
        // setResultmsg(result.msg)
        // setResulttype(result.type)
        
        branch_list();
          setTimeout(function() {
            handleClose()
      //   history.push('/Myorder')
      }, 1200);
      })
    }).catch(error =>{
      console.warn(error);
    })
   }else{

    // console.log(branch_id+ ' is safe')
   }

  }
  
  
  
  useEffect(() => {
    staff_list();
    branch_list();
  },[])

  
    return (
        <div> 
          <Sidebar/>
            <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` },ml:{sm:`${drawerWidth}px`} }}
      >
        <Toolbar />

        <div>
          <div sx={{p:3,m:1}} style={{marginTop:20,marginBottom:20,padding:10,borderRadius:'12px' ,display:'flex',justifyContent:'space-between',border:'0.5px  lightgrey'}} >
            <Typography variant="h6" sx={{fontWeight:'medium'}} >Manage Branches</Typography>
            <Button variant="contained" onClick={()=>handleShow()} color="primary" className="modified_button" >+ Add Branch</Button>
          </div>

          <Grid container spacing={2}>
            {
              branchlist.map((branch)=>(

                
                <Grid item xs={12} md={4} elevation="0">
              <Item elevation="0">
                <Card>
                  <CardContent style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

                    <Avatar onClick={()=>click_image()}  src="" sx={{height:150,width:150,border:'5px solid darkcyan'}} />

                    

                    <Chip label={branch.branch_address} color="info" className="branchname" sx={{m:1,p:1,}} />
                    <Typography variant="subtitle1" sx={{fontWeight:'bold',color:'gray'}}>Head : {branch.branch_head}</Typography>


                  </CardContent>
                  <CardActions sx={{justifyContent:'center',borderTop:'0.5px solid lightgrey'}} >

                    <IconButton color="success" data-measseage={'hello world'} onClick={(e)=>edit_branch(e)}>
                        <CreateIcon data-measseage={'hello world'} onClick={(e)=>edit_branch(e)}/>
                      </IconButton>
                      <IconButton color="error" onClick={()=>delete_data(branch.branch_id)} >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton color="info">
                        <VisibilityIcon />
                      </IconButton>
                  </CardActions>
                </Card>
              </Item>
            </Grid>
           
              ))
            }
          </Grid>


        </div>





      </Box>


      <Modal show={show}   backdrop="static" centered style={{background:'#808080db',zIndex:'10000'}} size="lg" onHide={() => handleClose()}>
  <Modal.Header closeButton>
    <span style={{fontWeight:600}} >Add New Branch</span>
  </Modal.Header>
 
<Modal.Body>

        <TextField
          fullWidth
          required
          id="outlined-required"
          label="Branch Name"
          sx={{mb:2}}
          onChange={(e)=>setBranchname(e.target.value)}
        />

       <TextField
         fullWidth
          required
          id="outlined-required"
          label="Branch Address"
          sx={{mb:2}}
          multiline
          rows={4}
          onChange={(e)=>setBranchaddress(e.target.value)}
        />

<FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Branch Head</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={branchhead}
    label="Branch Head"
    onChange={(e)=>setBranchhead(e.target.value)}
    style={{zIndex:10000}}
  >

{
            stafflist.map((staff)=>(

              <MenuItem value={staff.name}>{staff.name}</MenuItem>
            ))
          }
    
  </Select>
</FormControl>

{
    resulttype == 'error'?
    <Alert sx={{m:2}} variant="filled" color="error">{resultmsg}</Alert>
    :resulttype == 'success'?<Alert sx={{m:2}} variant="filled" color="success">{resultmsg}</Alert>
    :resulttype == 'info'?<Alert  sx={{m:2}} variant="filled" color="info">{resultmsg}</Alert>
    :''
     
  }


  </Modal.Body>

  <Modal.Footer>
    <Button variant="contained" color="error" onClick={()=>handleClose()} sx={{m:1}} >Close</Button>
    <Button variant="contained" onClick={()=>create_branch()} color="success" sx={{m:1}}  >Create Branch</Button>
  </Modal.Footer>
</Modal>


{/* for the edit */}
<Modal show={edit}   backdrop="static" centered style={{background:'#808080db',zIndex:'10000'}} size="lg" onHide={() => handleEditClose()}>
  <Modal.Header closeButton>
    <span style={{fontWeight:600}} >Edit Branch</span>
  </Modal.Header>
 
<Modal.Body>

        <TextField
          fullWidth
          required
          id="outlined-required"
          label="Branch Name"
          sx={{mb:2}}
          onChange={(e)=>setBranchname(e.target.value)}
        />

       <TextField
         fullWidth
          required
          id="outlined-required"
          label="Branch Address"
          sx={{mb:2}}
          multiline
          rows={4}
          onChange={(e)=>setBranchaddress(e.target.value)}
        />

<FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Branch Head</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={branchhead}
    label="Branch Head"
    onChange={(e)=>setBranchhead(e.target.value)}
    style={{zIndex:10000}}
  >

{
            stafflist.map((staff)=>(

              <MenuItem value={staff.name}>{staff.name}</MenuItem>
            ))
          }
    
  </Select>
</FormControl>

{
    resulttype == 'error'?
    <Alert sx={{m:2}} variant="filled" color="error">{resultmsg}</Alert>
    :resulttype == 'success'?<Alert sx={{m:2}} variant="filled" color="success">{resultmsg}</Alert>
    :resulttype == 'info'?<Alert  sx={{m:2}} variant="filled" color="info">{resultmsg}</Alert>
    :''
     
  }


  </Modal.Body>

  <Modal.Footer>
    <Button variant="contained" color="error" onClick={()=>handleEditClose()} sx={{m:1}} >Close</Button>
    <Button variant="contained" onClick={()=>update_branch()} color="success" sx={{m:1}}  >Update Branch</Button>
  </Modal.Footer>
</Modal>
 {/* for the image */}


<Modal show={image}   backdrop="static" centered style={{background:'#808080db',zIndex:'10000'}} size="lg" onHide={() => handleImage()}>
  <Modal.Header>
    <span style={{fontWeight:600}} >Branch Image Data</span>
  </Modal.Header>
 
<Modal.Body>
  
  {/* {'pop '+pop  }
  <br/>
  {'header ' + header }<br/>
  {'footer ' + footer}<br/><br/> */}
<Row>
  <Col xs={4}>
<label htmlFor="contained-button-file">
  <Input accept="image/*" id="contained-button-file"  type="file" onChange={(e) => selectImage(e)}/>
  <Button variant="contained"  className='modified_button'  component="span">
    Upload Logo
  </Button>
</label>
</Col>
<Col xs={8}>
{
    pop == null || pop == ''? 
    'No Logo Selected'
        :
<img
        src={pop}
        srcSet={pop}
        alt={'Logo'}
        style={{maxHeight:'120px',width:'auto'}}
        loading="lazy"
      />
}
</Col>
</Row><br />
<Row>
  <Col xs={4}>
<label htmlFor="contained-button-file1">
  <Input accept="image/*" id="contained-button-file1"  type="file" onChange={(h) => selectHeader(h)}/>
  <Button variant="contained" className='modified_button' component="span">
    Upload Header
  </Button>
</label>
</Col>
<Col xs={8}>
{
    header == null || header == ''? 
    'No Header Image Selected'
        :
<img
        src={header}
        srcSet={header}
        alt={'Header'}
        style={{maxHeight:'120px',width:'auto'}}
        loading="lazy"
      />
}
</Col>
</Row><br />

<Row>
  <Col xs={4}>
<label htmlFor="contained-button-file2">
  <Input accept="image/*" id="contained-button-file2" type="file" onChange={(f) => selectFooter(f)}/>
  <Button variant="contained" className='modified_button' component="span" >
    Upload Footer
  </Button>
</label>
</Col>
<Col xs={8}>
  {
    footer == null || footer == ''? 
    'No footer Image Selected'
        :
<img
        src={footer}
        srcSet={footer}
        alt={'Footer'}
        style={{maxHeight:'120px',width:'auto'}}
        loading="lazy"
        />
      }
</Col>
</Row><br />
{
    resulttype == 'error'?
    <Alert sx={{m:2}} variant="filled" color="error">{resultmsg}</Alert>
    :resulttype == 'success'?<Alert sx={{m:2}} variant="filled" color="success">{resultmsg}</Alert>
    :resulttype == 'info'?<Alert  sx={{m:2}} variant="filled" color="info">{resultmsg}</Alert>
    :''
     
  }


  </Modal.Body>

  <Modal.Footer>
    <Button variant="contained" color="error" onClick={()=>handleImage()} sx={{m:1}} >Close</Button>
    <Button variant="contained" onClick={()=>create_branch()} color="success" sx={{m:1}}  >Upload Branch Images</Button>
  </Modal.Footer>
</Modal>

        </div>
    )
}
