import React,{ useState , useEffect } from 'react'
import {Avatar, Typography, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Paper, Box, Grid} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://CodexSolutions.in/">
       Codex Solutions
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {

  let history = useHistory(); 
  const [mobile, setMobile] = useState('')
  const [password, setPassword] = useState('')

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //     setMobile(data.get('mobile'))
  //     setPassword(data.get('password'))

  //     submitform();
  // };


  const [loginresult, setLoginresult] = useState()
  const [loginmsg, setLoginmsg] = useState()

  const [session, setSession] = useState('')   
  var userid = JSON.parse(localStorage.getItem('userid'));
      var token = JSON.parse(localStorage.getItem('token'));
  
      let url = 'https://jeetlabs.in/Api/validate_session?id='+userid+'&token='+token;

        if(userid && token){
      var items = {userid,token}
          fetch(url).then((response)=>{
              response.json().then((result)=>{
                // console.log(result)
                  // setSession(result)
                  if(result == "valid"){
                      history.push("/dashboard")
                  }
            })
          }).catch(error =>{
            console.warn(error);
             
          })
          }
          
          async function submitform(){

              if(mobile =='' || password == ''){
                  setLoginresult('failed')
                  setLoginmsg('All Fields Required')
              }else{

              

              let items = {mobile,password}
           
              // console.log(JSON.stringify(items));
              let result = fetch('https://jeetlabs.in/Api/check_login',{
                  method:'POST',
                  headers:{
                      "Content-Type":"application/json",
                      "Accept":"application/json"
                  },
                  body:JSON.stringify(items)

              }).then((response)=>{
                  response.json().then((result)=>{
                    console.log(result)
                      setLoginresult(result.type)
                      setLoginmsg(result.msg)
                      if(result.type == 'success'){
                          localStorage.setItem('userid', JSON.stringify(result.data.id))
                          localStorage.setItem('username', JSON.stringify(result.data.name))
                          localStorage.setItem('token', JSON.stringify(result.data.token))
                          localStorage.setItem('role', JSON.stringify(result.data.role))
                          localStorage.setItem("permissions", JSON.stringify(result.permissions));
                          history.push('/dashboard');
                      }
                  })
              }).catch(error =>{
                console.warn(error);
              })
              // console.log(items);
          }
              
          }



  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box  sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="mobile"
                label="Phone number"
                name="mobile"
                type="number"
                autoComplete="mobile"
                autoFocus
                onChange={(e)=>setMobile(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e)=>setPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={()=>submitform()}
              >
                Sign In
              </Button>
            
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}