import React,{useState,useEffect} from 'react'
import Sidebar from './parts/Sidebar'
import {Button,Toolbar,Box,TextField,FormHelperText,Typography,Grid, Divider,IconButton,Alert} from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import { useFormControl } from '@mui/material/FormControl';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import PropTypes from 'prop-types';
import BadgeIcon from '@mui/icons-material/Badge';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Clusters from './Clusters';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


function MyFormHelperText() {
    const { focused } = useFormControl() || {};
  
    const helperText = React.useMemo(() => {
      if (focused) {
        return 'This field is being focused';
      }
  
      return 'Helper text';
    }, [focused]);
  
    return <FormHelperText>{helperText}</FormHelperText>;
  }


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  
  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));
  
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    const icons = {
      1: <BadgeIcon />,
      2: <AssignmentIcon />,
      3: <VideoLabelIcon />,
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };


const drawerWidth = 280;


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    elevation:'0',
    color: theme.palette.text.secondary,
  }));

  
const steps = ['Add Name & Unit', 'Cluster Data', 'Results Set'];




export default function Create_cluster() {
  
  let history = useHistory(); 
  const theme = useTheme();
  
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [agegroup_list, setAgegroup_list] = useState([])

  const userTemplate = { agegroups : [] , min : '' , max : '' , genders : []  }
  const [ datacluster , setDatacluster ] = useState([userTemplate])
  const [lowcmnt, setLowcmnt] = useState('');
  const [normalcmnt, setNormalcmnt] = useState('')
  const [highcmnt, setHighcmnt] = useState('')

  const [agegroup, setAgegroup] = useState([]);
  const [gender, setGender] = useState([]);

  const [cluster_name, setCluster_name] = useState('')
  const [cluster_unit, setCluster_unit] = useState('')


  const [resultmsg, setResultmsg] = useState('')
  const [resulttype, setResulttype] = useState('')

  const isStepOptional = (step) => {
    return step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
        
      throw new Error("You can't skip a step that isn't optional.");

    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };







  function create_cluster(){

    var url = 'https://jeetlabs.in/Api/create_cluster'
    var items = {cluster_name,cluster_unit,datacluster,lowcmnt,normalcmnt,highcmnt}
    // console.log(items)


       if(cluster_name.length < 1  ){
         setResultmsg('All fields Required')
         setResulttype('error')
       }else{
         setResultmsg('Cluster Creation in Progress')
         setResulttype('info')

           
       let result = fetch(url,{
         method:'POST',
         headers:{
           "Content-Type":"application/json",
           "Accept":"application/json"
         },
         body:JSON.stringify(items)
         
       }).then((response)=>{
         response.json().then((result)=>{
          //  console.log(result);
           setResultmsg(result.msg)
           setResulttype(result.type)
           
        //    clusters_data();
             setTimeout(function() {
            //    handleClose()
            history.push('/clusters')
         }, 1200);
         })
       }).catch(error =>{
         console.warn(error);
       })
       }

  
   }
 
  function age_group_list(){

    var url = 'https://jeetlabs.in/Api/age_group_list'
  
  let result = fetch(url,{
    method:'GET',
    headers:{
        "Content-Type":"application/json",
        "Accept":"application/json"
    }
  
  }).then((response)=>{
    response.json().then((result)=>{
      // console.log(result)
      setAgegroup_list(result)
     
   
    })
  }).catch(error =>{
  console.warn(error);
  })
  
  }

  // function handleSubmit(event){
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);

  //   console.log({
  //     Age: data.get('age_group')
  //   });
  
  // //   for (var key of data.entries()) {
  // //     console.log(key[0] + ', ' + key[1]);
  // // }

  // }
  
  
  
  
  useEffect(() => {
    age_group_list();
  },[])

 




  const handleAgeGroup = (e,index) => {
    const { target: { value } } = e;
    setAgegroup( typeof value === 'string' ? value.split(',') : value);
    
   
    const addvalues = datacluster.map((data,i) => index == i ?
    Object.assign(data,{[e.target.name]:e.target.value} )

    :  data )
  };

  const handleGender = (e,index) => {
    const { target: { value } } = e;
    setGender( typeof value === 'string' ? value.split(',') : value);

    const addvalues = datacluster.map((data,i) => index == i ?
    Object.assign(data,{[e.target.name]:e.target.value} )
    :  data )
  };


    const addCluster = () => {
        setDatacluster([...datacluster,userTemplate])
    }

    const onvaluechange = (e,index) => {
        const addvalues = datacluster.map((data,i) => index == i ?
        Object.assign(data,{[e.target.name]:e.target.value} )
         :  data  )

         setDatacluster(addvalues)
         
    }

    const removecluster = (index) => {
        // console.log(index)
        const filtered = [...datacluster];
         filtered.splice(index,1);
         setDatacluster(filtered);
         
    }




    return (
        <div> 
            <Sidebar/>
            <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` },ml:{sm:`${drawerWidth}px`} }}
      >
        <Toolbar />

       
        <Box sx={{ width: '100%' }}>
           
      <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />} >

        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

        //   if (isStepOptional(index)) {
        //     labelProps.optional = (
        //       <Typography variant="caption" align="center" sx={{width:'100%'}}>Optional</Typography>
        //     );
        //   }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
            <StepLabel  {...labelProps} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>

            // <Step key={label} {...stepProps}>
            //   <StepLabel {...labelProps}>{label } </StepLabel>
            // </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
         
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset} variant="contained" color="secondary" >Go to List</Button>
            &nbsp;&nbsp;
            <Button onClick={handleReset} variant="contained" color="info" >Add New</Button>
          </Box>

          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

        {
          activeStep == 0? 

          <Button
          style={{ backgroundImage:'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',fontWeight:'bold' }}
           variant="contained" color="primary" onClick={()=>history.push('/clusters')} >
             Back
           </Button>
          :  <Button
            color="error"
            variant="contained"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            >
              Back
            </Button>
            }
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

              {activeStep === steps.length - 1 ?
               <Button onClick={()=> create_cluster()}
                style={{ backgroundImage:'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',fontWeight:'bold' }} 
                variant="contained" >
                    Save Cluster 
                </Button>:

               <Button onClick={handleNext} style={{ backgroundImage:'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',fontWeight:'bold' }} variant="contained"  >
                Next
            </Button>
                }
          </Box>

                    <br/>
                    <Divider />
                    <br/>

              <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep }</Typography>
              

              {
                  activeStep === 0?
                  <div>
                            <TextField variant="outlined" label="Cluster Name" defaultValue={cluster_name} onChange={ (e) => setCluster_name(e.target.value) }  name="cluster_name" sx={{m:2,minWidth:'40%'}} />
                            <TextField variant="outlined" label="Cluster Unit" defaultValue={cluster_unit}  onChange={ (e) => setCluster_unit(e.target.value) }  name="cluster_unit" sx={{m:2,minWidth:'40%'}} />
                    </div>
                  
                  :activeStep === 1?
                  <div>

                      {
                          datacluster.map((template,index)=>(

                      

            <Grid sx={{mt:0}} container spacing={2} key={index} >

                <Grid item xs={12} md={3}>

                    <FormControl sx={{ width:'100%'}}>
                        <InputLabel id="demo-multiple-name-label">Age Groups</InputLabel>
                        <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        name="agegroups"
                        value={datacluster[index]['agegroups']}
                        onChange={e => handleAgeGroup(e,index)}
                        input={<OutlinedInput label="Age Groups" />}
                        MenuProps={MenuProps}
                        >
                        {agegroup_list.map((name) => (

                            <MenuItem
                            key={name.age_group_id}
                            value={name.age_group_id}
                            style={getStyles(name.age_group_id, datacluster[index]['agegroups'], theme)}
                            >
                            {name.min_age_num +' '+ name.min_age_unit +' '+ name.max_age_num +' '+ name.max_age_unit}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>

                </Grid>
                
                <Grid item xs={12} md={2}>
                    {/* <TextField  label="Age Group" name={'age_group-'+group.age_group_id} fullWidth variant="outlined"  hidden/> */}
                    <TextField  value={datacluster[index]['min']} onChange={e => onvaluechange(e,index)} label="Min" name="min" fullWidth variant="outlined" />
                </Grid>

                <Grid item xs={12} md={2}>
                    <TextField  label="Max" value={datacluster[index]['max']} onChange={e => onvaluechange(e,index)} name="max" fullWidth variant="outlined" />
                </Grid>

                <Grid item xs={12} md={3}>

                    <FormControl sx={{ width:'100%'}}>
                        <InputLabel id="demo-multiple-name-label">Gender</InputLabel>
                        <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        name="genders"
                        value={datacluster[index]['genders']}
                        onChange={e => handleGender(e,index)}
                        input={<OutlinedInput label="Gender" />}
                        MenuProps={MenuProps}
                        >
                             <MenuItem key="male" value="Male" style={getStyles('male' ,datacluster[index]['genders'], theme)} >Male </MenuItem>
                             <MenuItem key="female" value="Female" style={getStyles('female',datacluster[index]['genders'], theme)} >Female </MenuItem>
                             <MenuItem key="other" value="Other" style={getStyles('other', datacluster[index]['genders'], theme)} >Other </MenuItem>

                        
                        </Select>
                    </FormControl>

                </Grid>

                
                <Grid item xs={12} md={1} sx={{m:'auto'}} >
                <IconButton color="error" size="large" onClick={()=> removecluster(index) } aria-label="Delete field">
                    <DeleteIcon />
                </IconButton>
                </Grid>
           </Grid>

))
}

                <Button variant="contained" sx={{m:2}} onClick={addCluster} size="large" style={{ backgroundImage:'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',fontWeight:'bold' }}>Add More</Button>
                    </div>
                    :activeStep === 2?
                        <div style={{marginBottom:20}} >


            <Grid sx={{mt:0}} container spacing={2} >
                     
            <Grid item xs={12} md={4}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Low"
                                multiline
                                fullWidth
                                defaultValue={lowcmnt}
                                rows={4}
                                onChange={(e)=>setLowcmnt(e.target.value)}
                                />
                        </Grid>


                     <Grid item xs={12} md={4}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Normal"
                                multiline
                                fullWidth
                                defaultValue={normalcmnt}
                                rows={4}
                                onChange={(e)=>setNormalcmnt(e.target.value)}
                                />
                        </Grid>


                     <Grid item xs={12} md={4}>
                            <TextField
                                id="outlined-multiline-static"
                                label="High"
                                multiline
                                fullWidth
                                rows={4}
                                defaultValue={highcmnt}
                                onChange={(e)=>setHighcmnt(e.target.value)}
                                />
                        </Grid>

                        </Grid>
                        </div>
                    :''
              }
              <br/>
                    <Divider />
                    <br/>

{
    resulttype == 'error'?
    <Alert variant="filled" color="error">{resultmsg}</Alert>
    :resulttype == 'success'?<Alert variant="filled" color="success">{resultmsg}</Alert>
    :resulttype == 'info'?<Alert variant="filled" color="info">{resultmsg}</Alert>
    :''
     
  }




              {/* <Button style={{}} variant="contained" style={{ backgroundImage:'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',fontWeight:'bold' }} onClick={()=>console.log(datacluster)} >Show Preview</Button> */}

        </React.Fragment>
      )}
    </Box>




      </Box>






        </div>
    )
}
