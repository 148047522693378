import React, { useState, useEffect } from "react"
import { useTheme } from "@mui/material/styles"
import Sidebar from "./parts/Sidebar"
import {
  Button,
  Toolbar,
  Alert,
  Input,
  Box,
  Table,
  TextField,
  InputAdornment,
  FormHelperText,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import { useHistory } from "react-router-dom"
import CreateIcon from "@mui/icons-material/Create"
import DeleteIcon from "@mui/icons-material/Delete"

import { Modal, Row, Col, InputGroup } from "react-bootstrap"
import Clusters from "./Clusters"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const drawerWidth = 280

function getStyles(name, selectedclusters, theme) {
  return {
    fontWeight:
      selectedclusters.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  elevation: "0",
  color: theme.palette.text.secondary,
}))

const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export default function TestPrice() {
  var initialState = ""

  let history = useHistory()
  const theme = useTheme()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [show, setShow] = useState(false)
  const [resultmsg, setResultmsg] = useState("")
  const [resulttype, setResulttype] = useState("")

  const [testname, setTestname] = useState("")
  const [sampletype, setSampletype] = useState("")
  const [testprice, setTestprice] = useState("")

  const [selectedclusters, setSelectedclusters] = useState([])
  const [clusters_list, setClusters_list] = useState([])

  const [test_list, setTest_list] = useState([])

  const [cgst, setCgst] = useState("")
  const [sgst, setSgst] = useState("")

  //  edit action
  const [edit_test_price_modal, setEdit_test_price_modal] = useState(false)
  const [update_test_price_id, setupdate_test_price_id] = useState(initialState)

  const [new_testname, setNew_testname] = useState(initialState)
  const [new_sampletype, setNew_sampletype] = useState(initialState)
  const [new_testprice, setNew_testprice] = useState(initialState)

  const [new_selectedclusters, setNew_selectedclusters] = useState([])

  const [new_cgst, setNew_cgst] = useState(initialState)
  const [new_sgst, setNew_sgst] = useState(initialState)

  const handleChangeTestPrice = test => {
    setupdate_test_price_id(test.test_data.test_id)

    setNew_testname(test.test_data.test_name)
    setNew_sampletype(test.test_data.sample_type)
    setNew_testprice(test.test_data.test_price)

    var clusters_id = []
    test.clusters.map(eachClusters => {
      clusters_id.push(eachClusters.cl_id)
    })
    setNew_selectedclusters(clusters_id)

    setNew_cgst(test.test_data.c_gst)
    setNew_sgst(test.test_data.s_gst)

    setEdit_test_price_modal(true)
  }

  const handleChange = event => {
    const {
      target: { value },
    } = event
    setSelectedclusters(typeof value === "string" ? value.split(",") : value)
  }
  const handleNewChange = event => {
    const {
      target: { value },
    } = event
    setNew_selectedclusters(
      typeof value === "string" ? value.split(",") : value
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // function create_test(){

  //   var items = {testname,sampletype,testprice,selectedclusters}
  //   console.log(items)
  // }

  function handleClose() {
    setTestname("")
    setTestprice("")
    setSelectedclusters([])
    setSampletype("")
    setResultmsg("")
    setResulttype("")
    setShow(false)
  }

  function handleTestPriceClose() {
    setupdate_test_price_id("")
    setNew_testname("")
    setNew_testprice("")
    setNew_selectedclusters([])
    setNew_sampletype("")
    setResultmsg("")
    setResulttype("")
    setEdit_test_price_modal(false)
  }

  function total(price, sgst, cgst) {
    var price = Number(price)
    var total_sgst = (price / 100) * Number(sgst)
    var total_cgst = (price / 100) * Number(cgst)

    var total = price + total_cgst + total_sgst
    // return Math.round(price , 2)
    return total.toFixed(2)
  }

  function clusters_data() {
    var url = "https://jeetlabs.in/Api/clusters_data"

    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          // console.log(result)
          setClusters_list(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  function test_data() {
    var url = "https://jeetlabs.in/Api/test_data"

    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          // console.log(result)
          setTest_list(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  function create_test() {
    var url = "https://jeetlabs.in/Api/create_test"
    var items = {
      testname,
      sampletype,
      testprice,
      selectedclusters,
      cgst,
      sgst,
    }

    if (
      testname.length < 1 ||
      sampletype.length < 1 ||
      testprice.length < 1 ||
      selectedclusters.length < 1
    ) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Test Creation in Progress")
      setResulttype("info")

      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            setTimeout(function () {
              test_data()
              handleClose()
              //   history.push('/Myorder')
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }
  function update_test() {}
  var userid = JSON.parse(localStorage.getItem("userid"))
  var token = JSON.parse(localStorage.getItem("token"))

  function delete_data(branch_id) {
    var url = "https://jeetlabs.in/Api/delete_test"
    var confirm = window.confirm("Confirm Delete")

    var items = { userid, token, branch_id }

    if (confirm) {
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            // setResultmsg(result.msg)
            // setResulttype(result.type)

            test_data()
          })
        })
        .catch(error => {
          console.warn(error)
        })
    } else {
      //  console.log(branch_id+ ' is safe')
    }
  }

  useEffect(() => {
    test_data()
    clusters_data()
  }, [])

  return (
    <div>
      <Sidebar />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar />

        <div>
          <div
            sx={{ p: 3, m: 1 }}
            style={{
              marginTop: 20,
              marginBottom: 20,
              padding: 10,
              borderRadius: "12px",
              display: "flex",
              justifyContent: "space-between",
              border: "0.5px  lightgrey",
            }}
          >
            <Typography variant='h6' sx={{ fontWeight: "medium" }}>
              Test Prices
            </Typography>
            <Button
              variant='contained'
              onClick={() => setShow(true)}
              color='primary'
              className='modified_button'
            >
              + Create{" "}
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table aria-label='simple table' size='small'>
              <TableHead>
                <TableRow>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Sample Type
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Clusters
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Tax
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Price + CGST + SGST
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {test_list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(test => (
                    <TableRow>
                      <TableCell align='center'>
                        {test.test_data.test_name}
                      </TableCell>
                      <TableCell align='center'>
                        {test.test_data.sample_type}
                      </TableCell>
                      <TableCell align='center'>
                        {test.clusters.map(clusters => (
                          <Chip
                            label={clusters.cl_name}
                            sx={{ m: 0.2 }}
                            color='warning'
                          />
                        ))}
                      </TableCell>
                      <TableCell align='center'>
                        {test.test_data.test_price}
                      </TableCell>
                      <TableCell align='center'>
                        <Chip
                          label={"Center : " + test.test_data.c_gst + "%"}
                          sx={{ m: 0.25 }}
                          color='primary'
                        />
                        <Chip
                          label={"State : " + test.test_data.s_gst + "%"}
                          sx={{ m: 0.25 }}
                          color='success'
                        />
                      </TableCell>
                      <TableCell align='center'>
                        {" "}
                        {total(
                          test.test_data.test_price,
                          test.test_data.c_gst,
                          test.test_data.s_gst
                        )}
                      </TableCell>

                      <TableCell width='10%' align='center'>
                        <IconButton aria-label='Edit' color='success'>
                          <CreateIcon
                            onClick={() => handleChangeTestPrice(test)}
                          />
                        </IconButton>
                        <IconButton
                          aria-label='Delete'
                          color='secondary'
                          onClick={() => delete_data(test.test_data.test_id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                {test_list.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align='center'>
                      <img
                        src='https://jeetlabs.in/Api/no_data.png'
                        srcSet='https://jeetlabs.in/Api/no_data.png'
                        alt={"no data found"}
                        style={{ maxHeight: "250px", width: "auto" }}
                        loading='lazy'
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={test_list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Box>

      <Modal
        show={show}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handleClose()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>Create Tests</span>
        </Modal.Header>

        <Modal.Body>
          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Test Name'
                sx={{ mb: 2 }}
                defaultValue={testname}
                onChange={e => setTestname(e.target.value)}
              />
            </Col>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Sample Type'
                sx={{ mb: 2 }}
                defaultValue={sampletype}
                onChange={e => setSampletype(e.target.value)}
              />
            </Col>
          </Row>

          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Test Price'
                type='number'
                sx={{ mb: 2 }}
                defaultValue={testprice}
                onChange={e => setTestprice(e.target.value)}
              />
            </Col>

            <Col md>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id='demo-multiple-name-label'>Cluster</InputLabel>
                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  multiple
                  value={selectedclusters}
                  onChange={handleChange}
                  input={<OutlinedInput label='Cluster' />}
                  MenuProps={MenuProps}
                >
                  {clusters_list.map(cluster => (
                    <MenuItem
                      key={cluster.main.cl_id}
                      value={cluster.main.cl_id}
                      style={getStyles(
                        cluster.main.cl_name,
                        selectedclusters,
                        theme
                      )}
                    >
                      {cluster.main.cl_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          </Row>

          <Row className='g-2'>
            <Col md>
              <FormControl sx={{ width: "100%" }} variant='outlined'>
                <InputLabel htmlFor='outlined-adornment-password'>
                  Center GST
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password'
                  defaultValue={cgst}
                  onChange={e => setCgst(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>%</InputAdornment>
                  }
                  label='Center Gst'
                />
              </FormControl>
            </Col>
            <Col md>
              <FormControl sx={{ width: "100%" }} variant='outlined'>
                <InputLabel htmlFor='outlined-adornment-password'>
                  State GST
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password'
                  defaultValue={sgst}
                  onChange={e => setSgst(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>%</InputAdornment>
                  }
                  label='State Gst'
                />
              </FormControl>
            </Col>
          </Row>

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => create_test()}
            color='success'
            sx={{ m: 1 }}
          >
            Create Test
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={edit_test_price_modal}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handleTestPriceClose()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>Edit Tests</span>
        </Modal.Header>

        <Modal.Body>
          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Test Name'
                sx={{ mb: 2 }}
                value={new_testname}
                onChange={e => setNew_testname(e.target.value)}
              />
            </Col>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Sample Type'
                sx={{ mb: 2 }}
                value={new_sampletype}
                onChange={e => setNew_sampletype(e.target.value)}
              />
            </Col>
          </Row>

          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Test Price'
                type='number'
                sx={{ mb: 2 }}
                value={new_testprice}
                onChange={e => setNew_testprice(e.target.value)}
              />
            </Col>

            <Col md>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id='demo-multiple-name-label'>Cluster</InputLabel>
                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  multiple
                  value={new_selectedclusters}
                  onChange={handleNewChange}
                  input={<OutlinedInput label='Cluster' />}
                  sx={{ wb: 2 }}
                  MenuProps={MenuProps}
                >
                  {clusters_list.map(cluster => (
                    <MenuItem
                      key={cluster.main.cl_id}
                      value={cluster.main.cl_id}
                      style={getStyles(
                        cluster.main.cl_name,
                        new_selectedclusters,
                        theme
                      )}
                    >
                      {cluster.main.cl_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          </Row>

          <Row className='g-2'>
            <Col md>
              <FormControl sx={{ width: "100%" }} variant='outlined'>
                <InputLabel htmlFor='outlined-adornment-password'>
                  Center GST
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password'
                  value={new_cgst}
                  onChange={e => setNew_cgst(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>%</InputAdornment>
                  }
                  label='Center Gst'
                />
              </FormControl>
            </Col>
            <Col md>
              <FormControl sx={{ width: "100%" }} variant='outlined'>
                <InputLabel htmlFor='outlined-adornment-password'>
                  State GST
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password'
                  value={new_sgst}
                  onChange={e => setNew_sgst(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>%</InputAdornment>
                  }
                  label='State Gst'
                />
              </FormControl>
            </Col>
          </Row>

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleTestPriceClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => update_test()}
            color='success'
            sx={{ m: 1 }}
          >
            Update Test
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
