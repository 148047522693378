import React, { useState, useEffect } from "react"
import Sidebar from "./parts/Sidebar"
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  Toolbar,
  Tooltip,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Avatar,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import { Link, useHistory } from "react-router-dom"
import CreateIcon from "@mui/icons-material/Create"
import DeleteIcon from "@mui/icons-material/Delete"
import { Modal, Row, Col } from "react-bootstrap"
import SettingsIcon from "@mui/icons-material/Settings"
import { useInput } from "@mui/core"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"

const StyledInputElement = styled("input")`
  width: 200px;
  font-size: 1rem;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  background: #fff;
  border: 1.5px solid #606060;
  border-radius: 10px;
  padding: 6px 10px;
  color: #20262d;
  transition: width 300ms ease;

  &:hover {
    background: #fff;
    border-color: #606060;
  }

  &:focus {
    outline: none;
    width: 220px;
    transition: width 200ms ease-out;
  }
`

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  const { getRootProps, getInputProps } = useInput(props, ref)

  return (
    <div {...getRootProps()}>
      <StyledInputElement {...props} {...getInputProps()} />
    </div>
  )
})

const drawerWidth = 280

export default function Customers() {
  const [show, setShow] = useState(false)

  const [resultmsg, setResultmsg] = useState("")
  const [resulttype, setResulttype] = useState("")

  const [name, setName] = useState("")
  const [mobile, setMobile] = useState("")
  const [address, setAddress] = useState("")
  const [additional, setAdditional] = useState("")
  const [email, setEmail] = useState("")

  const [customers_list, setCustomers_list] = useState([])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [open, setOpen] = useState(false)

  const hidesnack = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function handleShow() {
    setShow(true)
  }

  function handleClose() {
    setName("")
    setMobile("")
    setAddress("")
    setAdditional("")
    setResultmsg("")
    setResulttype("")
    setShow(false)
  }

  function create_customer() {
    var url = "https://jeetlabs.in/Api/create_customer"
    var items = { name, mobile, email, address, additional }

    if (name.length < 1 || email.length < 1) {
      setResultmsg(" At least Name & Mobile no is Required")
      setResulttype("error")
    } else {
      setResultmsg("Customer Creation in Progress")
      setResulttype("info")

      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            customerslist()
            setTimeout(function () {
              handleClose()
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  function customerslist() {
    var url = "https://jeetlabs.in/Api/customers_list"

    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          // console.log(result)
          setCustomers_list(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  function search_customers_list(value) {
    var url = "https://jeetlabs.in/Api/search_customers"
    var items = { data: value }

    let result = fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(items),
    })
      .then(response => {
        response.json().then(result => {
          console.log(result)
          if (result.type === "error" && result.msg === "No Data Found") {
            setCustomers_list([])
          } else {
            setCustomers_list(result)
          }
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  var userid = JSON.parse(localStorage.getItem("userid"))
  var token = JSON.parse(localStorage.getItem("token"))

  function delete_data(branch_id) {
    var url = "https://jeetlabs.in/Api/delete_customer"
    var confirm = window.confirm("Confirm Delete")

    var items = { userid, token, branch_id }

    if (confirm) {
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            // console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            customerslist()
            setOpen(true)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    } else {
      // console.log(branch_id+ ' is safe')
    }
  }

  useEffect(() => {
    customerslist()
  }, [])

  let history = useHistory()

  return (
    <div>
      <Sidebar />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar />

        <div>
          <div
            sx={{ p: 3, m: 1 }}
            style={{
              marginTop: 20,
              marginBottom: 20,
              padding: 10,
              borderRadius: "12px",
              display: "flex",
              justifyContent: "space-between",
              border: "0.5px  lightgrey",
            }}
          >
            {/* <Typography variant="h6" sx={{fontWeight:'medium'}} >Customers List</Typography> */}
            <CustomInput
              aria-label='Search'
              onChange={e => {
                search_customers_list(e.target.value)
              }}
              placeholder='Search something...'
            />
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleShow()}
              className='modified_button'
            >
              + Create{" "}
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Button sx={{ p: 2 }} endIcon={<ArrowDownwardIcon />}>
              Export
            </Button>

            <Table sx={{ minWidth: "550px" }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Customer Code
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    DP
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    E-Mail
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Address
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Additional
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers_list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(customer => (
                    <TableRow>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {customer.cs_id}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        <Avatar src={customer.cs_picture} />
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {customer.cs_name}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {" "}
                        {customer.cs_mobile_no}{" "}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {customer.cs_email}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {customer.cs_address}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ fontWeight: 500, textTransform: "capitalize" }}
                      >
                        {" "}
                        {customer.cs_additional}{" "}
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title='Create Report'>
                          <IconButton
                            aria-label='Edit'
                            onClick={() =>
                              history.push("/create/" + customer.cs_id)
                            }
                            color='info'
                          >
                            <CreateIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title='View Customer'>
                          <IconButton aria-label='Edit' color='success'>
                            <SettingsIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title='Delete'>
                          <IconButton
                            aria-label='Delete'
                            color='error'
                            onClick={() => delete_data(customer.cs_id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                {customers_list.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align='center'>
                      <img
                        src='https://jeetlabs.in/Api/no_data.png'
                        srcSet='https://jeetlabs.in/Api/no_data.png'
                        alt={"no data found"}
                        style={{ maxHeight: "250px", width: "auto" }}
                        loading='lazy'
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={customers_list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Box>

      <Modal
        show={show}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handleClose()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>Add Customers</span>
        </Modal.Header>

        <Modal.Body>
          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Name'
                sx={{ mb: 2 }}
                onChange={e => setName(e.target.value)}
              />
            </Col>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Mobile No'
                sx={{ mb: 2 }}
                type='number'
                onChange={e => setMobile(e.target.value)}
              />
            </Col>
          </Row>

          <TextField
            fullWidth
            required
            id='outlined-required'
            label='Email'
            sx={{ mb: 2 }}
            type='email'
            onChange={e => setEmail(e.target.value)}
          />

          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Address'
                multiline
                rows={4}
                sx={{ mb: 2 }}
                onChange={e => setAddress(e.target.value)}
              />
            </Col>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Additional'
                sx={{ mb: 2 }}
                multiline
                rows={4}
                onChange={e => setAdditional(e.target.value)}
              />
            </Col>
          </Row>

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => create_customer()}
            color='success'
            sx={{ m: 1 }}
          >
            Add Customers
          </Button>
        </Modal.Footer>
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={hidesnack}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert onClose={hidesnack} severity={resulttype} sx={{ width: "100%" }}>
          {resultmsg}
        </Alert>
      </Snackbar>
    </div>
  )
}
