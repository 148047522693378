import React ,{useState ,useEffect} from 'react';
import PropTypes from 'prop-types';
import { Avatar,AppBar,CssBaseline,Drawer,IconButton,List, Collapse,ListItemIcon,ListItemText,ListItemButton} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PaymentsIcon from '@mui/icons-material/Payments';
import ColorizeIcon from '@mui/icons-material/Colorize';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Link ,useLocation,useHistory} from 'react-router-dom';
import CropFreeIcon from '@mui/icons-material/CropFree';


const drawerWidth = 280;

function toggleFullScreen() {
  if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}

export default function Sidebar(props) {
  const location = useLocation();
  const pathname = location.pathname;
  const history = useHistory();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  var permissions = JSON.parse(localStorage.getItem('permissions'));
  // console.log(permissions)
//   if(permissions != null){
//   console.log(permissions.includes('self reports'))
// }


  const [menuopen, SetMainmenu] = useState(false);
  const [staffopen, SetStaffopen] = useState(false);
  const [profileopen, SetProfileopen] = useState(false);



  function opened_menus(){
    if(pathname == '/agegroup' || pathname == '/clusters' || pathname == '/testprice' ){
      SetMainmenu(true)
      SetStaffopen(false)
      SetProfileopen(false)
    }else if(pathname == '/groups' || pathname == '/staff'  ){
      SetMainmenu(false)
      SetStaffopen(true)
      SetProfileopen(false)
    }else if(pathname == '/branches' || pathname == '/activity' || pathname == '/banking' ){
      SetMainmenu(false)
      SetStaffopen(false)
      SetProfileopen(true)
    }else{
      SetMainmenu(false)
      SetStaffopen(false)
      SetProfileopen(false)
    }
  }
  
  useEffect(() => {
    opened_menus();
  },[])


  const mainmenuopen = () => {
    SetMainmenu(!menuopen);
  };

  const handlestaffopen = () => {
    SetStaffopen(!staffopen);
  };

  const handleprofileopen = () => {
    SetProfileopen(!profileopen);
  };

  const drawer = (
    <div style={{paddingTop:20 }} >
      {/* <Toolbar /> */}

      
<div style={{display:'flex',justifyContent:'center',alignItems:'center'}} >

      <Avatar style={{margin:8}} height={25} width={25}>A</Avatar>
</div>

      <ListItemButton >
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText  variant="success" secondary="Welcome User" />
       
      </ListItemButton>

<Link to="/dashboard">
        <ListItemButton selected={pathname == '/dashboard'?true:false} >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText secondary="Dashboard" />
          </ListItemButton>
      </Link>

      <Link to="/create/0">
        <ListItemButton selected={pathname == '/create/0'?true:false} >
            <ListItemIcon>
              <ControlPointIcon />
            </ListItemIcon>
            <ListItemText secondary="Create Report" />
          </ListItemButton>
      </Link>

      <Link to="/invoices">
        <ListItemButton selected={pathname == '/invoices'?true:false} >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText secondary="Invoices" />
          </ListItemButton>
      </Link>


     
      <ListItemButton onClick={mainmenuopen}  >
        <ListItemIcon>
          <AppsOutlinedIcon />
        </ListItemIcon>
        <ListItemText secondary="Report Assets" style={{color:'green!important'}} />
        {menuopen ? <ExpandLess className="sideicons " /> : <ExpandMore className="sideicons " />}
      </ListItemButton>

      <Collapse in={menuopen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>

      <Link to="/agegroup">
        <ListItemButton selected={pathname == '/agegroup'?true:false}  sx={{ pl: 4 }}>
            <ListItemIcon>
              <ExtensionOutlinedIcon />
            </ListItemIcon>
            <ListItemText secondary="Age Group" />
          </ListItemButton>
      </Link>

      <Link to="/clusters">
          <ListItemButton selected={pathname == '/clusters'?true:false} sx={{ pl: 4 }}>
            <ListItemIcon>
              <ColorizeIcon />
            </ListItemIcon>
            <ListItemText secondary="Clusters" />
          </ListItemButton>
      </Link>

      <Link to="/testprice">
          <ListItemButton selected={pathname == '/testprice'?true:false} sx={{ pl: 4 }}>
            <ListItemIcon>
              <PaymentsIcon />
            </ListItemIcon>
            <ListItemText secondary="Tests Price" />
          </ListItemButton>
      </Link>
{/* 
      <Link to="/Myorder">
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <RestoreOutlinedIcon />
            </ListItemIcon>
            <ListItemText secondary="My Order " />
          </ListItemButton>
      </Link>

      <Link to="/Bonus">
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AccountBalanceWalletOutlinedIcon />
            </ListItemIcon>
            <ListItemText secondary="Bonus" />
          </ListItemButton>
      </Link> */}



        </List>
      </Collapse>



      <ListItemButton  onClick={handlestaffopen}>
        <ListItemIcon>
          <GroupsIcon />
        </ListItemIcon>
        <ListItemText secondary="Staff" />
        {staffopen ? <ExpandLess className="sideicons " /> : <ExpandMore className="sideicons " />}
      </ListItemButton>

        <Collapse in={staffopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

          <Link to="/groups">
            <ListItemButton selected={pathname == '/groups'?true:false} sx={{ pl: 4 }}>
              <ListItemIcon>
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText secondary="Roles" />
            </ListItemButton>
        </Link>

        <Link to="/staff">
            <ListItemButton selected={pathname == '/staff'?true:false} sx={{ pl: 4 }}>
              <ListItemIcon>
                <GroupAddIcon />
              </ListItemIcon>
              <ListItemText secondary="Staff Member" />
            </ListItemButton>
        </Link>


          </List>
        </Collapse>

      <ListItemButton  onClick={handleprofileopen}>
        <ListItemIcon>
          <AdminPanelSettingsIcon />
        </ListItemIcon>
        <ListItemText secondary="Management" />
        {profileopen ? <ExpandLess className="sideicons " /> : <ExpandMore className="sideicons " />}
      </ListItemButton>

      <Collapse in={profileopen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
      
    <Link to="/branches">
        <ListItemButton selected={pathname == '/branches'?true:false} sx={{ pl: 4 }}>
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText secondary="Branches" />
          </ListItemButton>
    </Link>

<Link to="/activity">
  <ListItemButton selected={pathname == '/activity'?true:false} sx={{ pl: 4 }}>
    <ListItemIcon>
      <ViewStreamIcon />
    </ListItemIcon>
    <ListItemText secondary="Activity Log" />
  </ListItemButton>
</Link>

<Link to="/banking">
  <ListItemButton selected={pathname == '/banking'?true:false} sx={{ pl: 4 }}>
    <ListItemIcon>
      <AccountBalanceIcon />
    </ListItemIcon>
    <ListItemText secondary="Banking" />
  </ListItemButton>
</Link>

</List>
      </Collapse>

<Link to="/customers">
  <ListItemButton selected={pathname == '/customers'?true:false} sx={{  }}>
    <ListItemIcon>
      <ContactPageIcon />
    </ListItemIcon>
    <ListItemText secondary="Customers" />
  </ListItemButton>
</Link>

<Link to="/products">
  <ListItemButton selected={pathname == '/products'?true:false} sx={{  }}>
    <ListItemIcon>
      <LocalMallIcon />
    </ListItemIcon>
    <ListItemText secondary="Products" />
  </ListItemButton>
</Link>


<Link to="/partners">
  <ListItemButton selected={pathname == '/partners'?true:false} sx={{ }}>
    <ListItemIcon>
      <GroupsIcon />
    </ListItemIcon>
    <ListItemText secondary="Partners" />
  </ListItemButton>
</Link>

    
{/* 
<Link to="/recyclebin">
        <ListItemButton >
          <ListItemIcon>
            <AutoDeleteIcon />
          </ListItemIcon>
          <ListItemText secondary="Recycle Bin" />
        </ListItemButton>
      </Link>


      <Link to="/billing">
        <ListItemButton >
          <ListItemIcon>
            <ReceiptLongIcon/>
          </ListItemIcon>
          <ListItemText secondary="Billing" />
        </ListItemButton>
      </Link> */}
      
      <Link to="/profile">
        <ListItemButton selected={pathname == '/profile'?true:false} >
          <ListItemIcon>
            <SupervisedUserCircleIcon />
          </ListItemIcon>
          <ListItemText secondary="Profile" />
        </ListItemButton>
      </Link>


      <Link to="/logout">
        <ListItemButton >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText secondary="Logout" />
        </ListItemButton>
      </Link>


    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }; 


  return (
    // <Box sx={{ display: 'flex' }}>

<div style={{zIndex:1}}>
    
      <CssBaseline />
      <AppBar
      
      elevation=""
        position="fixed"
        style={{zIndex:1,background:'#fff'}}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
         
        }}
        
      >
        <Toolbar style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
          <IconButton
            
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
            
            className="sideicons"
          >
            <MenuIcon style={{}}/>
          </IconButton>
         
        {auth && (
            <div sx={{right:10}} >
              <IconButton
                
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                className="sideicons"
                
              >
                <AccountCircle  style={{color:'#fff'}}/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>



            </div>
          )}
                <IconButton aria-label='Full Screen' onClick={() => toggleFullScreen()} className="sideicons">
                    <CropFreeIcon  style={{color:''}}/>
                </IconButton>
        </Toolbar>
      </AppBar>
      {/* <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
        
      > */}
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
        
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}

          style={{zIndex:1}}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            zIndex:1,
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          style={{zIndex:1}}
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            zIndex:1,
          }}
          open
        >
          {drawer}
        </Drawer>
      {/* </Box>
       */}

</div>

    // </Box>
  );
}

Sidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


