import React,{useState,useEffect} from 'react'
import Sidebar from './parts/Sidebar'
import {Button,Select,Badge ,Alert, TextField, FormControl, MenuItem , InputLabel,Toolbar,Box,IconButton,Chip, Grid, Typography,Card,CardContent,CardActions, Avatar} from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import { Modal, Row ,Col } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';
import ColorizeIcon from '@mui/icons-material/Colorize';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';


import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
  'Select master blaster campaign settings',
  'Create an ad group',
  'Create an ad',
];

const drawerWidth = 280;


const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 28,
  height: 28,
  border: `2px solid ${theme.palette.background.paper}`,
  padding:'5px'
}));

const useStyles = makeStyles({
  maincard:{
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
    alignItems:'center',
    padding:'20px',
    borderRadius:16,
  },
  maincard_title:{
    padding:20,
    fontSize:'large',
    fontWeight:'bold'
  },
  timelinedot:{
    backgroundColor:'transparent',
    boxShadow:'0px!important'
  }

});



const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    elevation:'0',
    color: theme.palette.text.secondary,
  }));

  

export default function Staff() {
  
  let history = useHistory(); 
  const classes = useStyles();

  const [show, setShow] = useState(false);

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [password, setPassword] = useState('')
  const [address, setAddress] = useState('')
  const [role, setRole] = useState('');
  const [resultmsg, setResultmsg] = useState('')
  const [resulttype, setResulttype] = useState('')
  const [stafflist, setStafflist] = useState([])
   
  const [roleslist, setRoleslist] = useState([])
  
  function handleShow() {
    
    setShow(true);
  }

  function handleClose(){
    setRole('')
    setResulttype('')
    setResultmsg('')
    setShow(false)
  }

  function get_report_gen_data() {
    var url = "https://jeetlabs.in/Api/get_report_gen_data";
    var token = 2

    let result = fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        token,
      }),
    })
      .then((response) => {
        response.json().then((result) => {
          console.log(result)
          // setClusters_box(result.inputs);
          // setReport_data(result.report_data);
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  useEffect(() => {
    get_report_gen_data();
  }, []);

  
    return (
        <div> 
            <Sidebar/>
            
            <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` },ml:{sm:`${drawerWidth}px`} }}
      >
        <Toolbar />

      

        <Card>

          <Row>
            <Col>
            
            <Stepper activeStep={2} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

            </Col>
          </Row>

        </Card>





      </Box>





        </div>
    )
}
