import React,{useState,useEffect} from 'react'
import Sidebar from './parts/Sidebar'
import {Button,Toolbar,Box,Table,Checkbox, Grid, TableBody, TableCell,TextField, TableContainer, TableHead, TableRow,IconButton,Chip, Typography, Divider, Alert} from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import { Modal ,Container,Row,Col} from 'react-bootstrap';
import permissions_list from './permissions/Permissions_list';


const drawerWidth = 280;


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: '',
    elevation:'2',
    color: theme.palette.text.secondary,
  }));

  

  

export default function Groups() {

  var perms = JSON.parse(localStorage.getItem('permissions'));
  

  let history = useHistory(); 
  const [show, setShow] = useState(false);

  const [permissions, setPermissions] = useState([])
  const [roletitle, setRoletitle] = useState('')

  const [resultmsg, setResultmsg] = useState('')
  const [resulttype, setResulttype] = useState('')

  const [roleslist, setRoleslist] = useState([])

  function handleShow() {
    
    setShow(true);
  }

  function handleClose(){
    setResultmsg('')
    setResulttype('')
    setRoletitle('')
    setPermissions([])
    setShow(false)
  }

  function permissionhandler(e){
      let perm = permissions;


      if(e.target.checked){
        perm.push(e.target.value);
      }else{
        if(perm.length > 0){
          var index = perm.indexOf(e.target.value);
          if (index !== -1) {
            perm.splice(index, 1);
            setPermissions(perm);
          }
        }
      }

    }


  var userid = JSON.parse(localStorage.getItem('userid'));
  var token = JSON.parse(localStorage.getItem('token'));


  function delete_data(branch_id){
    var url = 'https://jeetlabs.in/Api/delete_role'
   var confirm = window.confirm('Confirm Delete');

   var items = {userid,token,branch_id}

   if(confirm){
    let result = fetch(url,{
      method:'POST',
      headers:{
        "Content-Type":"application/json",
        "Accept":"application/json"
      },
      body:JSON.stringify(items)
      
    }).then((response)=>{
      response.json().then((result)=>{
        // console.log(result);
        // setResultmsg(result.msg)
        // setResulttype(result.type)
        
        role_list();
          setTimeout(function() {
            handleClose()
      //   history.push('/Myorder')
      }, 1200);
      })
    }).catch(error =>{
      console.warn(error);
    })
   }else{

    // console.log(branch_id+ ' is safe')
   }

  }
  

    function create_role(){

     var url = 'https://jeetlabs.in/Api/create_role'
     var items = {roletitle,permissions}

        if(roletitle.length < 1){
          setResultmsg('Group Title is Required')
          setResulttype('error')
        }else if(permissions.length < 1){
          setResultmsg('Choose at least Some Permissions')
          setResulttype('error')
        }else{
          setResultmsg('Creating Role in Progress')
          setResulttype('info')

            
        let result = fetch(url,{
          method:'POST',
          headers:{
            "Content-Type":"application/json",
            "Accept":"application/json"
          },
          body:JSON.stringify(items)
          
        }).then((response)=>{
          response.json().then((result)=>{
            setResultmsg(result.msg)
            setResulttype(result.type)
            role_list();
              setTimeout(function() {
                handleClose()
          //   history.push('/Myorder')
          }, 1200);
          })
        }).catch(error =>{
          console.warn(error);
        })
        }

   
    }


    function role_list(){

        var url = 'https://jeetlabs.in/Api/role_list'

      let result = fetch(url,{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
            "Accept":"application/json"
        }
  
    }).then((response)=>{
        response.json().then((result)=>{
          // console.log(result)
          setRoleslist(result)
         
       
        })
    }).catch(error =>{
      console.warn(error);
    })

    }


    

    useEffect(() => {
      role_list();
      // console.log(permissions_list[0].Dashboard)
    },[])
  
    return (
        <div> 


       
          
            <Sidebar/>
            <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` },ml:{sm:`${drawerWidth}px`} }}
      >
        <Toolbar />

        <div>
          
          <div sx={{p:3,m:1}} style={{marginTop:20,marginBottom:20,padding:10,borderRadius:'12px' ,display:'flex',justifyContent:'space-between',border:'0.5px  lightgrey'}} >
            <Typography variant="h6" sx={{fontWeight:'medium'}} >Manage Roles</Typography>
            {perms.includes('create roles') == true?

              <Button variant="contained" onClick={() => handleShow()} color="primary" className="modified_button" >+ Create </Button>
            :''
          
          }

          </div>
          <TableContainer component={Paper}>
              <Table sx={{minWidth:'550px'}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="10%" align="left" sx={{fontWeight:600,fontSize:'110%'}} >Role</TableCell>
                    <TableCell width="80%"  align="center" sx={{fontWeight:600,fontSize:'110%'}} >Permission</TableCell>
                    <TableCell width="10%"  align="center" sx={{fontWeight:600,fontSize:'110%'}} >Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roleslist.map((role)=>(
                  <TableRow>
                    
                                      <TableCell width="10%" align="left" sx={{fontWeight:500,textTransform:'capitalize'}} >{role.name}</TableCell>
                                       <TableCell width="80%"  align="center"> {role.permissions.map((permission)=>(
                                        <Chip label={permission.perm_name}  color="error" sx={{m:0.5,fontWeight:'bold',textTransform:'capitalize'}} />
                                      ))}
                                      </TableCell>
                                      <TableCell width="10%" align="center" >
                                          <IconButton aria-label="Edit" color="success">
                                              <CreateIcon />
                                            </IconButton>
                                            <IconButton aria-label="Delete" color="secondary" onClick={()=>delete_data(role.name)}>
                                              <DeleteIcon />
                                            </IconButton>
                                      </TableCell>

                  </TableRow>
                  ))}

                </TableBody>
              </Table>
          </TableContainer>


        </div>






      </Box>



<Modal show={show}  style={{background:'#808080db',zIndex:10000}}   onHide={() => handleClose()} size="xl" dialogClassName="modal-90w">
  <Modal.Header closeButton>
    <span style={{fontWeight:600}} >Add New Group</span>
  </Modal.Header>
  <Modal.Body style={{}} >

  <TextField id="outlined-basic" sx={{mb:2}} onChange={(e)=>setRoletitle(e.target.value)} label="Group Name" fullWidth variant="outlined" />
  
  {/* <Divider sx={{mt:2,mb:1,width:'100%'}}  /> */}


  <Table>
    <TableHead>
    <TableRow>
      <TableCell align="left" width="25%" sx={{fontWeight:800,fontSize:'110%'}}  >Modules</TableCell>
      <TableCell align="center" width="70%" sx={{fontWeight:800,fontSize:'110%'}}  >Permission</TableCell>
    </TableRow>
    </TableHead>
    <TableBody>

      {permissions_list.map((list,index)=>(

      
      
    <TableRow>
          <TableCell key={index} sx={{fontWeight:800}} >{Object.keys(list)[0]}</TableCell>

          <TableCell>
          <Grid container spacing={0}>
          {
            Object.values(list)[0].map((stacks,i)=>(
              <Grid item xs={6} md={4}>
                <Item elevation={0} >
                  <Checkbox value={stacks.label} onChange={(e)=>permissionhandler(e) } aria-label={stacks.label} /> <label>{stacks.label}</label>
                </Item>
              </Grid>
            ))
          }



       
              
          </Grid>
          </TableCell>

        </TableRow>
))}

    </TableBody>
  </Table>
  
  {
    resulttype == 'error'?
    <Alert variant="filled" color="error">{resultmsg}</Alert>
    :resulttype == 'success'?<Alert variant="filled" color="success">{resultmsg}</Alert>
    :resulttype == 'info'?<Alert variant="filled" color="info">{resultmsg}</Alert>
    :''
     
  }


  </Modal.Body>
  <Modal.Footer>
    <Button variant="contained" color="error" onClick={()=>handleClose()} sx={{m:1}} >Close</Button>
    <Button variant="contained" onClick={()=>create_role()} color="success" sx={{m:1}}  >Create Group</Button>
  </Modal.Footer>
</Modal>

        </div>
    )
}
