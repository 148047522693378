import React, { useState, useEffect } from "react"
import Sidebar from "./parts/Sidebar"
import {
  Button,
  TextField,
  Alert,
  Toolbar,
  Box,
  Table,
  TableBody,
  TableCell,
  Collapse,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import { useHistory } from "react-router-dom"
import CreateIcon from "@mui/icons-material/Create"
import DeleteIcon from "@mui/icons-material/Delete"
import PhotoCamera from "@mui/icons-material/PhotoCamera"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import StarIcon from "@mui/icons-material/Star"
import { Modal, Row, Col } from "react-bootstrap"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { useInput } from "@mui/core"
import XLSX from "xlsx"

var bill = "https://source.unsplash.com/random"

const StyledInputElement = styled("input")`
  width: 200px;
  font-size: 1rem;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  background: #fff;
  border: 1.5px solid #606060;
  border-radius: 10px;
  padding: 6px 10px;
  color: #20262d;
  transition: width 300ms ease;

  &:hover {
    background: #fff;
    border-color: #606060;
  }

  &:focus {
    outline: none;
    width: 220px;
    transition: width 200ms ease-out;
  }
`
const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  const { getRootProps, getInputProps } = useInput(props, ref)

  return (
    <div {...getRootProps()}>
      <StyledInputElement {...props} {...getInputProps()} />
    </div>
  )
})

const drawerWidth = 280

const Input = styled("input")({
  display: "none",
})

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  elevation: "0",
  color: theme.palette.text.secondary,
}))

function Details(props) {
  const { row, handleChangeProduct } = props
  const [open, setOpen] = useState(false)
  const [isFeatured, setFeatured] = useState(
    row.pd_featured == 0 ? true : false
  )
  const [isVisible, setVisible] = useState(row.pd_hide == 0 ? true : false)
  var cluster_data = row.cluster_data

  var userid = JSON.parse(localStorage.getItem("userid"))
  var token = JSON.parse(localStorage.getItem("token"))

  function delete_product(branch_id) {
    var url = "https://jeetlabs.in/Api/delete_product"
    var confirm = window.confirm("Confirm Delete")

    var items = { userid, token, branch_id }

    if (confirm) {
      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            // console.log(result);
            // setResultmsg(result.msg)
            // setResulttype(result.type)

            // age_group_list();
            window.location.reload(false)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    } else {
      // console.log(branch_id+ ' is safe')
    }
  }
  console.log(row, props)
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align='center'>{row.pd_name}</TableCell>
        <TableCell align='center'>{row.pd_price}</TableCell>
        <TableCell align='center'>{row.pd_details}</TableCell>
        <TableCell align='center'>
          <Tooltip title={isFeatured ? "Make Featured" : "Remove Featured"}>
            <IconButton
              aria-label='Make Featured'
              color='info'
              onClick={() => {
                setFeatured(!isFeatured)
              }}
            >
              {isFeatured ? <StarBorderIcon /> : <StarIcon />}
            </IconButton>
          </Tooltip>

          <Tooltip title='Edit'>
            <IconButton aria-label='Edit' color='success'>
              <CreateIcon
                onClick={() => {
                  handleChangeProduct(row)
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title='Delete'>
            <IconButton
              aria-label='Delete'
              color='error'
              onClick={() => delete_product(row.pd_id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={isVisible ? "Show" : "Hide"}>
            <IconButton
              aria-label='Hide'
              color='secondary'
              onClick={() => {
                setVisible(!isVisible)
              }}
            >
              {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <img
                src={row.pd_thumbnail}
                srcSet={row.pd_thumbnail}
                alt={"pop"}
                style={{ maxHeight: "250px", width: "auto" }}
                loading='lazy'
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function Products() {
  var initialState = ""

  const [show, setShow] = useState(false)

  const [pop, setPop] = useState(bill)
  let history = useHistory()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [name, setName] = useState("")
  const [price, setPrice] = useState("")
  const [details, setDetails] = useState("")
  const [resultmsg, setResultmsg] = useState("")
  const [resulttype, setResulttype] = useState("")
  const [popname, setPopname] = useState()
  const [rawpop, setRawpop] = useState()

  const [open, setOpen] = useState(false)
  const [popstatus, setPopstatus] = useState("")
  const [popmsg, setPopmsg] = useState("")

  //  edit action
  const [edit_product_modal, setEdit_product_modal] = useState(false)
  const [update_product_id, setupdate_product_id] = useState(initialState)
  const [new_name, setNew_name] = useState(initialState)
  const [new_price, setNew_price] = useState(initialState)
  const [new_details, setNew_details] = useState(initialState)

  const handleChangeProduct = row => {
    setupdate_product_id(row.pd_id)
    setNew_name(row.pd_name)
    setNew_price(row.pd_price)
    setNew_details(row.pd_details)
    setEdit_product_modal(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function handleClose() {
    setName("")
    setPrice("")
    setDetails("")
    setResultmsg("")
    setResulttype("")
    setPopmsg("")
    setPopstatus("")
    setPop(bill)
    setShow(false)
  }

  function handleProductClose() {
    setupdate_product_id("")
    setNew_name("")
    setNew_price("")
    setNew_details("")
    setResultmsg("")
    setResulttype("")
    setEdit_product_modal(false)
  }

  function selectImage(e) {
    // console.log(e.target.files[0])
    setRawpop(e.target.files[0])
    setPopname(e.target.files[0].name)
    var img = URL.createObjectURL(e.target.files[0])
    // console.log(img)
    setPop(img)
  }

  const [products_list, setProducts_list] = useState([])

  function create_product() {
    var url = "https://jeetlabs.in/Api/create_product"
    var items = { name, price, details }

    if (
      name.length < 1 ||
      price.length < 1 ||
      details.length < 1 ||
      pop == bill
    ) {
      setResultmsg("All Fields Required And Thumbnail must be your not default")
      setResulttype("error")
    } else {
      setResultmsg("Product Creation in Progress")
      setResulttype("info")

      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)
            if (result.type == "success") {
              uploadthumb(result.orderid)
            }
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  function uploadthumb(orderid) {
    setPopstatus("uploading")
    setPopmsg("POP Uploading in Progress")
    // console.log(rawpop);

    if (pop == bill) {
      setPopstatus("error")
      setPopmsg("POP Upload Required")
    } else {
      let url = "https://jeetlabs.in/Api/uploadthumb.php"

      const fd = new FormData()
      fd.append("upop", rawpop)
      fd.append("orderid", orderid)

      fetch(url, {
        method: "POST",
        body: fd,
      }).then(response => {
        response.json().then(result => {
          // console.log(result);
          setPopstatus(result.result)
          setPopmsg(result.msg)
          products_data()
          setTimeout(function () {
            handleClose()
          }, 1200)
        })
      })
    }
  }

  function products_data() {
    var url = "https://jeetlabs.in/Api/products_data"

    let result = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(response => {
        response.json().then(result => {
          // console.log(result)
          setProducts_list(result)
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  function update_product() {
    var url = "https://jeetlabs.in/Api/update_product"
    var items = {
      pd_id: update_product_id,
      pd_name: new_name,
      pd_price: new_price,
      pd_details: new_details,
      pd_thumbnail: "", // "https://jeetlabs.in/Api/products_image/AEEX.png",
    }

    if (
      update_product_id.length < 1 ||
      new_name.length < 1 ||
      new_price.length < 1 ||
      new_details.length < 1
    ) {
      setResultmsg("All fields Required")
      setResulttype("error")
    } else {
      setResultmsg("Product Update in Progress")
      setResulttype("info")

      let result = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(items),
      })
        .then(response => {
          response.json().then(result => {
            //  console.log(result);
            setResultmsg(result.msg)
            setResulttype(result.type)

            products_list()
            setTimeout(function () {
              handleProductClose()
              //   history.push('/Myorder')
            }, 1200)
          })
        })
        .catch(error => {
          console.warn(error)
        })
    }
  }

  function search_products_list(value) {
    var url = "https://jeetlabs.in/Api/search_products"
    var items = { data: value }

    let result = fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(items),
    })
      .then(response => {
        response.json().then(result => {
          console.log(result)
          if (result.type === "error" && result.msg === "No Data Found") {
            setProducts_list([])
          } else {
            setProducts_list(result)
          }
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  useEffect(() => {
    products_data()
  }, [])

  const getTableData = () => {
    var rows = []
    products_list.map((eachRow, eachRowIndex) => {
      var obj = {
        "S.No": "S.No",
        Name: "Name",
        Price: "Price",
        Details: "Details",
      }

      obj["S.No"] = eachRowIndex + 1
      obj["Name"] = eachRow.pd_name
      obj["Price"] = eachRow.pd_price
      obj["Details"] = eachRow.pd_details
      rows.push(obj)
    })
    console.log("rows", rows)
    return rows
  }

  const exportToExcel = () => {
    var rows = getTableData()

    const sheet_names = ["sheet 1"]
    const sheets = {
      [sheet_names[0]]: XLSX.utils.json_to_sheet(rows),
    }
    const wb = {
      SheetNames: sheet_names,
      Sheets: sheets,
    }

    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    })
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const fileNameWithExt = "ProductsList.xlsx"

    const data = new Blob([excelBuffer], { type: fileType })
    const url = URL.createObjectURL(data)
    var el = document.createElement("a")
    el.href = url
    el.download = fileNameWithExt
    el.click()

    URL.revokeObjectURL(url)
  }

  return (
    <div>
      <Sidebar />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar />

        <div>
          <div
            sx={{ p: 3, m: 1 }}
            style={{
              marginTop: 20,
              marginBottom: 20,
              padding: 10,
              borderRadius: "12px",
              display: "flex",
              justifyContent: "space-between",
              border: "0.5px  lightgrey",
            }}
          >
            {/* <Typography variant='h6' sx={{ fontWeight: "medium" }}>
              Products
            </Typography> */}
            <CustomInput
              aria-label='Search'
              onChange={e => {
                search_products_list(e.target.value)
              }}
              placeholder='Search something...'
            />

            <Button
              variant='contained'
              className='modified_button'
              color='primary'
              onClick={() => setShow(true)}
            >
              + Create{" "}
            </Button>
          </div>

          <TableContainer component={Paper}>
            <Button
              sx={{ p: 2 }}
              onClick={exportToExcel}
              endIcon={<ArrowDownwardIcon />}
            >
              Export
            </Button>
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Details
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: 600, fontSize: "110%" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products_list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <Details
                        key={index}
                        row={row}
                        handleChangeProduct={handleChangeProduct}
                      />
                    )
                  })}

                {products_list.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align='center'>
                      <img
                        src='https://jeetlabs.in/Api/no_data.png'
                        srcSet='https://jeetlabs.in/Api/no_data.png'
                        alt={"no data found"}
                        style={{ maxHeight: "250px", width: "auto" }}
                        loading='lazy'
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={products_list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Box>

      <Modal
        show={show}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handleClose()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>Add Product</span>
        </Modal.Header>

        <Modal.Body>
          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Product Name'
                sx={{ mb: 2 }}
                onChange={e => setName(e.target.value)}
              />
            </Col>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Price'
                sx={{ mb: 2 }}
                type='number'
                onChange={e => setPrice(e.target.value)}
              />
            </Col>
          </Row>

          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Details'
                multiline
                rows={4}
                sx={{ mb: 2 }}
                onChange={e => setDetails(e.target.value)}
              />
            </Col>
            <Col md>
              {/* <Avatar src='https://source.unsplash.com/random'  sx={{ mt:2,width: 250, height: 250 }} style={{border:'5px solid lightgrey'}} /> */}

              <label htmlFor='icon-button-file' align='center'>
                <Input
                  accept='image/*'
                  id='icon-button-file'
                  type='file'
                  onChange={e => selectImage(e)}
                />
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='span'
                >
                  <PhotoCamera style={{ fontSize: "30" }} />
                  {/* <br/><br/> */}
                  {/* <Avatar src={pop}  sx={{ mt:2,width: 250, height: 250 }} style={{border:'5px solid lightgrey'}} /> */}
                </IconButton>
              </label>

              <img
                src={pop}
                srcSet={pop}
                alt={"pop"}
                style={{ maxHeight: "120px", width: "auto" }}
                loading='lazy'
              />
            </Col>
          </Row>

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}

          {popstatus == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {popmsg}
            </Alert>
          ) : popstatus == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {popmsg}
            </Alert>
          ) : popstatus == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {popmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => create_product()}
            color='success'
            sx={{ m: 1 }}
          >
            Add Product
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={edit_product_modal}
        backdrop='static'
        centered
        style={{ background: "#808080db" }}
        size='lg'
        onHide={() => handleProductClose()}
      >
        <Modal.Header closeButton>
          <span style={{ fontWeight: 600 }}>Edit Product</span>
        </Modal.Header>

        <Modal.Body>
          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Product Name'
                sx={{ mb: 2 }}
                value={new_name}
                onChange={e => setNew_name(e.target.value)}
              />
            </Col>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Price'
                sx={{ mb: 2 }}
                type='number'
                value={new_price}
                onChange={e => setNew_price(e.target.value)}
              />
            </Col>
          </Row>

          <Row className='g-2'>
            <Col md>
              <TextField
                fullWidth
                required
                id='outlined-required'
                label='Details'
                multiline
                rows={4}
                sx={{ mb: 2 }}
                value={new_details}
                onChange={e => setNew_details(e.target.value)}
              />
            </Col>
            <Col md>
              {/* <Avatar src='https://source.unsplash.com/random'  sx={{ mt:2,width: 250, height: 250 }} style={{border:'5px solid lightgrey'}} /> */}

              <label htmlFor='icon-button-file' align='center'>
                <Input
                  accept='image/*'
                  id='icon-button-file'
                  type='file'
                  onChange={e => selectImage(e)}
                />
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='span'
                >
                  <PhotoCamera style={{ fontSize: "30" }} />
                  {/* <br/><br/> */}
                  {/* <Avatar src={pop}  sx={{ mt:2,width: 250, height: 250 }} style={{border:'5px solid lightgrey'}} /> */}
                </IconButton>
              </label>

              <img
                src={pop}
                srcSet={pop}
                alt={"pop"}
                style={{ maxHeight: "120px", width: "auto" }}
                loading='lazy'
              />
            </Col>
          </Row>

          {resulttype == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {resultmsg}
            </Alert>
          ) : resulttype == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {resultmsg}
            </Alert>
          ) : resulttype == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {resultmsg}
            </Alert>
          ) : (
            ""
          )}

          {popstatus == "error" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='error'>
              {popmsg}
            </Alert>
          ) : popstatus == "success" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='success'>
              {popmsg}
            </Alert>
          ) : popstatus == "info" ? (
            <Alert sx={{ m: 2 }} variant='filled' color='info'>
              {popmsg}
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='contained'
            color='error'
            onClick={() => handleProductClose()}
            sx={{ m: 1 }}
          >
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => update_product()}
            color='success'
            sx={{ m: 1 }}
          >
            Update Product
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
