import React,{useState,useEffect} from 'react'
import { useHistory } from 'react-router-dom';

export default function Logout() {
    var history = useHistory();
    useEffect(() => {
        localStorage.clear();
        history.push('/login')
      },[])
    
    return (
        <div>
            
        </div>
    )
}
